import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 환율 정보 조회
  getExchgRateInqList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/exchg-rate-inqs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPlnExchgRateInqList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/pln-exchg-rate-inqs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  // 환율 정보 저장
  saveExchgRate(IUDObj) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/exchg-rate-inqs', IUDObj)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadExcel(files, yymm) {
    return commonUtils.$upload(`/bse/exchg-rate-inqs/xls/${yymm}`, files[0])
  }
 
}
