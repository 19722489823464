import { axiosApi } from '@/api'

export default {
  // --- 법인 요약 조회 ---
  getBoSummaryInfo(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/anl/bo-summary${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // --- 영업이익 추이 조회 ---
  getBusiProfAnlInfo(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/anl/busi-profs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // --- 거래처 매출분석 조회 ---
  getCustPerfList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/anl/cust-perfs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
