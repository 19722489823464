import anlInfoService from '@/api/services/analysis/anl-info-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_BO_SUMMARY_INFO = 'FETCH_BO_SUMMARY_INFO'
const SET_TOTAL_SUM_INFO = 'SET_TOTAL_SUM_INFO'
const SET_SALE_GRAPH_SERIES = 'SET_SALE_GRAPH_SERIES'
const SET_PROF_GRAPH_SERIES = 'SET_PROF_GRAPH_SERIES'
const FETCH_SUMRY_CL_CODE_LIST = 'FETCH_SUMRY_CL_CODE_LIST'
const CLEAR_STORE = 'CLEAR_STORE'

// const FETCH_MENU_CODE_LIST = 'FETCH_MENU_CODE_LIST'

const anlBoSummaryStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    boSumInfo: {
      boSaleSumAmt: 0, // 매출액 총액
      boProfSumAmt: 0, // 영업이익 총액

      empResiCnt: 0, // 주재원 수
      empLocalCnt: 0, // 현채인 수
      empTotCnt: 0 // 총인원
    },

    // 요약 상세 리스트
    boSumDetails: [],
    boSumDetail: {
      // incItm1: '매출액',
      // incItm2: '합계', // 합계/기존/협력
      // incSeq: 1,      // 1: 합계, 2:항목
      // itmNm: '매출액', //
      // befRet: 20885,  // 전년실적
      // aftPln: 85,     // 금년 계획
      // aftRet: 8674,    // 금년 실적
      // increaseAmt: -12211, // 전년비 증감액
      // increaseRate: '-58.5%',  // 전년비 증감율
      // planRate: '10104.7%' // 계획비 달성율
    },
    sumryCodes: [],
    sumryCode: {
      // value: '1',
      // label: '당월'
    },

    // 매출액 Graph Data
    saleGraphSeries: [
      //   {
      //     name: '기존',
      //     data: [122, 232, 343]
      //   },
      //   {
      //     name: '협력',
      //     data: [224, 245, 352]
      //   }
    ],
    // 영업이익 Graph Data
    profGraphSeries: [
      //   {
      //     name: '기존',
      //     data: [423, 543, 223]
      //   },
      //   {
      //     name: '협력',
      //     data: [423, 543, 223]
      //   }
    ]
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_BO_SUMMARY_INFO](state, payload) {
      state.boSumInfo = {
        empResiCnt: payload.empResiCnt,
        empLocalCnt: payload.empLocalCnt,
        empTotCnt: payload.empTotCnt
      }
      state.boSumDetails = payload.boAmtInfoList
    },
    [SET_TOTAL_SUM_INFO](state, sumInfo) {
      state.boSumInfo.boSaleSumAmt = sumInfo.boSaleSumAmt
      state.boSumInfo.boProfSumAmt = sumInfo.boProfSumAmt
    },

    [SET_SALE_GRAPH_SERIES](state, series) {
      state.saleGraphSeries = series
    },
    [SET_PROF_GRAPH_SERIES](state, series) {
      state.profGraphSeries = series
    },

    [FETCH_SUMRY_CL_CODE_LIST](state, codes) {
      state.sumryCodes = codes
    },
    [CLEAR_STORE](state) {
      state.boSumInfo.empResiCnt = ''
      state.boSumInfo.empLocalCnt = ''
      state.boSumInfo.empTotCnt = ''
      state.boSumInfo.boSaleSumAmt = ''
      state.boSumInfo.boProfSumAmt = ''
      state.boSumDetails = []
      state.saleGraphSeries = []
      state.profGraphSeries = []
    }
  },

  actions: {
    // 코드유형 리스트 조회
    fetchBoSummaryInfo({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        anlInfoService
          .getBoSummaryInfo(queryStr)
          .then((res) => {
            // 매출액/영업이익 정보 저장
            const boSummaryInfo = res.data
            commit(FETCH_BO_SUMMARY_INFO, boSummaryInfo)

            if (state.boSumDetails && state.boSumDetails.length > 0) {
              // 매출액/영업이익 기준년도 실적금액 추출
              let boSaleSumAmt = state.boSumDetails.filter(
                (item) => item.incItm1 === '매출액' && item.incSeq === 1
              )
              boSaleSumAmt =
                boSaleSumAmt && boSaleSumAmt.length > 0
                  ? boSaleSumAmt[0].aftRet
                  : 0

              // console.log('======> boSaleSumAmt:', boSaleSumAmt)

              let boProfSumAmt = state.boSumDetails.filter(
                (item) => item.incItm1 === '영업이익 I' && item.incSeq === 1
              )
              boProfSumAmt =
                boProfSumAmt && boProfSumAmt.length > 0
                  ? boProfSumAmt[0].aftRet
                  : 0

              commit(SET_TOTAL_SUM_INFO, { boSaleSumAmt, boProfSumAmt })

              // 매출액 차트 정보 추출
              const boSaleItems = state.boSumDetails.filter(
                (item) => item.incItm1 === '매출액' && item.incSeq === 2
              )
              // console.log('======> boSaleItems:', boSaleItems)

              const boSaleSeries = boSaleItems.map((item) => {
                return {
                  name: item.incItm2,
                  data: [item.befRet, item.aftPln, item.aftRet]
                }
              })
              // console.log('======> boSaleSeries:', boSaleSeries)
              commit(SET_SALE_GRAPH_SERIES, boSaleSeries)

              // 매출액 차트 정보 추출
              const boProfItems = state.boSumDetails.filter(
                (item) => item.incItm1 === '영업이익 I' && item.incSeq === 2
              )
              // console.log('======> boProfItems:', boProfItems)

              const boProfSeries = boProfItems.map((item) => {
                return {
                  name: item.incItm2,
                  data: [item.befRet, item.aftPln, item.aftRet]
                }
              })
              // console.log('======> boProfSeries:', boProfSeries)
              commit(SET_PROF_GRAPH_SERIES, boProfSeries)
            } else {
              // 법인 요약 금액정보가 없으면 초기화
              commit(SET_TOTAL_SUM_INFO, { boSaleSumAmt: 0, boProfSumAmt: 0 })
            }

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 공통코드(확정구분) 리스트 조회
    fetchSumryClCodeList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            const commCodes = res.data
            if (commCodes && commCodes.length > 0) {
              commCodes.shift()
              commit(FETCH_SUMRY_CL_CODE_LIST, commCodes)
            }

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default anlBoSummaryStore
