import sysPgmMgmtService from '@/api/services/system/sys-pgm-mgmt-service'

// === for gred sample
const FETCH_PGM_LIST = 'FETCH_PGM_LIST'
const SELECT_PGM = 'SELECT_PGM'
const FETCH_MENU_CODE_LIST = 'FETCH_MENU_CODE_LIST'
const CLEAR_STORE = 'CLEAR_STORE'

const sysPgmMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 사용자
    pgms: [],
    pgm: {
      // topMenuCd: 'BOSYS000', // TOP 메뉴 코드(메뉴 코드)
      // topMenuNm: '시스템관리', // 메뉴명
      // topMenuNmEng: '시스템관리', // 메뉴명(영문)
      // pgmId: 'BOSYS002', // 프로그램 ID(화면ID)
      // pgmNm: '프로그램 관리', // 프로그램 명(화면명)
      // pgmSnm: '프로그램 관리', // 프로그램 단축명(호면 단축명)
      // pgmNmEng: '프로그램 관리', // 프로그램 명 영어(화면명(영어))
      // pgmSnmEng: '프로그램 관리', // 프로그램 단축명 영어(화면 단축명(영어))
      // viewNm: '', // 뷰 명(???)
      // pgmTyp: 'PGM', // 프로그램 유형
      // linkFile: 'SysPgmMgmtPage', // LINK FILE(Vue 파일명)
      // rmrk: '', // 비고
      // compId: 100, // 회사 ID
      // delYn: 'N', // 삭제 YN
      // viewSeq: '' // 뷰 순서
    },

    menuCodes: [],
    menuCode: {
      // topMenuCd: 'INC000',
      // topMenuNm: '손익관리',
      // topMenuNmEng: 'Income Mgmt.'
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_PGM_LIST](state, pgms) {
      state.pgms = pgms
    },
    [SELECT_PGM](state, pgm) {
      state.pgm = pgm
    },
    [FETCH_MENU_CODE_LIST](state, menuCodes) {
      state.menuCodes = menuCodes
    },
    [CLEAR_STORE](state) {
      state.pgms = []
    }
  },

  actions: {
    // 프로그램 리스트 조회
    fetchPgmList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysPgmMgmtService
          .getPgmList(queryStr)
          .then((res) => {
            commit(FETCH_PGM_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 프로그램 선택
    selectPgm({ commit }, selectPgm) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_PGM, selectPgm)
    },

    // 메뉴코드 리스트 조회
    fetchMenuCodeList({ commit }, { queryStr, locale }) {
      return new Promise((resolve, reject) => {
        sysPgmMgmtService
          .getMenuCodeList(queryStr, locale)
          .then((res) => {
            commit(FETCH_MENU_CODE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default sysPgmMgmtStore
