import bseFileService from '@/api/services/base/bse-file-mgmt-serivce'

const FETCH_FILE_LIST = 'FETCH_FILE_LIST' // 파일목록

const bseFileMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 파일목록
    files: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_FILE_LIST](state, files) {
      state.files = files
    }
  },

  actions: {
    // S3파일 목록 조회
    fetchFileInfoList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        bseFileService
          .getFileInfoList(queryStr)
          .then((res) => {
            commit(FETCH_FILE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 파일리스트 clear
    clearFiles({ commit }) {
      commit(FETCH_FILE_LIST, [])
    }
  }
}

export default bseFileMgmtStore
