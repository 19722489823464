import sysUserMgmtService from '@/api/services/system/sys-user-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_USER_LIST = 'FETCH_USER_LIST'
const SELECT_USER = 'SELECT_USER'
const FETCH_AUTH_CODE_LIST = 'FETCH_AUTH_CODE_LIST'
const FETCH_COUNTRY_CODE_LIST = 'FETCH_COUNTRY_CODE_LIST'
const FETCH_LOCALE_CODE_LIST = 'FETCH_LOCALE_CODE_LIST'
const CLEAR_STORE = 'CLEAR_STORE'

const sysUserMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 사용자
    users: [],

    user: {
      // userNo: 101,
      // userId: 'hdhong',
      // userNm: '홍길동',
      // userNmEng: 'hd.Hong',
      // useLang: 'ko_KR',
      // compId: 100,
      // compNm: '롯데글로벌 로지스',
      // boId: '',
      // boNm: '',
      // userTyp: 'H',
      // authCd: 'LGL-002',
      // authNm: '매니저(LGL)',
      // cntryCd: 'KRW',
      // delYn: 'N',
      // lockYn: 'N',
      // lockDt: '',
      // finlPwdUpdDt: '',
      // pwdChgDt: ''
    },

    // 권한 코드
    authCodes: [],
    authCode: {
      // authCd: 'LGL-002',
      // authNm: '해외사업팀',
      // authNmEng: '해외사업팀'
    },

    // 국가 코드
    cntryCodes: [],
    cntryCode: {
      // cntryCd: 'KR',
      // cntryNm: 'Korea (Republic of)'
    },

    // 국가 코드
    localeCodes: [],
    localeCode: {
      // lngCd: 'en_US',
      // lngDesc: 'English (United States)'
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_USER_LIST](state, payload) {
      state.users = payload
    },
    [SELECT_USER](state, user) {
      state.user = user
    },
    [FETCH_AUTH_CODE_LIST](state, codes) {
      state.authCodes = codes
    },
    [FETCH_COUNTRY_CODE_LIST](state, codes) {
      state.cntryCodes = codes
    },
    [FETCH_LOCALE_CODE_LIST](state, codes) {
      state.localeCodes = codes
    },
    [CLEAR_STORE](state) {
      state.users = []
    }
  },

  actions: {
    // === for Grid sample
    fetchUserList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysUserMgmtService
          .getUserList(queryStr)
          .then((res) => {
            commit(FETCH_USER_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    selectUser({ commit }, selectUser) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_USER, selectUser)
    },

    // 권한 코드 조회
    fetchAuthCodeList({ commit }, locale = 'en_US') {
      return new Promise((resolve, reject) => {
        commonService
          .getAuthCodeList(locale)
          .then((res) => {
            commit(FETCH_AUTH_CODE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 국가코드 조회
    fetchCountryCodeList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCountryCodeList()
          .then((res) => {
            commit(FETCH_COUNTRY_CODE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 지역언어 코드 조회
    fetchLocaleCodeList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getLocaleCodeList()
          .then((res) => {
            commit(FETCH_LOCALE_CODE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default sysUserMgmtStore
