import infEmployeeMgmtService from '@/api/services/infra/inf-employee-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_EMPLOYEE_LIST = 'FETCH_EMPLOYEE_LIST' // 인원
const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE'
const FETCH_EMP_TYP_LIST = 'FETCH_EMP_TYP_LIST' // 직원 유형
const FETCH_EMP_TYP = 'FETCH_EMP_TYP'
const FETCH_SEARCH_LIST = 'FETCH_SEARCH_LIST' // 검색 조건
const FETCH_SEARCH = 'FETCH_SEARCH'
const FETCH_SEX_LIST = 'FETCH_SEX_LIST' // 성별
const FETCH_SEX = 'FETCH_SEX'
const FETCH_HO_PST_LIST = 'FETCH_HO_PST_LIST' // 본사직위
const FETCH_HO_PST = 'FETCH_HO_PST'
const FETCH_CRNCY_LIST = 'FETCH_CRNCY_LIST' // 통화
const FETCH_CRNCY = 'FETCH_CRNCY'
const FETCH_RESIDENT_LIST = 'FETCH_RESIDENT_LIST' // 주재원
const FETCH_RESIDENT = 'FETCH_RESIDENT'

const infEmployeeMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample

    // 인원
    employees: [],
    employee: {},

    // 직원 유형
    empTypes: [],
    empTypId: {
      // codeType:"EMP01"
      // itemCd:"2"
      // label:"현채 한국인"
      // labelEng:"현채 한국인"
      // value:1000074
    },

    // 검색 조건
    searches: [],
    searchOpt: {
      // codeType:"HR01"
      // itemCd:"1"
      // label:"입사자"
      // labelEng:"입사자"
      // value:1000088
    },

    // 성별
    sexes: [],
    sex: {
      // codeType:"SEX"
      // itemCd:"M"
      // label:"Male"
      // labelEng:"Male"
      // value:1000086
    },

    // 본사 직위
    hoPsts: [],
    hoPst: {
      // codeType:"HR02"
      // itemCd:"EX"
      // label:"Executive"
      // labelEng:"Executive"
      // value:1000091
    },

    // 통화
    crncys: [],
    crncyCd: {
      // label:"United Arab Emirates dirham"
      // value:"AED"
    },

    // 주재원
    residents: [],
    resident: {
      // accFmlyChild:0
      // accFmlyWife:0
      // assDt:"20220318"
      // boCl:"H"
      // boEmpId:1000002
      // boId:1000001
      // boNm:"중국법인"
      // crncyCd:"CNY"
      // cty:"SHANGHAI "
      // delYn:"N"
      // doe:"20200301"
      // dpt:"管理部"
      // empNm:"김한겸(金翰?)"
      // empTypId:1000073
      // empTypNm:"주재원"
      // hoPst:"M"
      // hre:0
      // hsngContEtd:"20220318"
      // hsngContStd:"20220318"
      // job:"관리담당"
      // pst:"고급경리"
      // rgd:"20220318"
      // rtnDt:"20220318"
      // sex:"M"
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_EMPLOYEE_LIST](state, payload) {
      state.employees = payload
    },
    [FETCH_EMPLOYEE](state, employee) {
      state.employee = employee
    },
    [FETCH_EMP_TYP_LIST](state, empTypes) {
      state.empTypes = empTypes
    },
    [FETCH_EMP_TYP](state, employee) {
      state.empTypId = employee
    },
    [FETCH_SEX_LIST](state, sexes) {
      sexes.shift()
      state.sexes = sexes
    },
    [FETCH_SEX](state, sex) {
      state.sex = sex
    },
    [FETCH_HO_PST_LIST](state, hoPsts) {
      hoPsts.shift()
      state.hoPsts = hoPsts
    },
    [FETCH_HO_PST](state, hoPst) {
      state.hoPst = hoPst
    },
    [FETCH_SEARCH_LIST](state, searches) {
      state.searches = searches
    },
    [FETCH_SEARCH](state, searchOpt) {
      state.searchOpt = searchOpt
    },
    [FETCH_CRNCY_LIST](state, crncys) {
      state.crncys = crncys
    },
    [FETCH_CRNCY](state, crncyCd) {
      state.crncyCd = crncyCd
    },
    [FETCH_RESIDENT_LIST](state, payload) {
      state.residents = payload
    },
    [FETCH_RESIDENT](state, resident) {
      state.resident = resident
    }
  },

  actions: {
    // === for Grid sample
    fetchEmployeeMgmtList({ commit }, queryStr = '') {
      console.log(
        'infEmployeeMgmtStore > fetchEmployeeMgmtList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        infEmployeeMgmtService
          .getEmployeeMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_EMPLOYEE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchEmployee({ commit }, selectEmployee) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_EMPLOYEE, selectEmployee)
    },
    clearInfEmployee({ commit }) {
      commit(FETCH_EMPLOYEE_LIST, [])
    },
    fetchEmpTypList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log(
              'infEmployeeMgmtStore > fetchEmpTypList > res',
              res.data[0]
            )
            commit(FETCH_EMP_TYP_LIST, res.data)

            console.log(
              'infEmployeeMgmtStore > fetchEmpTypList > state',
              state.empTypes[0]
            )
            commit(
              FETCH_EMP_TYP,
              JSON.parse(JSON.stringify(state.empTypes[0])).value
            )

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchSearchList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log(
              'infEmployeeMgmtStore > fetchSearchList > res',
              res.data[0]
            )
            commit(FETCH_SEARCH_LIST, res.data)

            console.log(
              'infEmployeeMgmtStore > fetchSearchList > state',
              state.searches[0]
            )
            commit(
              FETCH_SEARCH,
              JSON.parse(JSON.stringify(state.searches[0])).value
            )

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchSearch({ commit }, selectSearch) {
      commit(FETCH_SEARCH, selectSearch)
    },
    fetchHoPstList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log(
              'infEmployeeMgmtStore > fetchHoPstList > res',
              res.data[0]
            )
            commit(FETCH_HO_PST_LIST, res.data)

            console.log(
              'infEmployeeMgmtStore > fetchHoPstList > state',
              state.hoPsts[0]
            )
            commit(
              FETCH_HO_PST,
              JSON.parse(JSON.stringify(state.hoPsts[0])).value
            )

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchHoPst({ commit }, selectHoPst) {
      commit(FETCH_HO_PST, selectHoPst)
    },
    fetchSexList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log(
              'infEmployeeMgmtStore > fetchSexList > res',
              res.data[0]
            )
            commit(FETCH_SEX_LIST, res.data)

            console.log(
              'infEmployeeMgmtStore > fetchSexList > state',
              state.sexes[0]
            )
            commit(FETCH_SEX, JSON.parse(JSON.stringify(state.sexes[0])).value)

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchCrncyList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCurrencyCodeList()
          .then((res) => {
            console.log('infEmployeeMgmtStore > fetchCrncyList > res', res)
            this.codeOpts = res.data
            commit(FETCH_CRNCY_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchResidentMgmtList({ commit }, queryStr = '') {
      console.log(
        'infEmployeeMgmtStore > fetchResidentMgmtList > qureystr ==>',
        queryStr
      )

      return new Promise((resolve, reject) => {
        infEmployeeMgmtService
          .getResidentMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_RESIDENT_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchResident({ commit }, selectResident) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_RESIDENT, selectResident)
    },
    clearInfResident({ commit }) {
      commit(FETCH_RESIDENT_LIST, [])
    },

    uploadExcel({ commit }, { files, sheetNum }) {
      return new Promise((resolve, reject) => {
        infEmployeeMgmtService
          .uploadExcel(files, sheetNum)
          .then((res) => {
            commit(FETCH_EMPLOYEE_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

export default infEmployeeMgmtStore
