import { axiosApi } from '@/api'
// import commonUtils from '@/utils/common-utils'

export default {
  // 손익정보 리스트 조회
  getIncPerfInqList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/perf-inqs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // -- 메모 --//
  // 메모저장
  saveIncMemo(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('inc/perf-inqs/memo', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteIncMemo(memoId) {
    return new Promise((resolve, reject) => {
      axiosApi

        .delete(`/inc/perf-inqs/memo/${memoId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // -- end 메모 -- //
  excelDown(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/perf-inqs/xls-down${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
