// import BseBranchOfficeMgmtPage from '@/views/base/BseBranchOfficeMgmtPage.vue';

export default [
  {
    // === 법인 관리 Page ===
    path: '/bse-branch-office-mgmt',
    name: 'BseBranchOfficeMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Base" */ '@/views/base/BseBranchOfficeMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // === 법인-거래처 관리 Page ===
  {
    path: '/bse-bo-cust-mgmt',
    name: 'BseBoCustMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Base" */ '@/views/base/BseBoCustMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // === 환율 정보 조회 Page ===
  {
    path: '/bse-exchg-rate-inq',
    name: 'BseExchgRateInqPage',
    component: () =>
      import(
        /* webpackChunkName: "Base" */ '@/views/base/BseExchgRateInqPage.vue'
      ),
    meta: { auth: true }
  },
  // === 마감 현황 Page ===
  {
    path: '/bse-close-mgmt',
    name: 'BseCloseMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Base" */ '@/views/base/BseCloseMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // === 양식 관리 Page ===
  {
    path: '/bse-form-mgmt',
    name: 'BseFormMgmtPage',
    component: () =>
      import(/* webpackChunkName: "Base" */ '@/views/base/BseFormMgmtPage.vue'),
    meta: { auth: true }
  },
  // === 재무양식 관리 Page ===
  {
    path: '/bse-fin-form-mgmt',
    name: 'BseFinFormMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Base" */ '@/views/base/BseFinFormMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // === 파일관리 Page ===
  {
    path: '/bse-file-mgmt',
    name: 'BseFileMgmtPage',
    component: () =>
      import(/* webpackChunkName: "Base" */ '@/views/base/BseFileMgmtPage.vue'),
    meta: { auth: true }
  },
  // === 손익데이터업로드현황 Page ===
  {
    path: '/bse-inc-up',
    name: 'BseIncUpPage',
    component: () =>
      import(/* webpackChunkName: "Base" */ '@/views/base/BseIncUpPage.vue'),
    meta: { auth: true }
  }
]
