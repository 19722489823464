import { axiosApi } from '@/api'

export default {
  // --- 프로그램 조회 ---
  getPgmList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/pgms${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 프로그램 존재 체크(1건)
  checkPgmId(pgmId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/pgms/${pgmId}/check`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 프로그램 추가
  createPgm(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/pgms', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 프로그램 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updatePgm(pgmId, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/pgms/${pgmId}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 프로그램 삭제
  deletePgm(pgmId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/pgms/${pgmId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 메뉴코드 리스트 조회
  getMenuCodeList(queryStr = '', locale) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/menu-codes${queryStr}`)
        .then((res) => {
          // combo box를 위한 속성명으로 변환
          const codeTemp = res.data.map((item) => {
            const { pgmId: value, pgmNm: label, pgmNmEng: labelEng } = item

            // 언어가 영어가 아니면 다른 언어로 설정
            return {
              value,
              label: locale === 'en_US' ? labelEng : label
            }
          })

          // '---'에 해당하는 dummy 행 추가
          const codeDummy = [
            {
              value: '',
              label: 'ALL'
            }
          ]
          codeTemp.unshift(...codeDummy)

          res.data = codeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
