import { axiosApi } from '@/api'
// import commonUtils from '@/utils/common-utils'

export default {
  // 손익당월실적 리스트 조회
  getIncCurrMonPerfList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/curr-mon-perfs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익당월실적 조회용 콤보리스트
  getIncItemInfoList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/inc/curr-mon-perfs/item')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
