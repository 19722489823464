import { axiosApi } from '@/api'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  /**
   * 파일 업로드를 처리하는 함수
   * @param {*} url 서버 라우트 경로
   * @param {*} file 파일
   * @returns
   */
  async $upload(url, file) {
    console.log('=======>>>> common-utils.js > $upload : ', url, file)
    const formData = new FormData()
    formData.append('file', file)
    return (
      await axiosApi
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .catch((e) => {
          console.log(e)
        })
    ).data
  },
  /**
   * 이미지를 로드하기 위한 함수
   * @param {*} blob 파일
   * @param {*} readAs read 방법
   * @returns
   */
  $loadFile(blob, readAs) {
    console.log('=======>>>> common-utils.js > $upload : ', blob, readAs)
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onload = (e) => resolve(e.target.result)
      fr.onerror = (e) => reject(e)
      if (readAs === 'arraybuffer') fr.readAsArrayBuffer(blob)
      else fr.readAsDataURL(blob)
    })
  },
  /**
   * 서버의 파일 다운로드 처리 함수
   * @param {*} url 서버 라우트 경로
   * @param {*} filename 저장 파일 이름
   */
  async $download(url, filename) {
    console.log('=======>>>> common-utils.js > $download : ', url, filename)
    const f = await axiosApi.get(url, {
      responseType: 'blob'
    })
    saveAs(new Blob([f.data]), `${filename}`)
  },
  /**
   * 클라이언트의 그리드 데이터를 엑셀파일로 다운로드 처리하는 함수
   * @param {*} header 헤더 정보
   * @param {*} rows 데이터 정보
   * @param {*} fileName 파일 이름
   * @param {*} option 옵션
   */
  async $ExcelFromTable(header = [], rows = [], fileName, option = {}) {
    console.log(
      '=======>>>> common-utils.js > $ExcelFromTable : ',
      header,
      rows,
      fileName,
      option
    )
    header = header.filter((h) => h.title && h.key)
    // https://github.com/exceljs/exceljs#tables
    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet() // name,{pageSetup:https://github.com/exceljs/exceljs#page-setup}
    ws.addTable({
      name: 'tableName',
      ref: 'A1',
      headerRow: true,
      // style: { theme: 'TableStyleDark3', showRowStripes: true },
      columns: header.map((h) => ({
        name: h.title
      })), // width 설정가능, total함수 가능
      rows: rows.map((r) => header.map((h) => r[h.key])),
      ...option
    })

    saveAs(new Blob([await wb.xlsx.writeBuffer()]), `${fileName}.xlsx`)
  },
  /**
   * 금액 포맷 처리 함수
   * @param {*} amount 금액
   * @param {*} format 적용 포맷
   * @returns
   */
  $convertNumberFormat(amount, format) {
    let currencyUnit = ''
    let lastUnit = ''

    if (format.substring(0, 1) !== '#') {
      currencyUnit = format.substring(0, 1)
    }

    if (format.slice(-1) !== '#' && format.slice(-1) !== '0') {
      lastUnit = format.slice(-1)
    }

    let groupingSeparator = ''
    let maxFractionDigits = 0
    let decimalSeparator = ''

    if (format.indexOf('.') === -1) {
      groupingSeparator = ','
    } else if (format.indexOf(',') === -1) {
      groupingSeparator = '.'
    } else if (format.indexOf(',') < format.indexOf('.')) {
      groupingSeparator = ','
      decimalSeparator = '.'
      maxFractionDigits = format.length - format.indexOf('.') - 1
    } else {
      groupingSeparator = '.'
      decimalSeparator = ','
      maxFractionDigits = format.length - format.indexOf(',') - 1
    }

    let sign = ''
    let dec = 1
    for (let i = 0; i < maxFractionDigits; i++) {
      dec = dec * 10
    }

    let v = String(Math.round(parseFloat(amount) * dec) / dec)

    if (v.startsWith('-')) {
      sign = '-'
      v = v.substring(1)
    }

    if (maxFractionDigits > 0 && format.substring(format.length - 1) === '0') {
      v = String(parseFloat(v).toFixed(maxFractionDigits))
    }

    let d = ''

    if (maxFractionDigits > 0 && v.indexOf('.') > -1) {
      d = v.substring(v.indexOf('.'))
      d = d.replace('.', decimalSeparator)
      v = v.substring(0, v.indexOf('.'))
    }

    const r = /(\d+)(\d{3})/

    while (r.test(v)) {
      v = v.replace(r, '$1' + groupingSeparator + '$2')
    }
    return sign + currencyUnit + String(v) + String(d) + lastUnit
  },
  // =============== 기존 frmk end ==================

  makeQueryString(params = {}) {
    // queryString 생성
    let queryStr = Object.entries(params)
      // value가 0인 경우도 조건에 포함
      .filter(([key, value]) => !!value || value === 0)
      .map((e) => e.join('='))
      .join('&')

    queryStr = queryStr.length > 0 ? '?' + queryStr : queryStr
    // console.log('=== common-utils.makeQueryString => ' + queryStr);

    return queryStr
  },

  validateUrl(strUrl) {
    // 웹사이트 주소 검증
    const expUrl = /^http[s]?:\/\//i
    // const expUrl = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
    return expUrl.test(strUrl)
  },

  validateEmail(email) {
    // 이메일 주소 검증
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  },

  // 객체 배열 내의 일부 속성만 가진 객체 배열 추출
  // Metar 분석 chart 프로그램에서 사용
  retriveObjArr(arr, fixedKeys, objKeys) {
    return arr.map((item) => {
      const retObj = {}
      // add fixedKkeys
      fixedKeys.forEach((key) => {
        retObj[key] = item[key]
      })
      // add objkeys
      objKeys.forEach((key) => {
        retObj[key] = item[key]
      })

      return retObj
    })
  },

  // 객체 배열을 Metar chart 형식 데이로 변형
  // Metar 분석 프로그램에서 사용
  convertToMetarChartData(arr, yAxisID, objKeys, nullTo) {
    return objKeys.map((item) => {
      const chartObj = {}
      let chartLabel = ''
      // item 형식 : ex) temp20210715 -> temp0715
      chartLabel = item.slice(0, -8) + item.slice(item.length - 4)
      // 풍속의 경우 부가 명칭 추가
      if (yAxisID === 'WIND') {
        // console.log('item, item.slice(0,4) :', item, item.slice(0, 4));
        if (item.slice(0, 4) === 'sspd') {
          chartLabel = chartLabel + '(WIND)'
        } else {
          chartLabel = chartLabel + '(GUST)'
        }
      }
      chartObj.label = chartLabel.toUpperCase()
      // console.log('chartObj.label :', chartObj.label);
      chartObj.yAxisID = yAxisID
      chartObj.data = [].concat(
        arr.map((arrObj) => arrObj[item] ?? nullTo) // nullTo로 보정
      )
      return chartObj
    })
  },
  // --- 숫자 입력 및 콤마 처리 ---
  // 숫자에 콤마 추가
  comma(str) {
    str = String(str)
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  },
  // 숫자에서 콤마 제거
  unComma(str) {
    str = String(str)
    return str.replace(/[^\d]+/g, '')
  },

  // 패스워드 변경규칙 적용 함수
  checkPwdRule(pwd) {
    const valNuum = '0123456789'
    const valLow = 'abcdefghijklmnopqrstuvwxyz'
    const valUpp = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const valCon0 = '~!@#$%^&*()_+'
    const valCon1 = '`1234567890-='
    const valCon2 = 'QWERTYUIOP{}|'
    const valCon3 = 'ASDFGHJKL:"'
    const valCon4 = 'ZXCVBNM<>?'
    // const valCon5 = 'qwertyuiop[]\\'
    // const valCon6 = 'asdfghjkl\;\''
    // const valCon7 = 'zxcvbnm,./'

    const arrPwd = [
      valNuum,
      valLow,
      valUpp,
      valCon0,
      valCon1,
      valCon2,
      valCon3,
      valCon4
      // valCon5
      // valCon6,
      // valcon7
    ]

    for (let i = 0; i < pwd.length - 2; i++) {
      // eslint-disable-next-line no-unused-vars
      const sss = pwd.charAt(i) + pwd.charAt(i + 1) + pwd.charAt(i + 2)

      if (
        pwd.charAt(i) === pwd.charAt(i + 1) &&
        pwd.charAt(i) === pwd.charAt(i + 2)
      ) {
        // 중복 3자리
        return false
      }
    }

    for (let i = 0; i < pwd.length - 3; i++) {
      const ssss =
        pwd.charAt(i) +
        pwd.charAt(i + 1) +
        pwd.charAt(i + 2) +
        pwd.charAt(i + 3)

      for (let j = 0; j < arrPwd.length; j++) {
        // 연속문자 4자리
        if (arrPwd[j].indexOf(ssss) !== -1) {
          return false
        }
      }
    }

    // const pattern = new RegExp(
    //   /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%\^&*()_+`\-={}|[\]\\:";'<>?,.\/])*.{8,20}$/g
    // )

    // if (!pattern.test(pwd)) {
    //   return false
    // }

    return true
  }
}
