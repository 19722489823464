import { axiosApi } from '@/api'

export default {
  // --- 권한리스트 조회 ---
  getAuthList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/auths${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 권한코드 추가
  createAuth(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/auths', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 권한코드 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateAuth(authCd, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/auths/${authCd}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 권한코드 삭제
  deleteAuth(authCd) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/auths/${authCd}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 메뉴 리스트 조회
  getMenuList(queryStr = '', locale) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/menu-codes${queryStr}`)
        .then((res) => {
          // const codeTemp = res.data.map((item) => {
          //   // 언어가 영어가 아니면 다른 언어로 설정
          //   return {
          //     ...item,
          //     pgmNm: locale === 'en_US' ? item.pgmNmEng : item.pgmNm
          //   }
          // })
          // res.data = codeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // --- 프로그램 권한 리스트 조회 ---
  getPgmAuthList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/pgm-auths${queryStr}`)
        .then((res) => {
          // const dataTemp = res.data.map((item) => {
          //   // checkbox값은 true/false라서 거기에 맞춰 변환
          //   // -> true-label/false-label를 설정하여 Y/N으로 지정가능하므로 불필요
          //   return {
          //     ...item,
          //     viewAuth: item.viewAuth === 'Y',
          //     addAuth: item.addAuth === 'Y',
          //     chgAuth: item.chgAuth === 'Y',
          //     delAuth: item.delAuth === 'Y',
          //     dwlAuth: item.dwlAuth === 'Y',
          //     uplAuth: item.uplAuth === 'Y',
          //     sveAuth: item.sveAuth === 'Y',
          //     clsAuth: item.clsAuth === 'Y'
          //   }
          // })
          // res.data = dataTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 프로그램 권한 저장(추가)
  createPgmAuth(authCd, topMenuCd, payload) {
    // console.log('SysAuthService > createPgmAuth() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post(`/sys/pgm-auths/${authCd}/${topMenuCd}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 권한코드 수정
  // pk가 3개로 구성되어 대표키 하나만 패스변수로 넘기고 나머지는 paylaod에 포함 처리
  updatePgmAuth(pgmId, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/pgm-auths/${pgmId}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 권한코드 삭제
  deletePgmAuth(authCd, topMenuCd, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/pgm-auths/${authCd}/${topMenuCd}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
