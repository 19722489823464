import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 비용계획 헤더정보조회
  getIncPlan(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/inc-plan${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비용계획헤더저장
  saveIncPlan(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/inc-plan', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 비용계획 정보조회
  getIncExpPlan(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/exp-plans${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadExcel(files, boId, incYy, typCd) {
    return commonUtils.$upload(
      `inc/exp-plans/xls/${boId}/${incYy}/${typCd}`,
      files[0]
    )
  },

  // 비용계획저장
  saveIncExpPlan(incExpPlans, typCdNm) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post(`/inc/exp-plans/${typCdNm}`, incExpPlans)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비용계획 마감
  procIncExpCls(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/exp-plans/cls', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 비용계획 삭제
  deleteIncExpPlan(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(
          `/inc/exp-plans/${payload.boId}/${payload.incYy}/${payload.incMon}/${payload.seq}/${payload.typCdNm}`,
          payload
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비용계획에 해당하는 매출조회
  getIncSalPlanByExps(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/exp-plans/sales${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
