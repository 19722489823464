import incMonAggrService from '@/api/services/income/inc-mon-aggr-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_INC_MON_AGGR_LIST = 'FETCH_INC_MON_AGGR_LIST'

// 확정구분 공통코드 리스트
const FETCH_DEF_LIST = 'FETCH_DEF_LIST'
// 화폐구분 공통코드 리스트
const FETCH_UNTDP_LIST = 'FETCH_UNTDP_LIST'
// 그룹1코드조회(GRP01)
const FETCH_GRP01_LIST = 'FETCH_GRP01_LIST'
// 행선택
const FETCH_INC10_LIST = 'FETCH_INC10_LIST'
// 열선택
const FETCH_INC11_LIST = 'FETCH_INC11_LIST'
// 열선택(비교)
const FETCH_INC12_LIST = 'FETCH_INC12_LIST'

const incMonAggrStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    // 손익집계리스트
    incMonAggrs: [],

    incMonAggr: {
      // boId: 999,
      // IncYy: '2022',
      // incItmId: '',
      // mon01Amt: '',
      // ..
      // mon12Amt: ''
    },
    // 확정구분
    defCls: [],
    // 화폐단위
    untDps: [],
    // 사업구분(그룹1)
    grp01s: [],
    // 행선택
    inc10s: [],
    // 열선택
    inc11s: [],
    // 열선택(비교)
    inc12s: []
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_INC_MON_AGGR_LIST](state, incMonAggrs) {
      state.incMonAggrs = incMonAggrs
    },

    [FETCH_DEF_LIST](state, defCls) {
      state.defCls = defCls
    },

    [FETCH_UNTDP_LIST](state, untDps) {
      state.untDps = untDps
    },

    [FETCH_GRP01_LIST](state, grp01s) {
      state.grp01s = grp01s
    },

    [FETCH_INC10_LIST](state, inc10s) {
      state.inc10s = inc10s
    },

    [FETCH_INC11_LIST](state, inc11s) {
      state.inc11s = inc11s
    },

    [FETCH_INC12_LIST](state, inc12s) {
      state.inc12s = inc12s
    }
  },

  actions: {
    // 확정구분 공통코드 조회
    fetchDefClList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_DEF_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 화폐단위 조회
    fetchUntDpList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_UNTDP_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 그룹1(사업구분)
    fetchGrp01List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            commit(FETCH_GRP01_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 행선택
    fetchInc10List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_INC10_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 열선택
    fetchInc11List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_INC11_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 열선택(비교)
    fetchInc12List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_INC12_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 손익집계조회 리스트
    fetchIncMonAggrList({ commit }, { queryStr = '' }) {
      return new Promise((resolve, reject) => {
        incMonAggrService
          .getIncMonAggrList(queryStr)
          .then((res) => {
            commit(FETCH_INC_MON_AGGR_LIST, res.data || [])

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearIncMonAggrs({ commit }) {
      commit(FETCH_INC_MON_AGGR_LIST, [])
    }
  }
}

export default incMonAggrStore
