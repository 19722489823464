<template>
  <header>
    <div class="header_in">
      <h1>{{ $t('HeaderBar.content.systemTitle') }}</h1>

      <nav></nav>

      <ul v-if="isLogin" class="top_side">
        <li v-if="me.authCd === 'LGL-001'">
          <span>{{ siteMode }}</span>
        </li>
        <li v-if="me.authCd === 'LGL-001'">
          <span class="lang" @click="changeLang">{{
            $i18n.locale === 'ko_KR' ? 'KOR' : 'ENG'
          }}</span>
        </li>
        <li>
          <span class="user">{{
            $i18n.locale === 'ko_KR' ? me.userNm : me.userNmEng
          }}</span>
          <el-button
            type=""
            class="pw_change"
            :title="$t('HeaderBar.content.btnChangePwd')"
            @click="popUpPwdChange"
          ></el-button>
        </li>
        <li>
          <el-button type="" @click="onLogout" class="logout">{{
            $t('HeaderBar.content.btnLogout')
          }}</el-button>
        </li>
      </ul>
    </div>
  </header>
  <!-- 프로그램 팝업 시작 -->
  <el-dialog
    :title="$t('HeaderBar.popup.title')"
    v-model="dialogVisible"
    width="500px"
    destroy-on-close
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false" 
    center
  >
    <div class="pop_content">
      <!-- 입력영역 -->
      <div class="input_group col_item02">
        <el-form :model="pwdObj" :inline="true" class="demo-form-inline">
          <el-row>
            <el-col :span="24" class="br_none">
              <el-form-item :label="$t('HeaderBar.popup.content.curPwd')">
                <el-input
                  type="password"
                  show-password
                  v-model="pwdObj.userPwd"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="br_none">
              <el-form-item :label="$t('HeaderBar.popup.content.newPwd')">
                <el-input
                  type="password"
                  show-password
                  v-model="pwdObj.newPwd"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="br_none">
              <el-form-item :label="$t('HeaderBar.popup.content.newPwd2')">
                <el-input
                  type="password"
                  show-password
                  v-model="pwdObj.newPwd2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="msg_box01 mt10">
        <p>{{ $t('HeaderBar.popup.content.note1') }}</p>
        <p>{{ $t('HeaderBar.popup.content.note11') }}</p>
        <p>{{ $t('HeaderBar.popup.content.note12') }}</p>
        <p>{{ $t('HeaderBar.popup.content.note13') }} !@#$%^&amp;*()?_~</p>
      </div>
      <div class="msg_box01 mt10">
        <p>{{ $t('HeaderBar.popup.content.note2') }}</p>
        <p>{{ $t('HeaderBar.popup.content.note21') }}</p>
        <p>{{ $t('HeaderBar.popup.content.note22') }}</p>
        <p>{{ $t('HeaderBar.popup.content.note23') }}</p>
      </div>
      <!-- //입력영역 -->
    </div>

    <template #footer>
      <span class="dialog-footer">
        <div class="btm_btn_area">
          <ul class="btn_list_r">
            <li>
              <el-button type="" @click="doChangePwd" class="">
                {{ $t('HeaderBar.popup.content.btnChange') }}
              </el-button>
            </li>
          </ul>
        </div>
      </span>
    </template>
  </el-dialog>
  <!-- 프로그램 팝업 종료 -->
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import headerService from '@/api/common/header-service'
import commonService from '@/api/services/cmm/common-service'

import VueCookies from 'vue-cookies'
import dayjs from 'dayjs'

const loginAuthStore = 'loginAuthStore'

export default {
  name: 'HeaderBar',
  components: {},

  data() {
    return {
      timerId: null,

      // 비밀번호 Obj
      pwdObj: {
        userId: '',
        compId: '',
        userPwd: '', // 현재 Password
        newPwd: '', // new Password
        newPwd2: '' // new Password 확인
      },

      // 모달 창 관련
      dialogVisible: false,
      siteMode: ''
    }
  },
  created() {
    console.log('Header > created() > default locale :', this.$i18n.locale)
    // 로그인 사용자의 사용 언어로 현재 front-end의 locale 변경
    if (this.me?.useLang) {
      this.$i18n.locale = this.me.useLang
    }
    console.log(
      'Header > created() > user definded locale :',
      this.$i18n.locale
    )
    // SYS 정보 조회(공통코드 SYS)
    commonService
      .getCommonCodeList('SYS', this.$i18n.locale)
      .then((res) => {
        const siteMap = res.data.find((item) => item.itemCd === 'SITE_MODE')
        this.siteMode = siteMap.cdVal
      })
      // eslint-disable-next-line handle-callback-err
      .catch((err) => {})
  },

  mounted() {
    // 로그인이 된 경우 실행
    if (this.isLogin) {
      console.log(
        'Header > mounted() > watchLogout() > Watching(token) Started...'
      )
      this.watchLogout()

      // 비밀번호 변경 예정일이 지났으면 비번 변경 팝업 표시
      if (this.me && this.me.pwdChgMustYn === 'Y') {
        alert(this.$t('HeaderBar.script.msg.confirmPwdChange'))
        this.popUpPwdChange()
         
        // else {
        //   this.dalayPwdValidTerm()
        // }
      }
    }
  },
  unmounted() {
    // 종료시 timer 존재하면 제거
    if (this.timerId) {
      console.log('Header > unmounted() > Watching(token) Ended...')
      clearInterval(this.timerId)
    }
  },

  computed: {
    ...mapState(loginAuthStore, ['me']),
    ...mapGetters(loginAuthStore, ['isLogin'])
  },

  watch: {},

  methods: {
    ...mapActions('loginAuthStore', ['svrLogout', 'logout']),

    // 로그아웃 실행 함수
    // async logout() {
    //   await this.$store.dispatch('sampleLoginStore/serverLogout')
    // }

    onLogout() {
      // console.log('Header > isLogin =>', this.isLogin);
      // if (!confirm(this.$t('header.script.msg.confirmLogout')))
      if (!confirm(this.$t('HeaderBar.script.msg.logout'))) return

      // 로그아웃 처리
      // 로그인 상태에서 refToken이 살아 있으면 서버 로그아웃,
      // 아니면 클라이언트 로그아웃
      const refToken = VueCookies.get('refreshToken')

      if (this.isLogin && refToken) {
        const loginInfo = {
          userId: this.me.userId
        }
        this.svrLogout(loginInfo)
      } else {
        this.logout()
      }
    },
    // RT가 만료여부 주기적으로 watch
    watchLogout() {
      // 1분 간격으로 RT토큰 체크
      const intervalTime = process.env.VUE_APP_COOKIE_WATCH_INTERVAL || 30000
      // console.log('Header > watchLogout() > intervalTime =>', intervalTime)
      const that = this

      this.timerId = setInterval(function () {
        const isRT = VueCookies.get('refreshToken')
        console.log(
          'Header > watchLogout() > Watching(token)... > curTime : ',
          // isRT,
          dayjs().format('YYYY-MM-DD HH:mm:ss')
        )

        if (!isRT) {
          // "인증 토큰이 만료되어 자동 종료합니다."
          alert(that.$t('HeaderBar.script.msg.tokenExpired'))
          // refToken까지 만료된 경우이므로 클라이언트 로그아웃
          clearInterval(that.timerId)
          that.logout()
        }
      }, intervalTime)
    },
    // 사용 언어 변경(관리자용)
    changeLang() {
      this.$i18n.locale = this.$i18n.locale === 'ko_KR' ? 'en_US' : 'ko_KR'
      // this.$i18n.locale = 'en_US'
      console.log('Header > UI Locale Changed > ', this.$i18n.locale)
    },

    // 비밀번호 90일 연장 사용 중지 상태
    dalayPwdValidTerm() {
      this.pwdObj.userId = this.me.userId // 사용자 id 설정
      this.pwdObj.compId = this.me.compId // comp id 설정

      headerService
        .delayUserPwd(this.pwdObj)
        .then((res) => {
          alert(this.$t('HeaderBar.script.msg.delayPwdOk'))
          this.dialogVisible = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          // 에러는 Interceptor에서 alert처리
          // alert(err.svrMessage)
        })
    },

    // 비밀번호 변경 화면 표시
    popUpPwdChange() {
      if (!this.me) {
        alert(this.$t('HeaderBar.script.msg.noLogin'))
        return
      }

      this.pwdObj.userId = this.me.userId // 사용자 id 설정
      this.pwdObj.compId = this.me.compId // comp id 설정
      this.ResetPwdObj() // pwd만 reset

      this.dialogVisible = true
    },
    // 비밀번호 변경 처리
    doChangePwd() {
      // console.log('Header > doChangePwd() > pwdObj =>', this.PwdObj)
      // eslint-disable-next-line no-useless-return
      if (!this.checkPwdRule(this.pwdObj)) return

      headerService
        .changeUserPwd(this.pwdObj)
        .then((res) => {
          if (res.svrCode === 200) {
            alert(this.$t('HeaderBar.script.msg.pwdChangeOk'))
            this.dialogVisible = false
          } else {
            alert(res.svrMessage)
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          // err객체는 하부 속성까지 정확하게 지정해야 표시가 됨
          console.log(
            '$$$ HeaderPage > doChangePwdgin > catch err, err.svrCode, err.svrMessage :',
            err,
            err.httpStatus,
            err.svrCode,
            err.svrMessage
          )
          // 에러는 Interceptor에서 alert처리
          // alert(err.svrMessage)
          // 비번 입력 초기화
          this.ResetPwdObj()
        })
    },

    checkPwdRule(pwdObj) {
      // console.log('Header > checkPwdRule() > pwdObj =>', pwdObj)

      const { userId, userPwd, newPwd, newPwd2 } = pwdObj

      // 기존 비밀번호와 새 비밀번호 일치 여부
      if (userPwd === newPwd) {
        alert(this.$t('HeaderBar.popup.msg.pwdChangeError1'))
        return false
      }

      // 재입력 일치 여부
      if (newPwd !== newPwd2) {
        alert(this.$t('HeaderBar.popup.msg.pwdChangeError2'))
        return false
      }

      // 공백 사용 체크
      if (newPwd.search(/\s/) !== -1) {
        alert(this.$t('HeaderBar.popup.msg.pwdChangeError3'))
        return false
      }

      // === 비밀번호 변경 RULE 체크 ===

      // 영문 대소문자, 숫자, 특수문자 3종 이상 혼용
      let useCnt = 0
      if (newPwd.search(/[0-9]/g) !== -1) useCnt++
      if (newPwd.search(/[A-Z]/g) !== -1) useCnt++
      if (newPwd.search(/[a-z]/g) !== -1) useCnt++
      if (newPwd.search(/[!@#$%^&*()?_~]/g) !== -1) useCnt++

      // console.log('Header > checkPwdRule() > useCnt =>', useCnt)

      // 2종 10자 이상 or 3종 8자 이상
      if (!/^[a-zA-Z0-9!@#$%^&*()?_~]{8,}$/.test(newPwd) || 
      (useCnt < 3 && /^[a-zA-Z0-9!@#$%^&*()?_~]{8,}$/.test(newPwd)) || 
      (useCnt < 2 && /^[a-zA-Z0-9!@#$%^&*()?_~]{10,}$/.test(newPwd))) {
        alert(this.$t('HeaderBar.popup.msg.pwdChangeError4'))

        return false
      }
      // 동일한 문자/숫자 3번 이상, 연속된 문자
      if (/(\w)\1\1/.test(newPwd) || this.isContinuedValue(newPwd)) {
        alert(this.$t('HeaderBar.popup.msg.pwdChangeError6'))

        return false
      }

      // 아이디 포함 여부
      if (userId && newPwd.search(userId) > -1) {
        alert(this.$t('HeaderBar.popup.msg.pwdChangeError7'))

        return false
      }

      // 한글 사용
      // if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(newPwd)) {
      //   alert('비밀번호에 한글을 사용 할 수 없습니다.')
      //   return false
      // }
 
      return true
    },
    // 연속된 3자의 문자 또는 숫자를 체크
    isContinuedValue(value) {
      // console.log('value = ' + value)
      let intCnt1 = 0
      let intCnt2 = 0
      let temp0 = ''
      let temp1 = ''
      let temp2 = ''
      // let temp3 = ''

      // 마지막 3자 연속을 체크할 인덱스 + 1 ex) 3자연속일 경우 2, 4자연속일 경우 3
      for (let i = 0; i < value.length - 2; i++) {
        temp0 = value.charAt(i)
        temp1 = value.charAt(i + 1)
        temp2 = value.charAt(i + 2)
        // temp3 = value.charAt(i + 3)

        // console.log('temp0 ~ 2 : ', temp0, temp1, temp2)

        if (
          temp0.charCodeAt(0) - temp1.charCodeAt(0) === 1 &&
          temp1.charCodeAt(0) - temp2.charCodeAt(0) === 1
          // && temp2.charCodeAt(0) - temp3.charCodeAt(0) === 1
        ) {
          // 뒤에서 앞으로 연속된 문자, 숫자
          intCnt1 = intCnt1 + 1
        }

        if (
          temp0.charCodeAt(0) - temp1.charCodeAt(0) === -1 &&
          temp1.charCodeAt(0) - temp2.charCodeAt(0) === -1
          // && temp2.charCodeAt(0) - temp3.charCodeAt(0) === -1
        ) {
          // 앞에서 뒤로 연속된 문자, 숫자
          intCnt2 = intCnt2 + 1
        }
      }

      // console.log('intCnt1 > 0 || intCnt2 > 0 =>', intCnt1, intCnt2)

      return intCnt1 > 0 || intCnt2 > 0
    },
    // 초기화
    ResetPwdObj() {
      this.pwdObj.userPwd = '' // 현재 Password
      this.pwdObj.newPwd = '' // new Password
      this.pwdObj.newPwd2 = '' // new Password 확인
    }
  }
}
</script>
<style>
header {
  background-color: #fff;
  height: 40px;
  position: relative;
  min-width: 1280px;
  width: 100%;
  z-index: 11;
}
header::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #b3b3b3;
}
header h1 {
  position: absolute;
  left: 14px;
  width: 290px;
  height: 14px;
  top: 15px;
  text-indent: -9999px;
  background: url('~@/assets/img/toplogo.svg') no-repeat left center;
}
header h1 a {
  display: block;
}
header nav {
  background: #fff;
  margin-left: 340px;
}
.header_in {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
.top_side {
  float: right;
  margin-right: 20px;
}
.top_side li {
  margin-left: 0;
  padding: 0 13px 0 11px;
  float: left;
  position: relative;
  margin-right: 0;
  height: 28px;
  line-height: 26px;
}

.top_side li span.lang,
.top_side li span.user,
.top_side li button {
  display: inline-block;
  height: 100%;
  position: relative;
  padding-left: 28px;
  font-weight: 400;
  font-size: 12.5px;
}
.top_side li span.lang {
  cursor: pointer;
}
.top_side li button {
  padding-left: 28px;
  background: none;
  border: 0 none;
  --el-button-hover-bg-color: #fff;
}

.top_side li button span {
  color: #222;
}
.top_side li button:hover span {
  color: #101010;
}
.top_side li span b {
  font-weight: 400;
  color: #111;
}
.top_side li span.lang:hover,
.top_side li span.user:hover,
.top_side li button:hover {
  color: #101010;
  background: #fff;
}
.top_side li span.lang::before,
.top_side li span.user::before,
.top_side li button::before,
.pw_change::before {
  content: '';
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  top: 50%;
  margin-top: -12px;
  border-radius: 50%;
  background: #7a7a7a;
  opacity: 0.87; /* 0401수정 */
}
.top_side li span.lang:hover::before,
.top_side li span.user:hover::before,
.top_side li button:hover::before {
  opacity: 1; /* 0401수정 */
}
.top_side li span.lang::before {
  background: #907756 url('~@/assets/img/icon_top_lang.svg') no-repeat center
    center;
  background-size: 63% 63%;
}
.top_side li span.user::before {
  background: url('~@/assets/img/icon_top_user.svg') no-repeat center center; /* 0401수정 */
  background-size: 84% 84%; /* 0401수정 */
  margin-top: -14px; /* 0401수정 */
}

.top_side li button.logout::before {
  background: #686868 url('~@/assets/img/icon_top_logout.svg') no-repeat 7px
    center; /* 0401수정 */
  background-size: 50% 50%;
  margin-top: -12px;
}

.top_side li + li {
  background: url('~@/assets/img/img_dot.png') no-repeat left center;
}
.top_side li span.user {
  padding-left: 23px;
}
.top_side li button.pw_change {
  text-indent: -9999px;
  margin-left: 6px !important;
  padding: 0;
  width: 24px;
}
.top_side li button.pw_change::before {
  background: #686868 url('~@/assets/img/icon_pw.svg') no-repeat center center;
  background-size: 54% 54%;
}
.top_side li button.pw_change.top_button {
  text-indent: 0;
  width: inherit;
  width: unset;
  padding-left: 28px;
}

.input_group.col_item02 .el-form-item__label {
  width: 190px;
}
.input_group.col_item02 .el-form-item__content {
  width: calc(100% - 190px);
}
</style>
