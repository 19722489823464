// import AnlBoKpiPage from '@/views/analysis/AnlBoKpiPage.vue';

export default [
  {
    // === Bo Summary Page ===
    path: '/anl-bo-sum',
    name: 'AnlBoSummaryPage',
    // component: AnlBoSummaryPage,
    component: () =>
      import(
        /* webpackChunkName: "Analysis" */ '@/views/analysis/AnlBoSummaryPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // === 영업이익 추이 Page ===
    path: '/anl-busi-prof',
    name: 'AnlBusiProfPage',
    // component: AnlBusiProfPage,
    component: () =>
      import(
        /* webpackChunkName: "Analysis" */ '@/views/analysis/AnlBusiProfPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // === 거래처 매출분석 Page ===
    path: '/anl-cust-perf',
    name: 'AnlCustPerfPage',
    // component: AnlCustPerfPage,
    component: () =>
      import(
        /* webpackChunkName: "Analysis" */ '@/views/analysis/AnlCustPerfPage.vue'
      ),
    meta: { auth: true }
  }
]
