const routes = [
  {
    path: '/datagrid/list',
    name: 'List',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/datagrid/List.vue'
      )
  },
  {
    path: '/datagrid/edit',
    name: 'Edit',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/datagrid/Edit.vue'
      )
  },
  {
    path: '/datagrid/paging',
    name: 'Paging',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/datagrid/Paging.vue'
      )
  }
]

export default routes
