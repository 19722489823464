import VueCookies from 'vue-cookies'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NotFoundPage from '@/views/NotFoundPage.vue'
import LoginPage from '@/views/common/LoginPage.vue'
import incomeRoutes from './routes/income-routes'
import financeRoutes from './routes/finance-routes'
import infraRoutes from './routes/infra-routes'
import baseRoutes from './routes/base-routes'
import analysisRoutes from './routes/analysis-routes'
import systemRoutes from './routes/system-routes'
import designTemplateRoutes from './routes/design-template-routes'

import sampleRoutes from './samples'

const routes = [
  {
    // root url로 접속하면 로그인 페이지로 이동
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    // App 메인 홈 페이지
    // webpackPrefetch 설정은 ture로 설정 or 0로 설정
    // 주의할 점은 false가 아니라 0로 설정하는 것, 아니면 아예 설정항목을 제거
    path: '/home',
    name: 'MainHomePage',
    component: () =>
      import(
        /* webpackChunkName: "Analysis" */ '@/views/analysis/AnlBoSummaryPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // 존재하지 않는 페이지를 접근할 때
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage
  },

  // === 메뉴 그룹별 라우트 정보 ===
  ...incomeRoutes, // 손익
  ...financeRoutes, // 재무
  ...infraRoutes, // 인프라
  ...baseRoutes, // 기준
  ...analysisRoutes, // 분석
  ...systemRoutes, // 시스템

  // === 템플릿 Publishing ===
  ...designTemplateRoutes,

  // framework 기본 샘플
  ...sampleRoutes

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 메뉴 이동 시 권한 체크
router.beforeEach((to, from, next) => {
  const isLogin = store.getters['loginAuthStore/isLogin']

  console.log(
    '>>> router.beforeEach > isAuthorized, to.meta.auth, from.path, to.path : ',
    isLogin,
    to.meta.auth,
    from.path,
    to.path
  )
  const loginFlag = (isLogin && VueCookies.get('refreshToken'))

  if ((to.path !== '/login') && !loginFlag) {
    console.log('Not logged in.')
    next('/login')
    return
  } else if (to.path === '/login' && loginFlag) {
    console.log(`this path(${to.path}) is redirect.`)
    next('/home')
  } else if (!to.meta.auth && loginFlag) {
    console.log(`this path(${to.path}) is not authorized.`)
    next('/home')
    return
  } 
  next()
})

export default router
