const routes = [
  {
    path: '/grid-crud',
    name: 'GridCrud',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/dev-sample/GridCrud.vue'
      )
  },
  {
    path: '/excel/excel-upload',
    name: 'ExcelUpload',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/dev-sample/ExcelUpload.vue'
      )
  },
  {
    path: '/excel/excel-down',
    name: 'ExcelDown',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/dev-sample/ExcelDown.vue'
      )
  },
  {
    path: '/grid-crud2',
    name: 'GridCrud2',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/dev-sample/GridCrud2.vue'
      )
  },
  // {
  //   path: '/grid-crud3',
  //   name: 'GridCrud3',
  //   component: () =>
  //     import(
  //       /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/dev-sample/GridCrud3.vue'
  //     )
  // },
  {
    path: '/line-chart-sample',
    name: 'LineChartSample',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/dev-sample/LineChartSample.vue'
      )
  }
]

export default routes
