import { axiosApi } from '@/api'

export default {
  // 파일정보 목록 조회
  getFileInfoList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/s3-obj-infos${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 파일정보 저장
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  saveFileInfo(payload) {
    const formData = new FormData()
    formData.append('file', payload)
    return new Promise((resolve, reject) => {
      axiosApi
        .put('/bse/s3-obj-infos', formData, {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 파일정보 삭제
  deleteFileInfo(objNm) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/bse/s3-obj-infos/${objNm}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
