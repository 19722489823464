import { axiosApi } from '@/api'

export default {
  // 비밀번호 수정
  changeUserPwd(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/pwd/', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비밀번호 연장
  delayUserPwd(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/pwd-delay/', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
