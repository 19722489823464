import incExpPerfService from '@/api/services/income/inc-exp-perf-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_INC_RET = 'FETCH_INC_RET'
const FETCH_EXP_RET_LIST = 'FETCH_EXP_RET_LIST'
// 확정구분 공통코드 리스트
const FETCH_DEF_LIST = 'FETCH_DEF_LIST'
// 매출조회
const FETCH_SAL_RET_BYEXP_LIST = 'FETCH_SAL_RET_BYEXP_LIST'
const FETCH_M101_LIST = 'FETCH_M101_LIST'

const incExpPerfStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    incExpRets: [],

    incRet: {
      boId: -1
      // IncYymm: '2022',
      // defCl: 'Q1',
      // retCls1: 'N',
      // retCls2: 'N',
      // crncyCd: '',
      // cntryCd: '',
    },
    // 확정구분
    defCls: [],
    // 매출조회리스트
    incSalRetsByexps: [],
    // 유형(영업실적/ETC) : M101
    m101s: []
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_INC_RET](state, incRet) {
      state.incRet = incRet
    },
    [FETCH_EXP_RET_LIST](state, incExpRets) {
      state.incExpRets = incExpRets
    },
    [FETCH_DEF_LIST](state, defCls) {
      state.defCls = defCls
    },
    [FETCH_SAL_RET_BYEXP_LIST](state, incSalRetsByexps) {
      state.incSalRetsByexps = incSalRetsByexps
    },
    [FETCH_M101_LIST](state, m101s) {
      state.m101s = m101s
    }
  },
  actions: {
    // 확정구분 공통코드 조회
    fetchDefClList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_DEF_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearInExpRets({ commit }) {
      commit(FETCH_EXP_RET_LIST, [])
    },
    // 실적 헤더정보 조회
    fetchIncRet({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incExpPerfService
          .getIncRet(queryStr)
          .then((res) => {
            commit(FETCH_INC_RET, res.data || { finlVer: 0 })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncExpRetList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incExpPerfService
          .getIncExpRet(queryStr)
          .then((res) => {
            commit(FETCH_EXP_RET_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    uploadExcel({ commit }, { files, boId, incYymm, defCl, typCd, sheetNum }) {
      return new Promise((resolve, reject) => {
        incExpPerfService
          .uploadExcel(files, boId, incYymm, defCl, typCd, sheetNum)
          .then((res) => {
            commit(FETCH_EXP_RET_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 유형 공통코드 조회
    fetchM101List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_M101_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // row삭제
    deleteIncExpRet({ commit, state }, selIdx) {
      state.incExpRets.splice(selIdx, 1)
      commit(FETCH_EXP_RET_LIST, state.incExpRets)
    },
    // 매출조회
    fetchIncSalPerfDelByExpList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incExpPerfService
          .getIncSalPerfByExps(queryStr)
          .then((res) => {
            commit(FETCH_SAL_RET_BYEXP_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

export default incExpPerfStore
