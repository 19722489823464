import incExpPlanService from '@/api/services/income/inc-exp-plan-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_INC_PLAN = 'FETCH_INC_PLAN'
const FETCH_EXP_PLAN_LIST = 'FETCH_EXP_PLAN_LIST'
const FETCH_SAL_PLAN_BYEXP_LIST = 'FETCH_SAL_PLAN_BYEXP_LIST'
const FETCH_M101_LIST = 'FETCH_M101_LIST'

const incExpPlanStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    incExpPlans: [],
    totalCount: 0,
    totalPage: 0,

    incPlan: {
      // boId: 999,
      // boIncYy: '2022',
      // incMon: '01',
      finlVer: 0
      // plnCls1: 'Y',
      // crncyCd: '',
    },

    // 매출조회리스트
    incSalPlansByexps: [],
    // 유형(영업실적/ETC) : M101
    m101s: []
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_INC_PLAN](state, incPlan) {
      state.incPlan = incPlan
    },
    [FETCH_EXP_PLAN_LIST](state, incExpPlans) {
      state.incExpPlans = incExpPlans
    },
    [FETCH_SAL_PLAN_BYEXP_LIST](state, incSalPlansByexps) {
      state.incSalPlansByexps = incSalPlansByexps
    },
    [FETCH_M101_LIST](state, m101s) {
      state.m101s = m101s
    }
  },
  actions: {
    clearInExpPlans({ commit }) {
      commit(FETCH_EXP_PLAN_LIST, [])
    },
    // === for Grid sample
    fetchIncPlan({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incExpPlanService
          .getIncPlan(queryStr)
          .then((res) => {
            commit(FETCH_INC_PLAN, res.data || { finlVer: 0 })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncExpPlanList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incExpPlanService
          .getIncExpPlan(queryStr)
          .then((res) => {
            commit(FETCH_EXP_PLAN_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    uploadExcel({ commit }, { files, boId, incYy, typCd }) {
      return new Promise((resolve, reject) => {
        incExpPlanService
          .uploadExcel(files, boId, incYy, typCd)
          .then((res) => {
            commit(FETCH_EXP_PLAN_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 유형 공통코드 조회
    fetchM101List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_M101_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // row삭제
    deleteIncExpPlan({ commit, state }, selIdx) {
      state.incExpPlans.splice(selIdx, 1)
      commit(FETCH_EXP_PLAN_LIST, state.incExpPlans)
    },
    // 매출조회
    fetchIncSalPlanDelByExpList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incExpPlanService
          .getIncSalPlanByExps(queryStr)
          .then((res) => {
            commit(FETCH_SAL_PLAN_BYEXP_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

export default incExpPlanStore
