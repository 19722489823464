import { axiosApi } from '@/api'

export default {
  // 법인 장치 현황 관리 리스트 조회
  getEquipmentMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inf/equipments${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 장치 현황 관리 추가
  createEquipmentMgmt(payload) {
    // console.log('infEquipmentMgmtService > createEquipmentMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inf/equipments', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 장치 현황 관리 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateEquipmentMgmt(id, payload) {
    // console.log('infEquipmentMgmtService > updateEquipmentMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/inf/equipments/${id}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 장치 현황 관리 삭제
  deleteEquipmentMgmt(id) {
    // console.log('infEquipmentMgmtService > deleteEquipmentMgmt() > boRntId :', id)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/inf/equipments/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
