import { axiosApi } from '@/api'
// import commonUtils from '@/utils/common-utils'

export default {
  // 손익데이터 리스트 조회
  getIncDataInqList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/data-inqs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
