import finArIncreAnalysisService from '@/api/services/finance/fin-ar-incre-analysis-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_AR_SMMRY_CUST_LIST = 'FETCH_AR_SMMRY_CUST_LIST' // 미수채권
const FETCH_AR_SMMRY_CUST = 'FETCH_AR_SMMRY_CUST'
const FETCH_TRRT_LIST = 'FETCH_TRRT_LIST' // 지역
const FETCH_TRRT = 'FETCH_TRRT'
// 화폐구분 공통코드 리스트
const FETCH_UNTDP_LIST = 'FETCH_UNTDP_LIST'

const finArIncreAnalysisStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 미수채권
    arSmmryCusts: [],
    arSmmryCust: {},

    // 지역
    trrts: [],
    trrtId: {
      // codeType:"TRRT"
      // itemCd:"1"
      // label:"중국지역"
      // labelEng:"중국지역"
      // value:1000102
    },

    // 화폐단위
    untDps: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_AR_SMMRY_CUST_LIST](state, arSmmryCusts) {
      state.arSmmryCusts = arSmmryCusts
    },
    [FETCH_AR_SMMRY_CUST](state, arSmmryCust) {
      state.arSmmryCust = arSmmryCust
    },
    [FETCH_TRRT_LIST](state, trrts) {
      state.trrts = trrts
    },
    [FETCH_TRRT](state, trrtId) {
      state.trrtId = trrtId
    },

    [FETCH_UNTDP_LIST](state, untDps) {
      state.untDps = untDps
    }
  },

  actions: {
    fetchIncreAnalysisList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        finArIncreAnalysisService
          .getArIncreAnalysisList(queryStr)
          .then((res) => {
            // 거래처 코드가 0인 경우 (미등록)으로 보여주기
            for (let i = 0; i < res.length; i++) {
              if (res[i].boCustCd === '0') {
                res[i].boCustCd = '(미등록)'
              }
            }
            commit(FETCH_AR_SMMRY_CUST_LIST, res.data)
            console.log('fetchIncreAnalysisList > res', res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearIncreAnalysis({ commit }) {
      commit(FETCH_AR_SMMRY_CUST_LIST, [])
    },
    uploadExcel({ commit }, { files, arYymm, sheetNum }) {
      return new Promise((resolve, reject) => {
        finArIncreAnalysisService
          .uploadExcel(files, arYymm, sheetNum)
          .then((res) => {
            commit(FETCH_AR_SMMRY_CUST_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteIncreAnalysis({ commit, state }, selIdx) {
      state.arSmmryCusts.splice(selIdx, 1)
      commit(FETCH_AR_SMMRY_CUST_LIST, state.arSmmryCusts)
    },
    fetchTrrtList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_TRRT_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchTrrtId({ commit }, selectTrrtId) {
      commit(FETCH_TRRT, selectTrrtId)
    },

    // 화폐단위 조회
    fetchUntDpList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_UNTDP_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

export default finArIncreAnalysisStore
