import sysCodeMgmtService from '@/api/services/system/sys-code-mgmt-service'

// === for gred sample
const FETCH_CODE_TYPE_LIST = 'FETCH_CODE_TYPE_LIST'
const SELECT_CODE_TYPE = 'SELECT_CODE_TYPE'
const FETCH_CODE_VAL_LIST = 'FETCH_CODE_VAL_LIST'
const SELECT_CODE_VAL = 'SELECT_CODE_VAL'
const FETCH_SVC_TYPE_LIST = 'FETCH_SVC_TYPE_LIST'
const SELECT_SVC_TYPE = 'SELECT_SVC_TYPE'
const CLEAR_STORE = 'CLEAR_STORE'

const sysCodeMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 사용자
    codeTypes: [],
    codeType: {
      // tccId: 1000000, // 공통코드 ID
      // compId: 100, // 회사 ID
      // bseCd: 'GRP01', // 기준코드
      // typNm: '그룹1', // 유형먕
      // typNmEng: 'Group1', // 유형명 영어
      // cdCl: 'S', // 코드구분(미사용) -> 제거예정
      // userCl: 'USER', // 사용자 구분(MMGR:관리자, USER:사용자)
      // cdLv: 1, // 코드레벨
      // delYn: 'N' // 삭제 YN
    },

    codeVals: [],
    codeVal: {
      // tccvId: 1000000, // 공통코드 상세 ID
      // tccId: 1000000, // 공통코드 유형 ID
      // stdCd: '1', // 기초(상세) 코드
      // stdCdNm: '기존', // 기초(상세) 코드 명
      // stdCdNmEng: '기존', // 기초(상세) 코드 명 영어
      // cdVal: '', // 코드 값
      // viewSeq: 1, // 표시 순서
      // delYn: 'N' // 삭제 YN
    },

    svcTypes: [],
    svcType: {
      // tccvId: 1000000, // 공통코드 상세 ID
      // svcTyp: '101', // 서비스 유형
      // cl1Cd: '1', // 구분1 코드
      // svcNm: '항공', // 서비스 명
      // svcSnm: '항공', // 서비스 단축명
      // svcNmEng: 'AirLine', // 서비스 명 영어
      // svcSnmEng: 'AL', // 서비스 단축명 영어
      // viewSeq: 1, // VIEW 순서
      // delYn: 'N' // 삭제 YN
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_CODE_TYPE_LIST](state, codes) {
      state.codeTypes = codes
      state.codeVals = []
      state.svcTypes = []
    },
    [SELECT_CODE_TYPE](state, code) {
      state.codeType = code
    },
    [FETCH_CODE_VAL_LIST](state, codes) {
      state.codeVals = codes
      state.svcTypes = []
    },
    [SELECT_CODE_VAL](state, code) {
      state.codeVal = code
    },
    [FETCH_SVC_TYPE_LIST](state, codes) {
      state.svcTypes = codes
    },
    [SELECT_SVC_TYPE](state, code) {
      state.svcType = code
    },
    [CLEAR_STORE](state) {
      state.codeTypes = []
      state.codeVals = []
      state.svcTypes = []
    }
  },

  actions: {
    // 코드유형 리스트 조회
    fetchCodeTypeList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysCodeMgmtService
          .getCodeTypeList(queryStr)
          .then((res) => {
            commit(FETCH_CODE_TYPE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 코드 유형 선택
    selectCodeType({ commit }, selectCodeType) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_CODE_TYPE, selectCodeType)
    },

    // 코드값 리스트 조회
    fetchCodeValList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysCodeMgmtService
          .getCodeValList(queryStr)
          .then((res) => {
            commit(FETCH_CODE_VAL_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 코드값 선택
    selectCodeVal({ commit }, selectCodeVal) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_CODE_VAL, selectCodeVal)
    },

    // 서비스 유형 리스트 조회
    fetchSvcTypeList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysCodeMgmtService
          .getSvcTypeList(queryStr)
          .then((res) => {
            commit(FETCH_SVC_TYPE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 서비스 유형 선택
    selectSvcType({ commit }, selectSvcType) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_SVC_TYPE, selectSvcType)
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default sysCodeMgmtStore
