import incFinBalanceSheetService from '@/api/services/finance/fin-balance-sheet-service'
import incFinCommonService from '@/api/services/finance/fin-common-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_FIN_BO_BS_INFO = 'FETCH_FIN_BO_BS_INFO'
const FETCH_FIN_BS_SHEET_LIST = 'FETCH_FIN_BS_SHEET_LIST'
const FETCH_FRM_INFO_LIST = 'FETCH_FRM_INFO_LIST'
const FETCH_CRU01_LIST = 'FETCH_CRU01_LIST'
// 법인목록관리
const FETCH_SOURCE_BO_LIST = 'FETCH_SOURCE_BO_LIST'
const FETCH_TARGET_BO_LIST = 'FETCH_TARGET_BO_LIST'

const finBalanceSheetStore = {
  namespaced: true,

  // // === state ===
  // // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for grid

    // 법인정보(헤더 데이터) 3 rows
    finBoBsInfos: [
      {
        // ... 법인만큼 생성
      }
    ],

    // 재무제표 목록
    finBsSheets: [],

    // 참고용
    finBsSheet: {
      // frmId : 1000001
      // frmNm : Ⅰ. 유동자산
      // finAmt_{boId}: 10,000,
      // ... 법인만큼 생성
    },

    // 양식리스트
    frmInfos: [],
    // 통화구분리스트(원화/외화)
    cru01s: [],

    // 법인목록관리 왼쪽 법인리스트
    sourceBos: [],
    // 법인목록관리 오른쪽 법인리스트
    targetBos: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_FIN_BO_BS_INFO](state, finBoBsInfos) {
      state.finBoBsInfos = finBoBsInfos
    },
    [FETCH_FIN_BS_SHEET_LIST](state, finBsSheets) {
      state.finBsSheets = finBsSheets
    },
    [FETCH_FRM_INFO_LIST](state, frmInfos) {
      state.frmInfos = frmInfos
    },
    [FETCH_CRU01_LIST](state, cru01s) {
      state.cru01s = cru01s
    },

    // 법인목록관리 리스트
    [FETCH_SOURCE_BO_LIST](state, sourceBos) {
      state.sourceBos = sourceBos
    },

    [FETCH_TARGET_BO_LIST](state, targetBos) {
      state.targetBos = targetBos
    }
  },

  actions: {
    // 재무제표 리스트 조회
    fetchFinBsSheetList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incFinBalanceSheetService
          .getFinBsSheetList(queryStr)
          .then((res) => {
            const finBoBsInfos =
              res.data.finBoBsInfos == null ||
              res.data.finBoBsInfos.length === 0
                ? [{ frmBos: [] }]
                : res.data.finBoBsInfos
            commit(FETCH_FIN_BO_BS_INFO, finBoBsInfos)
            commit(FETCH_FIN_BS_SHEET_LIST, res.data.finBsSheets)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 양식정보 리스트 조회
    fetchFrmInfoList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incFinCommonService
          .getFrmInfoList(queryStr)
          .then((res) => {
            commit(FETCH_FRM_INFO_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 통화(원화/외화)
    fetchCru01List({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_CRU01_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 재무제표 엑셀 업로드
    uploadExcel({ commit }, { files, frmId, crncyType, sheetNum }) {
      return new Promise((resolve, reject) => {
        incFinBalanceSheetService
          .uploadExcel(files, frmId, crncyType, sheetNum)
          .then((res) => {
            const finBsSheets = res.finBsSheets.filter(
              (item) => item.frmNm != null && item.frmNm.length > 0
            )
            commit(FETCH_FIN_BO_BS_INFO, res.finBoBsInfos)
            commit(FETCH_FIN_BS_SHEET_LIST, finBsSheets)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 법인목록 리스트 조회
    fetchFinBoMgmtList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incFinCommonService
          .getFinBoMgmtList(queryStr, 'ALL')
          .then((res) => {
            commit(FETCH_SOURCE_BO_LIST, res.data.sourceBos)
            commit(FETCH_TARGET_BO_LIST, res.data.targetBos)

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 초기화
    clearFinBsSheets({ commit }) {
      commit(FETCH_FIN_BO_BS_INFO, [{}, {}, {}])
      commit(FETCH_FIN_BS_SHEET_LIST, [])
    }
  }
}

export default finBalanceSheetStore
