import incDataInqService from '@/api/services/income/inc-data-inq-service'
import commonService from '@/api/services/cmm/common-service'
import sysCodeMgmtService from '@/api/services/system/sys-code-mgmt-service'

const FETCH_INC_DATA_INQ_LIST = 'FETCH_INC_DATA_INQ_LIST'

// 확정구분 공통코드 리스트
const FETCH_DEF_LIST = 'FETCH_DEF_LIST'
// 화폐구분 공통코드 리스트
const FETCH_UNTDP_LIST = 'FETCH_UNTDP_LIST'
// 유형 공통코드 리스트
const FETCH_INC01_LIST = 'FETCH_INC01_LIST'

// 구분1 공통코드 리스트
const FETCH_M102_LIST = 'FETCH_M102_LIST'
// 구분2 공통코드 리스트
const FETCH_M103_LIST = 'FETCH_M103_LIST'
// 구분3 공통코드 리스트
const FETCH_M104_LIST = 'FETCH_M104_LIST'

// 그룹1 공통코드 리스트
const FETCH_GRP01_LIST = 'FETCH_GRP01_LIST'
// 그룹2 공통코드 리스트
const FETCH_GRP02_LIST = 'FETCH_GRP02_LIST'
// 그룹3 공통코드 리스트
const FETCH_GRP03_LIST = 'FETCH_GRP03_LIST'

// 서비스 코드 리스트
const FETCH_SVC_TPY_LIST = 'FETCH_SVC_TPY_LIST'

const incDataInqStore = {
  namespaced: true,

  // // === state ===
  // // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    // 손익데이터
    incDataInqs: [],

    incDataInq: {
      // boNm: 중국법인,
      // subBoNm: 상해지점,
      // IncYy: '2022',
      // crncyCd: 'CNY',
      // itemNm: '매출',
    },
    // 확정구분
    defCls: [],
    // 화폐단위
    untDps: [],
    // 유형
    inc01s: [],
    // 구분1
    m102s: [],
    // 구분2
    m103s: [],
    // 구분3
    m104s: [],
    // 그룹1
    grp01s: [],
    // 그룹2
    grp02s: [],
    // 그룹3
    grp03s: [],
    // 서비스
    svcTpys: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_INC_DATA_INQ_LIST](state, incDataInqs) {
      state.incDataInqs = incDataInqs
    },
    [FETCH_DEF_LIST](state, defCls) {
      state.defCls = defCls
    },
    [FETCH_UNTDP_LIST](state, untDps) {
      state.untDps = untDps
    },
    // 유형
    [FETCH_INC01_LIST](state, inc01s) {
      state.inc01s = inc01s
    },
    // 구분1
    [FETCH_M102_LIST](state, m102s) {
      state.m102s = m102s
    },
    // 구분2
    [FETCH_M103_LIST](state, m103s) {
      state.m103s = m103s
    },
    // 구분3
    [FETCH_M104_LIST](state, m104s) {
      state.m104s = m104s
    },
    // 그룹1
    [FETCH_GRP01_LIST](state, grp01s) {
      state.grp01s = grp01s
    },
    // 그룹2
    [FETCH_GRP02_LIST](state, grp02s) {
      state.grp02s = grp02s
    },
    // 그룹3
    [FETCH_GRP03_LIST](state, grp03s) {
      state.grp03s = grp03s
    },
    // 서비스
    [FETCH_SVC_TPY_LIST](state, svcTpys) {
      state.svcTpys = svcTpys
    }
  },

  actions: {
    fetchAllCommonCodes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getAllCommonCodeList(payload.lang)
          .then((res) => {
            res.data.shift()
            const allCodes = res.data || []
            // 확정구분
            const defCodes = allCodes.filter(code => code.codeType === 'DEF01')
            commit(FETCH_DEF_LIST, defCodes)
            // 화폐단위
            const undtpCodes = allCodes.filter(code => code.codeType === 'UNTDP')
            commit(FETCH_UNTDP_LIST, undtpCodes)
            // 유형
            const inc01Codes = allCodes.filter(code => code.codeType === 'INC01')
            commit(FETCH_INC01_LIST, inc01Codes)
            // 구분1
            const m102Codes = allCodes.filter(code => code.codeType === 'M102')
            commit(FETCH_M102_LIST, m102Codes)
            // 구분2
            const m103Codes = allCodes.filter(code => code.codeType === 'M103')
            commit(FETCH_M103_LIST, m103Codes)
            // 구분3
            const m104Codes = allCodes.filter(code => code.codeType === 'M104')
            commit(FETCH_M104_LIST, m104Codes)
            // 그룹1
            const grp01Codes = allCodes.filter(code => code.codeType === 'GRP01')
            commit(FETCH_GRP01_LIST, grp01Codes)
            // 그룹2
            const grp02Codes = allCodes.filter(code => code.codeType === 'GRP02')
            commit(FETCH_GRP02_LIST, grp02Codes)
            // 그룹3
            const grp03Codes = allCodes.filter(code => code.codeType === 'GRP03')
            commit(FETCH_GRP03_LIST, grp03Codes)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },    
    
    // 서비스 조회
    fetchSvcTypeList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sysCodeMgmtService
          .getSvcTypeList(payload.tccvId)
          .then((res) => {
            const svcTpyTemp = res.data.map((item) => {
              const {
                svcNm: label,
                svcNmEng: labelEng,
                svcTyp: value,
                cl1Cd,
                tccvId
              } = item

              // 언어가 영어가 아니면 다른 언어로 설정
              return {
                value,
                label: payload.lang === 'en_US' ? labelEng : label,
                cl1Cd,
                labelEng,
                tccvId
              }
            })

            commit(FETCH_SVC_TPY_LIST, svcTpyTemp || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 손익데이터 리스트
    fetchIncDataInqList({ commit }, { queryStr = '' }) {
      return new Promise((resolve, reject) => {
        incDataInqService
          .getIncDataInqList(queryStr)
          .then((res) => {
            commit(FETCH_INC_DATA_INQ_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearIncDataInqs({ commit }) {
      commit(FETCH_INC_DATA_INQ_LIST, [])
    }
  }
}

export default incDataInqStore
