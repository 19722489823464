import finCurrStatusInqService from '@/api/services/finance/fin-curr-status-inq-service'
// import commonService from '@/api/services/cmm/common-service'

const FETCH_BALAN_SHEET_LIST = 'FETCH_BALAN_SHEET_LIST' // 재무제표
const FETCH_BALAN_SHEET = 'FETCH_BALAN_SHEET'
const FETCH_BO_LIST = 'FETCH_BO_LIST' // 법인
const FETCH_BO = 'FETCH_BO'
const FETCH_INC_STATEMENT_LIST = 'FETCH_INC_STATEMENT_LIST' // 손익 계산서
const FETCH_INC_STATEMENT = 'FETCH_INC_STATEMENT'

const finCurrStatusInqStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 재무제표
    balanSheets: [],
    balanSheet: {},

    // 법인
    bos: [],
    bo: {},

    // 손익 계산서
    incStatements: [],
    incStatement: {}
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_BALAN_SHEET_LIST](state, balanSheets) {
      state.balanSheets = balanSheets
    },
    [FETCH_BALAN_SHEET](state, balanSheet) {
      state.balanSheet = balanSheet
    },
    [FETCH_BO_LIST](state, bos) {
      state.bos = bos
    },
    [FETCH_BO](state, bo) {
      state.bo = bo
    },
    [FETCH_INC_STATEMENT_LIST](state, incStatements) {
      state.incStatements = incStatements
    },
    [FETCH_INC_STATEMENT](state, incStatement) {
      state.incStatement = incStatement
    }
  },

  actions: {
    fetchBalanSheetList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        finCurrStatusInqService
          .getBalanSheetList(queryStr)
          .then((res) => {
            commit(FETCH_BALAN_SHEET_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearBalanSheet({ commit }) {
      commit(FETCH_BALAN_SHEET_LIST, [])
    },

    fetchBoList({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        finCurrStatusInqService
          .getBoList()
          .then((res) => {
            commit(FETCH_BO_LIST, res.data)
            // 디폴트로 첫번째 법인 선택
            commit(FETCH_BO, JSON.parse(JSON.stringify(state.bos[0])))

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    fetchBo({ commit, state }, selectedBoId) {
      commit(FETCH_BO, selectedBoId)
    },

    fetchIncStatementList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        finCurrStatusInqService
          .getIncStatement(queryStr)
          .then((res) => {
            commit(FETCH_INC_STATEMENT_LIST, res.data)
            console.log('fetchIncStatementList > res', res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearIncStatement({ commit }) {
      commit(FETCH_INC_STATEMENT_LIST, [])
    }
  }
}

export default finCurrStatusInqStore
