import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// --- sample store ---
import devSampleStore from './samples/dev-sample-store'
// import sampleLoginStore from './samples/sample-login-store'

import loginAuthStore from './common/login-auth-store'
// import mainHomeStore from './common/main-home-store'
import commonBoStore from './modules/cmm/common-bo-store'
import commonStore from './modules/cmm/common-store'

import incSalesPlanStore from './modules/income/inc-sales-plan-store'
import incExpPlanStore from './modules/income/inc-exp-plan-store'
import incSalesPerfStore from './modules/income/inc-sales-perf-store'
import incExpPerfStore from './modules/income/inc-exp-perf-store'
import incHopayMgmtStore from './modules/income/inc-hopay-mgmt-store'
import incPerfInqStore from './modules/income/inc-perf-inq-store'
import incMonAggrStore from './modules/income/inc-mon-aggr-store'
import incCurrMonPerfStore from './modules/income/inc-curr-mon-perf-store'
import incDataInqStore from './modules/income/inc-data-inq-store'
import finTrialBalanceStore from './modules/finance/fin-trial-balance-store'
import finBalanceSheetStore from './modules/finance/fin-balance-sheet-store'
import finIncomeStatementStore from './modules/finance/fin-income-statement-store'
import finTransHistStore from './modules/finance/fin-trans-hist-store'
import finAccountsReceivableStore from './modules/finance/fin-accounts-receivable-store'
import finArIncreAnalysisStore from './modules/finance/fin-ar-incre-analysis-store'
import finCurrStatusInqStore from './modules/finance/fin-curr-status-inq-store'
import infFacilityMgmtStore from './modules/infra/inf-facility-mgmt-store'
import infEquipmentMgmtStore from './modules/infra/inf-equipment-mgmt-store'
import infEmployeeMgmtStore from './modules/infra/inf-employee-mgmt-store'
import bseBranchOfficeMgmtStore from './modules/base/bse-branch-office-mgmt-store'
import bseBoCustMgmtStore from './modules/base/bse-bo-cust-mgmt-store'
import bseExchgRateInqStore from './modules/base/bse-exchg-rate-inq-store'
import bseFormMgmtStore from './modules/base/bse-form-mgmt-store'
import bseCloseMgmtStore from './modules/base/bse-close-mgmt-store'
import bseCodeMapMgmtStore from './modules/base/bse-code-map-mgmt-store'
import bseFinFormMgmtStore from './modules/base/bse-fin-form-mgmt-store'
import bseFileMgmtStore from './modules/base/bse-file-mgmt-store'
import bseIncUpStore from './modules/base/bse-inc-up-store'
import anlBoSummaryStore from './modules/analysis/anl-bo-summary-store'
import anlBusiProfStore from './modules/analysis/anl-busi-prof-store'
import anlCustPerfStore from './modules/analysis/anl-cust-perf-store'
import sysUserMgmtStore from './modules/system/sys-user-mgmt-store'
import sysPgmMgmtStore from './modules/system/sys-pgm-mgmt-store'
import sysAuthMgmtStore from './modules/system/sys-auth-mgmt-store'
import sysCodeMgmtStore from './modules/system/sys-code-mgmt-store'
import sysAccessHistStore from './modules/system/sys-access-hist-store'

const store = createStore({
  modules: {
    // --- 샘플용 store ---
    // sampleLoginStore,
    devSampleStore,

    // --- common store ---
    loginAuthStore,
    // mainHomeStore, // 메인화면(법인요약이 대신하여 사용안함)

    // --- 업무용 store ---
    // --- cmm ---
    commonStore, // 공통
    commonBoStore, // 공통 Bo

    // --- income(손익관리) ---
    incSalesPlanStore, // 매출계획 등록
    incExpPlanStore, // 비용계획 등록
    incSalesPerfStore, // 매출실적 등록
    incExpPerfStore, // 비용실적 등록
    incHopayMgmtStore, // 본사 지급분 관리
    incPerfInqStore, // 손익조회
    incMonAggrStore, // 손익 월집계 조회
    incCurrMonPerfStore, // 손익 당월실적 조회
    incDataInqStore, // 손익 데이터 검색

    // --- finance(재무관리) ---
    finTrialBalanceStore, // 시산표 등록
    finBalanceSheetStore, // 재무재표
    finIncomeStatementStore, // 손익계산서
    finTransHistStore, // 전송이력 조회
    finAccountsReceivableStore, // 미수채권 정보등록
    finArIncreAnalysisStore, // 미수채권 증감현황
    finCurrStatusInqStore, // 재무제표 리포트

    // --- infra(인프라관리) ---
    infFacilityMgmtStore, // 시설현황 관리
    infEquipmentMgmtStore, // 장비현황 관리
    infEmployeeMgmtStore, // 인원현황 관리

    // --- base(기준괸리) ---
    bseBranchOfficeMgmtStore, // 법인관리
    bseBoCustMgmtStore, // 법인-거래처 관리
    bseExchgRateInqStore, // 환율정보 조회
    bseFormMgmtStore, // 양식관리
    bseCloseMgmtStore, // 마감현황
    bseCodeMapMgmtStore, // 코드매핑 관리
    bseFinFormMgmtStore, // 재무양식관리
    bseFileMgmtStore, // 파일관리
    bseIncUpStore, // 손익 데이터 업로드 현황

    // --- analysis(분석관리) ---
    anlBoSummaryStore, // 법인 요약
    anlBusiProfStore, // 영업이익 추이
    anlCustPerfStore, // 거래처 매출분석

    // --- system(시스템관리) ---
    sysUserMgmtStore, // 사용자관리
    sysPgmMgmtStore, // 프로그램관리
    sysAuthMgmtStore, // 권한관리
    sysCodeMgmtStore, // 코드관리
    sysAccessHistStore // 접근이력 조회
  },

  // vuex 주요 상태 저장, 새로고침 방지
  plugins: [
    createPersistedState({
      paths: ['loginAuthStore']
    })
  ]
})

export default store
