import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 법인 인원 현황 관리 리스트 조회 (직원현황)
  getEmployeeMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inf/employees${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 인원 현황 관리 추가 (직원현황 | 주재원))
  createEmployeeMgmt(payload) {
    // console.log('infEmployeeService.createEmployeeMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inf/employees', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 인원 현황 관리 수정 (직원현황 | 주재원))
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateEmployeeMgmt(id, payload) {
    // console.log('infEmployeeService.updateEmployeeMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/inf/employees/${id}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 인원 현황 관리 삭제 (직원현황 | 주재원)
  deleteEmployeeMgmt(id) {
    // console.log('infEmployeeService.deleteEmployeeMgmt() > boEmpId :', id)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/inf/employees/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 인원 현황 관리 리스트 조회 (주재원)
  getResidentMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inf/employees/residents${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 인원현황관리 업로드
  uploadExcel(files, sheetNum) {
    return commonUtils.$upload(`/inf/employees/xls/${sheetNum}`, files[0])
  },

  // 인원현황관리 저장
  doSaveEmpExcel(IUDObj) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inf/employees/xls-saved', IUDObj)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
