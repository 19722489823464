import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 양식정보 조회 (재무)
  getFinFrmInfoList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/fin-frm-info${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 계정관리 조회
  getAccMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/acc-mgmt${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 양식정보 추가(재무)
  addFinFrmIInfo(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/fin-frm-info', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 양식정보 수정
  updateFinFrmIInfo(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put('/bse/fin-frm-info', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 양식정보상세 삭제
  deleteFinFrmInfoDet(frmId, rowSeq) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/bse/fin-frm-info-det/${frmId}/${rowSeq}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 재무양식관리 상세 조회 (손익 항목 상세)
  getFinFrmInfoDetList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/fin-frm-info-det${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 양식상세리스트 저장
  saveFinFrmInfoDet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/fin-frm-info-det', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 엑셀업로드
  uploadExcel(files, sheetNum, includeFrmYn) {
    return commonUtils.$upload(
      `/bse/fin-frm-info-det/xls/${includeFrmYn}/${sheetNum}`,
      files[0]
    )
  }
}
