import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 매출손익실적 헤더정보조회
  getIncRet(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/inc-ret${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 매출손익실적 헤더저장
  saveIncRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/inc-ret', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 매출실적 정보조회
  getIncSalesRetList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/sales-rets${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadExcel(files, boId, incYymm, defCl, sheetNum) {
    return commonUtils.$upload(
      `inc/sales-rets/xls/${sheetNum}/${boId}/${incYymm}/${defCl}`,
      files[0]
    )
  },

  // 매출실적 저장
  saveIncSalRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/sales-rets', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 매출실적 마감
  procIncSalCls(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/sales-rets/cls', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 매출실적 삭제
  deleteIncSalRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(
          `/inc/sales-rets/${payload.boId}/${payload.incYymm}/${payload.defCl}/${payload.seq}`,
          payload
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
