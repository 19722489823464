// import bseBoCustMgmtService from '@/api/services/base/bse-bo-cust-mgmt-service'
// import commonService from '@/api/services/cmm/common-service'

// const FETCH_BO_CUST_LIST = 'FETCH_BO_CUST_LIST' // 거래처
// const FETCH_BO_CUST = 'FETCH_BO_CUST'
// const FETCH_BO_LIST = 'FETCH_BO_LIST' // 법인리스트
// const SELECT_BO = 'SELECT_BO' // 선택된 법인
// const SUB_BO_LIST = 'SUB_BO_LIST' // 지점리스트
// const SELECT_SUB_BO = 'SELECT_SUB_BO' // 선택된 지점 리스트

const bseCodeMapMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // // 거래처
    // boCusts: [],
    // boCust: {},
    // // 법인리스트
    // bos: [],
    // // 선택된 법인
    // bo: {
    //   boId: '' // 1000001
    //   // boNm: '상해',
    //   // crncyCd: 'CNY',
    //   // cntryCd: 'CN',
    //   // boNmEng:
    //   //   'Shanghai Hyundai Asia Shipping International Freight Forwarding Co.Ltd.'
    // },
    // // 지점리스트
    // subBos: [],
    // // 선택된 지점
    // subBo: {
    //   boId: '' // 1000021
    //   // boNm: '낙척(북경)',
    //   // crncyCd: 'CNY',
    //   // cntryCd: 'CN',
    //   // boNmEng: '낙천(북경)물류유한공사'
    // }
  },

  getters: {
    // -
  },

  mutations: {
    // [FETCH_BO_CUST_LIST](state, boCusts) {
    //   state.boCusts = boCusts
    // },
    // [FETCH_BO_CUST](state, boCust) {
    //   state.boCust = boCust
    // },
    // // 법인리스트
    // [FETCH_BO_LIST](state, payload) {
    //   payload.shift()
    //   state.bos = payload
    // },
    // // 선택된 법인
    // [SELECT_BO](state, bo) {
    //   state.bo = bo
    // },
    // // 지점리스트
    // [SUB_BO_LIST](state, subBos) {
    //   state.subBos = subBos
    // },
    // // 선택된지점
    // [SELECT_SUB_BO](state, subBo) {
    //   state.subBo = subBo
    // }
  },

  actions: {
    // fetchBoCustList({ commit }, queryStr = '') {
    //   console.log(
    //     'bseBoCustMgmtStore > fetchBoCustList > qureystr ==>',
    //     queryStr
    //   )
    //   return new Promise((resolve, reject) => {
    //     bseBoCustMgmtService
    //       .getBoCustMgmtList(queryStr)
    //       .then((res) => {
    //         commit(FETCH_BO_CUST_LIST, res.data)
    //         resolve(res)
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    // fetchBoCust({ commit }, selectBoCust) {
    //   // 특정 row를 선택(list에서 특정 건이 선택될 때)
    //   commit(FETCH_BO_CUST, selectBoCust)
    // },
    // clearBoCust({ commit }) {
    //   commit(FETCH_BO_CUST, [])
    // },
    // // 법인리스트를 가지고 온다.
    // fetchBoList({ commit, state }, queryStr = '') {
    //   return new Promise((resolve, reject) => {
    //     commonService
    //       .getBoList()
    //       .then((res) => {
    //         commit(FETCH_BO_LIST, res.data)
    //         // 디폴트로 첫번째 법인 선택
    //         // payload.shift()로 전체 조회할 수 있는 컬럼 (---) 지웠기 때문에
    //         // 0번째 법인이 첫번째 법인이다.
    //         commit(SELECT_BO, JSON.parse(JSON.stringify(state.bos[0])))
    //         commit(
    //           SUB_BO_LIST,
    //           JSON.parse(JSON.stringify(state.bos[0].subBos)) || [{ boId: '' }]
    //         )
    //         commit(
    //           SELECT_SUB_BO,
    //           JSON.parse(JSON.stringify(state.subBos[0])) || { boId: '' }
    //         )
    //         resolve(res)
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    // // 법인선택시
    // // @param selBoId : 선택된 법인 ID
    // selectBo({ commit, state }, selBoId) {
    //   // 특정 법인을 선택했을 경우 해당 법인의 지점 선택
    //   let selbo = { boId: '', boNm: '---', subBos: [{ boId: '', boNm: '---' }] }
    //   if (selBoId) {
    //     selbo = JSON.parse(
    //       JSON.stringify(state.bos.find((bo) => bo.boId === selBoId))
    //     )
    //   }
    //   commit(SELECT_BO, selbo)
    //   commit(SUB_BO_LIST, selbo.subBos || [{ boId: '' }])
    //   commit(
    //     SELECT_SUB_BO,
    //     JSON.parse(JSON.stringify(selbo.subBos[0] || { boId: '' }))
    //   )
    // },
    // // 지점 선택시
    // // @param selSubBoId : 선택된 지점ID
    // selectSubBo({ commit, state }, selSubBoId) {
    //   // 특정 지점을 선택했을 경우
    //   let selSubBo = { boId: '' }
    //   if (selSubBoId) {
    //     selSubBo = JSON.parse(
    //       JSON.stringify(
    //         state.subBos.find((subBo) => subBo.boId === selSubBoId)
    //       )
    //     )
    //   }
    //   commit(SELECT_SUB_BO, selSubBo || { boId: '' })
    // }
  }
}

export default bseCodeMapMgmtStore
