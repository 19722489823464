import bseExchgRateInqService from '@/api/services/base/bse-exchg-rate-inq-service'
// import commonService from '@/api/services/cmm/common-service'

const FETCH_EXCHG_RATE_LIST = 'FETCH_EXCHG_RATE_LIST' // 환율 정보
const FETCH_EXCHG_RATE = 'FETCH_EXCHG_RATE'
const FETCH_PLN_EXCHG_RATE_LIST = 'FETCH_PLN_EXCHG_RATE_LIST'
const FETCH_PLN_EXCHG_RATE = 'FETCH_PLN_EXCHG_RATE'

const bseExchgRateInqStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 환율 정보
    exchgRates: [],
    exchgRate: {
      // 조회인 경우 : BseExchgRateInqResponse
      // crncyCd:"AED"
      // curExchgRate:325.63
      // etd:"20220228"
      // exchgRateDet:"아랍에미리트공화국 AED"
      // rcpDt:1647874800000
      // std:"20220103"
      // yymm:"202203"
      // 업로드인 경우 : BseExchgRateInqExcelResponse
      // boughtCash:"1216.98"
      // boughtTc:"1210.41"
      // conversionRateUs:"1"
      // curExchgRate:"1196.06"
      // errorColumns:null
      // errorMsg:null
      // exchangeRate:"2.04497"
      // exchgRateDet:"미국 USD"
      // receiveMoney:"1184.38"
      // sellingCash:"1175.13"
      // sellingForeignCurrency:"1183.75"
      // sendMoney:"1207.73"
      // yymm:"202203"
    },
    plnExchgRates: [],
    plnExchgRate: {}
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_EXCHG_RATE_LIST](state, exchgRates) {
      state.exchgRates = exchgRates
    },
    [FETCH_EXCHG_RATE](state, exchgRate) {
      state.exchgRate = exchgRate
    },
    [FETCH_PLN_EXCHG_RATE_LIST](state, plnExchgRates) {
      state.plnExchgRates = plnExchgRates
    },
    [FETCH_PLN_EXCHG_RATE](state, plnExchgRate) {
      state.plnExchgRate = plnExchgRate
    }
  },

  actions: {
    fetchExchgRateInqList({ commit }, queryStr = '') {
      console.log(
        'bseExchgRateInqStore > fetchExchgRateInqList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseExchgRateInqService
          .getExchgRateInqList(queryStr)
          .then((res) => {
            commit(FETCH_EXCHG_RATE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchPlnExchgRateInqList({ commit }, queryStr = '') {
      console.log(
        'bseExchgRateInqStore > fetchPlnExchgRateInqList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseExchgRateInqService
          .getPlnExchgRateInqList(queryStr)
          .then((res) => {
            commit(FETCH_PLN_EXCHG_RATE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchExchgRateInq({ commit }, exchgRate) {
      // 엑셀 업로드 시 0번째 행
      commit(FETCH_EXCHG_RATE, exchgRate)
    },
    fetchPlnExchgRateInq({ commit }, plnExchgRate) {
      commit(FETCH_PLN_EXCHG_RATE, plnExchgRate)
    },
    clearExchgRateInq({ commit }) {
      commit(FETCH_EXCHG_RATE_LIST, [])
      commit(FETCH_PLN_EXCHG_RATE_LIST, [])
    },

    uploadExcel({ commit, state }, { files, yymm, type }) {
      return new Promise((resolve, reject) => {
        bseExchgRateInqService
          .uploadExcel(files, yymm)
          .then((res) => {
            if (type === 0) {
              // 헤더 제외한 데이터
              commit(FETCH_EXCHG_RATE_LIST, res.splice(2))
              // 기준일 : 2022-01-03 ~ 2022-02-28 고시회차 : 최초 [조회시각 : 2022-03-22 11:09:54]
              commit(FETCH_EXCHG_RATE, res[0])
            } else {
              // 헤더 제외한 데이터
              commit(FETCH_PLN_EXCHG_RATE_LIST, res.splice(2))
              // 기준일 : 2022-01-03 ~ 2022-02-28 고시회차 : 최초 [조회시각 : 2022-03-22 11:09:54]
              commit(FETCH_PLN_EXCHG_RATE, res[0])
            }

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteExchgRateInq({ commit }) {
      commit(FETCH_EXCHG_RATE_LIST, [])
    }
  }
}

export default bseExchgRateInqStore
