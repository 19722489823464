import VueCookies from 'vue-cookies'
import store from '@/store'
// import router from '@/router'

export function setInterceptors(axiosApi) {
  // ====================================================================
  //  api 공통 설정
  // ====================================================================
  axiosApi.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
  axiosApi.defaults.headers['Access-Control-Allow-Origin'] = '*'

  // Refresh Token관련 재시도 flag
  let bRefreshTokenCall = false

  // ====================================================================
  //  http 요청을 서버로 보내기 바로 전 처리
  // ====================================================================
  axiosApi.interceptors.request.use(
    async function (config) {
      // Do something before request is sent

      // >>> req 요청 시 header에 AT, accept-language 설정
      const accessToken = VueCookies.get('accessToken')
      const userLang = VueCookies.get('userLang')
      console.log(
        '>>>>>>> axiosApi.Interceptors > request > AT, lang => ',
        accessToken,
        userLang
      )
      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken
        config.headers['Accept-Language'] = userLang
      }
      if (bRefreshTokenCall) {
        config.headers.refreshToken = VueCookies.get('refreshToken')
      }
      // --- 로그 처리 start ---
      console.log('> req.config => ', config)
      console.log('======= axiosApi.interceptor.request log start =======')
      console.log('> req.url     => ', config.url)
      console.log('> req.method  => ', config.method)
      console.log(
        '> config.headers.Authorization  => ',
        config.headers.Authorization
      )
      console.log(
        "> config.headers['Accept-Language']  => ",
        config.headers['Accept-Language']
      )
      if (config.method === 'post' || config.method === 'put') {
        console.log('> req.data(when post or put) => ', config.data)
      }
      console.log('======= axiosApi.interceptor.request log end =======')
      // --- 로그 처리 end ---

      return config
    },

    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  /**
   * accessToken이 만료가 되어서 실행이 중단된 요청에 대해서,
   * accessToken을 재발행 받고 중단된 요청을 다시 하는 함수
   * @param {*} config
   * @returns
   */
  const retry = (config) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log(
          '====>>>> axiosApi.Interceptors > request retry() executed...'
        )
        axiosApi
          .request(config)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      }, 500)
    })
  }

  // ====================================================================
  //  http 응답을 서비스로 리턴하기 전 처리
  // ====================================================================
  axiosApi.interceptors.response.use(
    async function (response) {
      // ====== 서버와 통신이 정상인 경우 ======
      // === 요청에 대한 응답을 컴포넌트 단에 넘겨주기 전 처리를 수행 ===
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      // response.data에 존재하는 서버에서 보낸 code, message, success, data를 추출한다.
      // delete의 경우
      console.log('=== axiosApi.interceptors.response -> ', response)

      // === 파일이나 엑셀 다운로드일 경우 일반 처리와 다르게 그냥 bypass 처리
      // 토큰 만료일 경우에도 중간 처리는 json형태이고 최종 결과는 content-type이 json이 아님
      if (
        response.headers['content-type'] &&
        response.headers['content-type'].substr(0, 16) !== 'application/json'
      ) {
        // bypass
        return response
      }

      if (response.data) {
        // // response 객체에 svr 프리픽스로 상태 정보를 설정하고 data는 svrData로 overwrite
        response.svrCode = response.data.code
        response.svrMessage = response.data.message
        response.data = response.data.result
      } else {
        console.log('=== axiosApi.interceptors.response.data null')
        response.svrCode = ''
        response.svrMessage = ''
        response.data = null
      }

      console.log('======= axiosApi.interceptor.response log start =======')
      console.log('> res.reqUrl      => ', response.config.url)
      console.log('> res.status      => ', response.status)
      console.log('> res.svrCode     => ', response.svrCode)
      console.log('> res.svrMessage  => ', response.svrMessage)
      console.log('> res.data        => ', response.data)
      if (response.data?.rows) {
        console.log(
          '> res.data.rows.length => ',
          response.data.rows ? response.data.rows.length : 0
        )
      }
      console.log(
        '> res.accessToken refresh => ',
        response.data?.refreshToken ? 'Y' : 'N'
      )
      console.log('======= axiosApi.interceptor.response log end =======')

      if (response.status === 200 && response.svrCode === 705) {
        console.log('===>>> LOGOUT SUCCESS!!! <<<===')
      }

      // ------------------- rt frmk 로직 ------
      // accessToken이 만료가 되면 토큰 재발행 요청
      // refreshToken이 만료가 되면 로그아웃 처리
      // status가 200이고, 응답받은 데이터의 code가 401이면 accessToken이 만료가 되었음을 의미하고,
      // 토큰 재발행을 요청
      if (response.status === 200 && response.svrCode === 401) {
        // AT만료 결과 response.svrCode 저장 -> 아래의 retry 처리를 위해 필요
        // accessToken 이 만료된 경우 AT 재발행
        bRefreshTokenCall = true
        const res = await store.dispatch('loginAuthStore/fetchRefreshToken')
        console.log(
          'axiosApi.Interceptor > response > refresh-token > res : ',
          res
        )

        bRefreshTokenCall = false
        const accessToken = await store.getters['loginAuthStore/getAccessToken']

        response.config.headers.Authorization =
          // 'Bearer ' + VueCookies.get('accessToken')
          'Bearer ' + accessToken
        console.log(
          'axiosApi.Interceptor > response > refresh-token > AT : ',
          accessToken
        )
        console.log(
          '======>>> axiosApi.Interceptor > retry!!! > response.config : ',
          response.config
        )
        // 바로 이전 request 재요청
        return retry(response.config)
      } else {
        return response
      }
    },

    async function (error) {
      // ====== 서버와 통신에 오류가 발생한 경우 ======
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // response 에러 발생 시 서비스 단으로 보내기 전 error 처리
      error.httpStatus = error.response.status
      if (error.response.data) {
        // // response 객체에 svr 프리픽스로 상태 정보를 설정하고 data는 svrData로 overwrite
        error.svrCode = error.response.data.code
        error.svrMessage = error.response.data.message
        error.data = error.response.data.result
      } else {
        error.svrCode = ''
        error.svrMessage = ''
        error.data = null
      }
      console.log('======= axiosApi.interceptor.error log start =======')
      console.log('$$$ error.httpStatus =>', error.httpStatus)
      console.log('$$$ error.svrCode =>', error.svrCode)
      console.log('$$$ error.svrMessage =>', error.svrMessage)
      console.log('$$$ error.data -> ', error.data)
      console.log('======= axiosApi.interceptor.error log end =======')

      // 에러 메시지 alert
      let errorMsg = ''
      if (error.httpStatus === 401) {
        // HTTPResponse.SC_UNAUTHORIZED(401)
        if (error.svrCode === 703) {
          // 서버 오류코드가 패스워드 오류인 경우
          errorMsg =
            error.svrMessage + '\n로그인 실패 횟수 : ' + error.data.failCnt
          alert(errorMsg)
        } else if (error.svrCode === 402 || error.svrCode === 403) {
          // 비인가된 접근
          alert(error.svrMessage)
          await store.dispatch('loginAuthStore/logout')
          location.href = '/login'
        } else {
          errorMsg = error.svrMessage
          alert(errorMsg)
        }
        // alert(errorMsg)
        // 로컬 logout action 호출하면 서비스의 then으로 내려가고 res도 undefined로
        // 내려가므로 여기서 처리하지 말고 종단인 LoginPage에서 처리 필요
      } else {
        console.log('axiosApi.intercptor > error > 401 이외')
      }

      return Promise.reject(error)
    }
  )

  return axiosApi
}
