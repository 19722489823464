// import SysUserMgmtPage from '@/views/system/SysUserMgmtPage.vue';

export default [
  {
    // === 사용자관리 Page ===
    path: '/sys-user-mgmt',
    name: 'SysUserMgmtPage',
    // component: SysUserMgmtPage,
    component: () =>
      import(
        /* webpackChunkName: "System" */ '@/views/system/SysUserMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // === 코드관리 Page ===
    path: '/sys-code-mgmt',
    name: 'SysCodeMgmtPage',
    // component: SysCodeMgmtPage,
    component: () =>
      import(
        /* webpackChunkName: "System" */ '@/views/system/SysCodeMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // === 프로그램관리 Page ===
    path: '/sys-pgm-mgmt',
    name: 'SysPgmMgmtPage',
    // component: SysProgMgmtPage,
    component: () =>
      import(
        /* webpackChunkName: "System" */ '@/views/system/SysPgmMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // === 권한관리 Page ===
    path: '/sys-auth-mgmt',
    name: 'SysAuthMgmtPage',
    // component: SysProgMgmtPage,
    component: () =>
      import(
        /* webpackChunkName: "System" */ '@/views/system/SysAuthMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  {
    // === 접근이력 조회 Page ===
    path: '/sys-access-hist',
    name: 'SysAccessHistPage',
    // component: SysProgMgmtPage,
    component: () =>
      import(
        /* webpackChunkName: "System" */ '@/views/system/SysAccessHistPage.vue'
      ),
    meta: { auth: true }
  }
]
