import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 미수채권 증감 조회
  getArIncreAnalysisList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/ar-incre-analysis${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 미수채권 증감 업로드
  uploadExcel(files, arYymm, sheetNum) {
    return commonUtils.$upload(
      `fin/ar-incre-analysis/xls/${arYymm}/${sheetNum}`,
      files[0]
    )
  },

  // 미수채권 증감 삭제
  deleteIncreAnalysis(yymm, id, seq) {
    // console.log('finArIncreService > deleteIncreAnalysis() > incItmId :', id)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/fin/ar-incre-analysis/${yymm}/${id}/${seq}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 미수채권 증감 저장
  doSaveArExcel(IUDObj) {
    // console.log('finArIncreService > createArExcel() > payload', IUDObj)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/fin/ar-incre-analysis', IUDObj)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
