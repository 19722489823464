import commonService from '@/api/services/cmm/common-service'

const EXCHG_RATE_YM = 'EXCHG_RATE_YM'

const commonStore = {
  namespaced: true,

  // === state ===
  state: {
    exchgRateYm: '' // 202106
  },

  getters: {
    // -
  },

  mutations: {
    // 최종환율 년월
    [EXCHG_RATE_YM](state, exchgRateYm) {
      state.exchgRateYm = exchgRateYm
    }
  },

  actions: {
    // 손익실적용 최종 환율 년월 날짜
    fetchExchgRateYm({ commit }, yyyy) {
      return new Promise((resolve, reject) => {
        commonService
          .getExchgRateYm(yyyy)
          .then((res) => {
            commit(EXCHG_RATE_YM, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

export default commonStore
