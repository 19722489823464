import incSalesPlanService from '@/api/services/income/inc-sales-plan-service'

// === for gred sample
const FETCH_INC_PLAN = 'FETCH_INC_PLAN'
const FETCH_SAL_PLAN_LIST = 'FETCH_SAL_PLAN_LIST'
// const FETCH_COMMENT_LIST = 'FETCH_COMMENT_LIST'

const incSalesPlanStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    incSalPlans: [],

    incPlan: {
      // boId: 999,
      // boIncYy: '2022',
      // incMon: '01',
      finlVer: ''
      // plnCls1: 'Y',
      // crncyCd: '',
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_INC_PLAN](state, incPlan) {
      state.incPlan = incPlan
    },
    [FETCH_SAL_PLAN_LIST](state, incSalPlans) {
      state.incSalPlans = incSalPlans || []
    }
  },
  actions: {
    clearIncSalPlans({ commit }) {
      commit(FETCH_SAL_PLAN_LIST, [])
    },
    // === for Grid sample
    fetchIncPlan({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incSalesPlanService
          .getIncPlan(queryStr)
          .then((res) => {
            commit(FETCH_INC_PLAN, res.data || { finlVer: 0 })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncSalPlanList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incSalesPlanService
          .getIncSalesPlan(queryStr)
          .then((res) => {
            commit(FETCH_SAL_PLAN_LIST, res.data.incSalPlans)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    uploadExcel({ commit }, { files, boId, incYy }) {
      return new Promise((resolve, reject) => {
        incSalesPlanService
          .uploadExcel(files, boId, incYy)
          .then((res) => {
            commit(FETCH_SAL_PLAN_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteIncSalPlan({ commit, state }, selIdx) {
      state.incSalPlans.splice(selIdx, 1)
      commit(FETCH_SAL_PLAN_LIST, state.incSalPlans)
    }
  }
}

export default incSalesPlanStore
