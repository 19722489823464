import VueCookies from 'vue-cookies'
import { axiosApi } from '@/api'
// import axios from 'axios'
import loginAuthService from '@/api/common/login-auth-service'

// === define mutation-type constant
const SET_TOKEN_INFO = 'SET_TOKEN_INFO'
const SET_MY_INFO = 'SET_MY_INFO'
const REMOVE_TOKEN_INFO = 'REMOVE_TOKEN_INFO'
const REMOVE_MY_INFO = 'REMOVE_MY_INFO'
const INIT_STORE = 'INIT_STORE'
const BOINC000_SUB_GROUP = ['손익계획', '손익실적', '손익조회']

const loginAuthStore = {
  namespaced: true,

  // === state ===
  // 현재 로그인한 사용자정보
  state: {
    accessToken: '',

    me: {
      // userId: 'user1',
      // userNm: '홍길동',
      // userNmEng: '홍길동',
      // compId: 100,
      // boId: 1000036, // 본사
      // cntryCd: 'KR',
      // userTyp: 'S',
      // authCd: 'LGL-001', // 권한코드
      // useLang: 'ko_KR',
      // pwdChgDt: '2022-04-11 13:23:33',
      // pwdChgMustYn: 'N'
      // hqYn: 'N'  // 본사여부
    },

    menus: [
      // {
      //   MenuCd: 'BOINC000',
      //   menuNm: '손익관리',
      //   menuNmEng: '손익관리',
      //   menuSeqCd: 'BOMNU001',
      //   authCd: 'LGL-002',
      //   subMenus: [
      //     {
      //       grpNm: '손익계획',
      //       subMenus2: [
      //         {
      //           MenuCd: 'BOINC001',
      //           menuNm: '매출등록(계획)',
      //           menuNmEng: '매출등록(계획)',
      //           pgmWebPage: 'IncSalesPlanPage',
      //           viewSeq: 111,
      //           authCd: 'LGL-002',
      //           topMenuCd: 'BOINC000',
      //           grpNm: '손익계획'
      //         },
      //         {
      //           MenuCd: 'BOINC002',
      //           menuNm: '매출등록2(계획)',
      //           menuNmEng: '매출등록2(계획)',
      //           pgmWebPage: 'IncSalesPlanPage2',
      //           viewSeq: 112,
      //           authCd: 'LGL-002',
      //           topMenuCd: 'BOINC000',
      //           grpNm: '손익계획'
      //         }
      //       ]
      //     }
      //   ]
      // }
    ], // 로그인자의 사용가능한 메뉴 목록

    auths: [] // 로그인자의 메뉴별 CRUD 권한 목록
  },

  getters: {
    // 로그인 여부
    isLogin(state) {
      // 인증 여부(토큰+내정보) 리턴
      return state.accessToken.length > 0 && !!state.me.userId
    },

    isBtnAuth: (state) => (webPageId, authName) => {
      // authName : view/add/sve/del/dwl/upl
      // 서버에서 내려준 항목명을 그대로 사용하여 해당 action에 대한 권한여부를 판단
      // test
      // const auths = [
      //   {
      //     MenuCd: 'BOINC001',
      //     menuNm: '매출등록(계획)',
      //     menuNmEng: '매출등록(계획)',
      //     pgmWebPage: 'IncSalesPlanPage',
      //     viewSeq: 0,
      //     viewAuth: 'Y',
      //     addAuth: 'Y',
      //     chgAuth: 'Y',
      //     delAuth: 'N',
      //     dwlAuth: 'N',
      //     uplAuth: 'N',
      //     authCd: 'LGL-002',
      //     topMenuCd: 'BOINC000'
      //   }
      // ]

      // ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
      // 권한정보가 없으면 기본적으로 모두 사용 가능하도록, test를 위해서도 필요
      // 나중에 협의하여 기본 처리가 정해지면 주석이나 삭제 처리 필요
      // console.log('************ state.auths.length : ', state.auths.length);
      // if (state.auths.length === 0) {
      //   return true
      // }
      // ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
      // cf) MainPage를 기존 메뉴의 페이지를 공유하는 경우 권한 체크 해제 필요
      //     이유는 해당 페이지의 권한 정보가 없는 경우 아래 로직에서 에러가 나서
      //     로그인이 안되는 현상 발생
      const auth = state.auths.find((auth) => auth.pgmWebPage === webPageId)
      // 메뉴권한이 없으면 false 리턴
      if (!auth) return false
      // eslint-disable-next-line no-unneeded-ternary
      const isAuth = auth[authName + 'Auth'] === 'Y' ? true : false
      // console.log(
      //   '====== >>> Button Auth info : auth, webPageId, authName, isAuth > ',
      //   auth, webPageId, authName, isAuth
      // )

      return isAuth
    },

    getTokenInfo(state) {
      return {
        accessToken: state.accessToken,
        refreshToken: VueCookies.get('refreshToken')
      }
    },

    getMyInfo(state) {
      return state.me
    },

    getAccessToken(state) {
      return state.accessToken
    }
  },

  mutations: {
    [SET_TOKEN_INFO](state, tokenInfo) {
      // --- 서버로 부터 발급 받은 Token정보를 저장 ---
      // accessToken(AT), refreshToken(RT), 로그인 사용자 언어를 쿠키에 저장
      if (tokenInfo.accessToken) {
        state.accessToken = tokenInfo.accessToken
        // request header에 Authorization 토큰 설정
        axiosApi.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`

        // 쿠키 만료기간 설정(설정 시점 기준 accessToken:30분, refreshToken:30분)
        // 서버에서는 AT를 10분, RT를 30분으로 처리하기 때문에 AT가 만료되면 다시 발행
        const atExpTime =
          (process.env.VUE_APP_COOKIE_EXPIRE_TIME_RT || '30') + 'min'
        const rtExpTime =
          (process.env.VUE_APP_COOKIE_EXPIRE_TIME_RT || '30') + 'min'
        // console.log(
        //   '=== loginAuthStore > SET_TOKEN_INFO > atExpTime, rtExpTime : ',
        //   atExpTime,
        //   rtExpTime
        // )
        VueCookies.set('accessToken', tokenInfo.accessToken, atExpTime)
        VueCookies.set('refreshToken', tokenInfo.refreshToken, rtExpTime)

        // --- 로그인 사용자의 언어정보를 서버 요청 헤더에 설정 ---
        // 로그인 사용자의 사용언어를 request Header에 설정하여 서버 요청시 사용하면
        // 서버의 언어 설정도 로그인 사용자의 사용언어로 자동 변경
        //   없으면 frontend의 기본 설정을 사용(서버에서는 ko_KR의 앞 2자리만 사용)
        // 로그인시, AT만료 RT유효시 토큰 재발생 처리에서도 호출되므로 로그인 action에서 여기로 이동함
        // 토큰 재발행 시 state는 존재하므로 아래의 me.useLang 유효
        // 로그인 사용자의 사용언어로 UI화면의 언어를 설정하는 부분은 HeaderBar.vue의 created()에서 처리
        // 쿠키에 저장된 userLang 정보는 요청시 AT와 함께 interceptor에서 사용됨
        const userLang = (
          state.me.useLang || process.env.VUE_APP_I18N_LOCALE
        ).substr(0, 2)

        axiosApi.defaults.headers.common['Accept-Language'] = userLang

        VueCookies.set('userLang', userLang, rtExpTime)
      }
    },
    // 로그인한 사용자 정보 저장
    [SET_MY_INFO](state, payload) {
      state.me = payload.userInfo
      state.menus = payload.menus
      state.auths = payload.auths
    },

    // 쿠키에 저장된 accessToken, refreshToken을 삭제하고 스토어의 user 정보를 삭제하는 함수
    [REMOVE_TOKEN_INFO](state) {
      state.accessToken = ''
      // request header의 Authorization 토큰 삭제
      delete axiosApi.defaults.headers.common.Authorization
      VueCookies.remove('accessToken')
      VueCookies.remove('refreshToken')
      VueCookies.remove('userLang')
    },

    [REMOVE_MY_INFO](state) {
      // 원칙적으로는 null 처리를 해야 하나 자동로그인과 관련하여
      // 로그 아웃하더라도 me.userId 정보항목은 존재해야 하므로 아래와
      // 같이 초기화 처리함.
      // state.me = { userId: '' }
      state.me = {}
      state.menus = []
      state.auths = []
    },
    [INIT_STORE](state) {
      state.accessToken = ''
      state.me = {}
      state.menus = []
      state.auths = []
      delete axiosApi.defaults.headers.common.Authorization
      VueCookies.remove('accessToken')
      VueCookies.remove('refreshToken')
      VueCookies.remove('userLang')
    }
  },

  actions: {
    // 로그인
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginAuthService
          .userLogin(userInfo)
          .then((res) => {
            const loginInfo = res.data
            console.log(
              'loginAuthStore > actions > login > loginInfo.menus : ',
              loginInfo.menus
            )
            
            const tokenInfo = {
              accessToken: loginInfo.accessToken,
              refreshToken: loginInfo.refreshToken
            }
            
            const menusTemp = loginInfo.menus.map((menus) => {
              if (menus.menuCd === 'BOINC000') {
                const sMenus = []                

                BOINC000_SUB_GROUP.forEach((subMunus) => {
                  const subGrp = menus.subMenus.filter((item) => (item.grpNm === subMunus))
                  const subMenu = {
                    grpNm: subGrp[0].grpNm,
                    grpNmEng: subGrp[0].grpNmEng,
                    subMenus2: subGrp
                  }

                  sMenus.push(subMenu)
                })
                menus.subMenus = sMenus
              }
              return menus
            })         

            const payload = {
              userInfo: loginInfo.userInfo,
              menus: menusTemp,
              auths: loginInfo.auths
            }
            commit(SET_MY_INFO, payload)
            commit(SET_TOKEN_INFO, tokenInfo)

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 서버 로그아웃 호출
    async svrLogout({ commit }, loginInfo = {}) {
      // console.log('loginAuthStore > Server Logout() exec...')
      await loginAuthService.userLogout(loginInfo)

      // 로그아웃은 서버 상태와 상관없이 진행처리
      commit('REMOVE_TOKEN_INFO')
      commit('REMOVE_MY_INFO')
      location.reload()
    },

    // 로컬만 로그아웃 처리
    // 저장된 토큰정보를 모두 삭제하고 로그아웃
    // 서버에서 401(refresh token 만료)에러 발생 시 로컬만 로그아웃하고 로그인화면으로
    logout({ commit }) {
      commit('REMOVE_TOKEN_INFO')
      commit('REMOVE_MY_INFO')
      // location.href = '/login'
      location.reload()
    },

    // accessToken이 만료가 되면 다시 발급 받기 위한 함수
    fetchRefreshToken({ commit }) {
      console.log('loginAuthStore > fetchRefreshToken() exec...')

      // request header에 RT를 설정하는 것은 interceptor의 request에서 처리

      return new Promise((resolve, reject) => {
        loginAuthService
          .getRefreshToken()
          .then((res) => {
            // 내 정보에는 사용자정보 및 메뉴, 권한이 같이 포함되어 있으므로
            // res.data내에 userInfo, menu, auth가 존재
            console.log(
              'loginAuthStore > fetchRefreshToken > res.status : ',
              res.status
            )

            if (res.status === 200 && res.svrCode === 200) {
              // AT, RT 재발행 성공이면
              const loginInfo = res.data

              const tokenInfo = {
                accessToken: loginInfo.accessToken,
                refreshToken: loginInfo.refreshToken
              }

              commit(SET_TOKEN_INFO, tokenInfo)
            } else {
              // RT만료
              commit('REMOVE_TOKEN_INFO')
            }

            resolve(res)
          })
          .catch((err) => {
            console.log(
              '$$$ loginAuthStore > actions > fetchRefreshToken >catch err =>',
              err
            )
            reject(err)
          })
      })
    },

    // auth-store 초기화 처리
    initStore({ commit }) {
      commit(INIT_STORE)
    }
  }
}

export default loginAuthStore
