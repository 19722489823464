import { axiosApi } from '@/api'

export default {
  // 법인 마감 현황 리스트 조회
  getCloseList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/closes${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 마감 해제
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateBoClear(closeList) {
    // console.log('bseCloseMgmtService.updateBoClose() > payload', closeList)

    return new Promise((resolve, reject) => {
      axiosApi
        .put('/bse/clear', closeList)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 마감
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateCheckedCloser(IUDObj) {
    console.log('bseCloseMgmtService.updateCheckedCloser() > payload', IUDObj)

    return new Promise((resolve, reject) => {
      axiosApi
        .put('/bse/closure', IUDObj)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인리스트조회 (법인 + 지사)
  getBoList(locale) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/bse/closes/bos')
        .then((res) => {
          res.data.map((item) => {
            return item
          })
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
