<template>
  <nav class="aside">
    <div class="gnb">
      <el-menu
        default-active="0"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
      >
        <!-- HOME -->
        <el-sub-menu :index="0 + ''" class="home">
          <template #title>
            <i class="icon_menu"></i>
            <span>{{ $t('AsideMenu.content.itemHome') }}</span>
          </template>
        </el-sub-menu>

        <template v-for="(menu, mIdx) in menus" :key="menu.menuCd">
          <el-sub-menu :index="mIdx + 1 + ''">
            <template #title>
              <i :class="'icon_menu0' + (mIdx + 1)"></i>
              <span>{{
                $i18n.locale === 'ko_KR' ? menu.menuNm : menu.menuNmEng
              }}</span>
            </template>

            <template
              v-for="(subMenu, smIdx) in menu.subMenus"
              :key="subMenu.menuCd || smIdx"
            >
              <!-- grpNm이 있으면 한단계 더 내려가서 서브메뉴 표시
                   grpNm이 있는 메뉴그룹 아래의 서브 메뉴는 모두 grpNm이 있어야 함  -->
              <template v-if="subMenu.grpNm">
                <el-menu-item-group
                  :title="
                    $i18n.locale === 'ko_KR' ? subMenu.grpNm : subMenu.grpNmEng
                  "
                >
                  <template
                    v-for="(subMenu2, smIdx2) in subMenu.subMenus2"
                    :key="subMenu2.menuCd"
                  >
                    <el-menu-item
                      :index="mIdx + 1 + '-' + smIdx2 + 1"
                      @click="activate(subMenu2.menuCd, subMenu2.pgmWebPage)"
                      >{{
                        $i18n.locale === 'ko_KR'
                          ? subMenu2.menuNm
                          : subMenu2.menuNmEng
                      }}</el-menu-item
                    >
                  </template>
                </el-menu-item-group>
              </template>

              <!-- grpNm이 없으면 현 단계에서 서브메뉴 표시-->
              <template v-else>
                <el-menu-item
                  :index="mIdx + 1 + '-' + smIdx + 1"
                  @click="activate(subMenu.menuCd, subMenu.pgmWebPage)"
                  >{{
                    $i18n.locale === 'ko_KR'
                      ? subMenu.menuNm
                      : subMenu.menuNmEng
                  }}</el-menu-item
                >
              </template>
            </template>
          </el-sub-menu>
        </template>
      </el-menu>

      <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
        <el-radio-button :label="false">expand</el-radio-button>
        <el-radio-button :label="true">collapse</el-radio-button>
      </el-radio-group>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

const loginAuthStore = 'loginAuthStore'

export default {
  name: 'AsideMenu',

  data() {
    return {
      isCollapse: false,
      // activeIndex: '9',
      active_el: 0
      // menus: [
      //   {
      //     MenuCd: 'BOINC000',
      //     menuNm: '손익관리',
      //     menuNmEng: '손익관리',
      //     menuSeqCd: 'BOMNU001',
      //     authCd: 'LGL-002',
      //     subMenus: [
      //       {
      //         grpNm: '1.손익계획',
      //         subMenus2: [
      //           {
      //             MenuCd: 'BOINC001',
      //             menuNm: '매출등록(계획)',
      //             menuNmEng: '매출등록(계획)',
      //             pgmWebPage: 'IncSalesPlanPage',
      //             viewSeq: 111,
      //             authCd: 'LGL-002',
      //             topMenuCd: 'BOINC000',
      //             grpNm: '1.손익계획'
      //           },
      //           {
      //             MenuCd: 'BOINC002',
      //             menuNm: '매출등록2(계획)',
      //             menuNmEng: '매출등록2(계획)',
      //             pgmWebPage: 'IncSalesPlanPage2',
      //             viewSeq: 112,
      //             authCd: 'LGL-002',
      //             topMenuCd: 'BOINC000',
      //             grpNm: '1.손익계획'
      //           }
      //         ]
      //       },
      //     ]
      //   },
      //   {
      //     MenuCd: 'BOINC000',
      //     menuNm: '인프라관리',
      //     menuNmEng: '인프라관리',
      //     menuSeqCd: 'BOMNU002',
      //     authCd: 'LGL-002',
      //     subMenus: [
      //       {
      //         MenuCd: 'BOINF001',
      //         menuNm: '설비등록',
      //         menuNmEng: '설비등록',
      //         pgmWebPage: 'IncSalesPlanPage7',
      //         viewSeq: 121,
      //         authCd: 'LGL-002',
      //         topMenuCd: 'BOINF000',
      //         grpNm: ''
      //       },
      //       {
      //         MenuCd: 'BOINF001',
      //         menuNm: '장비등록',
      //         menuNmEng: '장비등록',
      //         pgmWebPage: 'IncSalesPlanPage8',
      //         viewSeq: 120,
      //         authCd: 'LGL-002',
      //         topMenuCd: 'BOINF000',
      //         grpNm: ''
      //       }
      //     ]
      //   }
      // ] // 로그인자의 사용가능한 메뉴 목록
    }
  },
  computed: {
    // 스토어의 상태를 로컬 컴포넌트에서 사용할 수 있도록 가져옴
    ...mapState(loginAuthStore, ['menus'])
  },
  methods: {
    activate(el, pgmWebPage) {
      this.active_el = el
      console.log(
        'active_el, el, programWebPath ',
        this.active_el,
        el,
        pgmWebPage
      )

      if (el === 0) {
        this.$router.push('/home')
      } else {
        this.$router.push({ name: pgmWebPage })
      }
    },

    handleOpen(key, keyPath) {
      // console.log('handleOpen(key, keyPath) ', key, keyPath)
      if (key === '0') {
        this.$router.push('/home')
      }
    },
    handleClose(key, keyPath) {
      if (key === '0') {
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style>
/*.aside {
  padding: 0;
  float: left;
  background: #555;
  width: 220px;
  position: relative;
}*/
.aside {
  /*position: fixed;
  left: 0;
  top: 40px;
  bottom: 0;
  z-index: 1;*/
  background: #555;
  width: 220px;
  height: 100%;
}
.gnb {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
}
.gnb_in {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
}

.gnb > .el-menu::-webkit-scrollbar {
  width: 6px;
}
.gnb > .el-menu::-webkit-scrollbar-thumb {
  background-color: #909090;
  border-radius: 3px;
}
.gnb > .el-menu::-webkit-scrollbar-track {
  background-color: #222;
  border-radius: 3px;
}
.el-menu-item-group .el-menu-item.is-active {
  background-color: #202020;
}
/* 1depth menu */
.gnb .el-sub-menu__title {
  height: 54px;
  padding-left: 15px !important;
}
.el-sub-menu__title:hover {
  background-color: #1c1911 !important;
  color: #f0f0f0;
}
.el-sub-menu.home {
  height: 54px !important;
}
.el-sub-menu.home .el-sub-menu__icon-arrow {
  display: none !important;
}
.gnb .el-sub-menu__title i:first-child {
  width: 26px;
  height: 26px;
  opacity: 0.96;
  position: absolute;
}

.gnb .el-sub-menu__title span {
  display: inline-block;
  line-height: 40px;
  margin-left: 35px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.gnb .el-sub-menu__title i.icon_menu {
  background: url('~@/assets/img/icon_nav_home.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu01 {
  background: url('~@/assets/img/icon_nav_profit.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu02 {
  background: url('~@/assets/img/icon_nav_profit.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu03 {
  background: url('~@/assets/img/icon_nav_finance.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu04 {
  background: url('~@/assets/img/icon_nav_infra.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu05 {
  background: url('~@/assets/img/icon_nav_analyze.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu06 {
  background: url('~@/assets/img/icon_nav_standard.svg') center center;
}
.gnb .el-sub-menu__title i.icon_menu07 {
  background: url('~@/assets/img/icon_nav_system.svg') center center;
}
/* 1depth menu open */
.gnb .el-sub-menu.is-opened {
  background-color: #333;
}
/* 1depth menu open 2depth */
.gnb .el-sub-menu.is-opened .el-menu.el-menu--inline {
  background-color: #3f3f3f;
  border-bottom: 1px solid #222;
}
.gnb .el-sub-menu.is-opened .el-menu.el-menu--inline li ul li:first-child {
  padding-top: 12px !important;
}
.gnb .el-sub-menu.is-opened .el-menu.el-menu--inline li ul li:last-child {
  padding-bottom: 14px !important;
}

.gnb .el-menu-item-group__title {
  display: flex;
  /* color: #eee; */
}
.gnb .el-menu-item-group2__title {
  display: none;
  /* color: #eee; */
}

.gnb .el-sub-menu .el-icon svg {
  color: #eee;
}
/** aside nav closing **/
.el-menu--collapse {
  width: 70px;
}
.el-menu.el-menu--collapse .el-sub-menu__title {
  height: 70px;
  padding-left: 14px !important;
}
.el-menu.el-menu--collapse .el-sub-menu__title i:first-child {
  width: 32px;
  height: 32px;
  opacity: 0.83;
}
.el-menu.el-menu--collapse .el-sub-menu__title i:first-child:hover {
  opacity: 1;
}
/** aside nav closing 2depth **/
.el-popper.is-light {
  border: 1px solid #333;
  box-shadow: 4px 4px 4px #00000024;
  padding: 0;
}
.el-menu-item-group .el-menu-item {
  letter-spacing: -0.5px;
  color: #eee;
}
.el-menu-item-group .el-menu-item:hover {
  background: #383838;
  color: #eee;
}
.el-menu-item-group .el-menu-item.is-active {
  background: #393939;
  color: #fff;
}

/* 2depth menu */
.el-sub-menu .el-menu-item {
  color: #e0e0e0;
  height: unset;
  line-height: 1.2;
  font-size: 14px;
  padding: 9px 0 !important;
  padding-left: 48px !important;
  letter-spacing: -0.5px;
  padding-right: 12px;
  white-space: inherit;
}
.el-sub-menu .el-menu-item:hover {
  color: #f5c225;
  color: #fff;
  text-shadow: 3px 3px 3px #000;
  background: #333 !important;
}

.el-menu--vertical .el-menu--popup {
  padding: 0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #454545;
}
/* toggle btn */
.el-menu + .el-radio-group {
  position: absolute;
  left: 220px;
  top: 50px;
  z-index: 99;
  display: flex;
  flex-direction: column;
}
.el-menu + .el-menu--collapse {
  background: #4f5050;
}

.el-menu.el-menu--collapse + .el-radio-group {
  left: 70px;
}
.gnb_in .el-radio-button .el-radio-button__inner {
  background: #40454b url('~@/assets/img/icon_toggle.png') no-repeat center 16px;
  border: 0;
  font-size: 0;
  width: 16px;
  height: 53px;
  padding: 0;
}
.gnb_in .el-radio-button {
  margin-bottom: 3px;
  --el-radio-button-checked-bg-color: #40454b;
  --el-radio-button-checked-border-color: #40454b;
}
.gnb_in .el-radio-button.is-active span {
  background: #40454b url('~@/assets/img/icon_toggle.png') no-repeat center -24px;
}
.el-menu {
  border: 0;
  height: 100%;
  position: relative;
  background-color: #454545;
}
.el-radio-button {
  margin-bottom: 2px;
}

.el-radio-button:first-child .el-radio-button__inner,
.el-radio-button:last-child .el-radio-button__inner {
  display: inline-block;
  padding: 0;
  width: 12px;
  height: 86px;
  background: #555 !important;
  text-indent: -9999px;
  border-radius: 0 3px 3px 0;
  border: 0 solid #ccc;
  border-left: 0;
  position: relative;
  box-shadow: 2px 2px 2px #00000024;
}
.el-radio-button:first-child .el-radio-button__inner:hover,
.el-radio-button:last-child .el-radio-button__inner:hover {
  background: #353535 !important;
}
.el-radio-button:first-child .el-radio-button__inner::before,
.el-radio-button:last-child .el-radio-button__inner::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  width: 6px;
  height: 10px;
  margin-top: -5px;
  margin-left: -3px;
}
.el-radio-button:last-child .el-radio-button__inner::before {
  background: url('~@/assets/img/icon_toggle.png') no-repeat left top;
}
.el-radio-button:first-child .el-radio-button__inner::before {
  background: url('~@/assets/img/icon_toggle.png') no-repeat left -40px;
}
</style>
