import bseFinFormService from '@/api/services/base/bse-fin-form-serivce'
import commonService from '@/api/services/cmm/common-service'

const FETCH_FRM_LIST = 'FETCH_FRM_LIST' // 양식리스트
const FETCH_FRM_DETAIL_LIST = 'FETCH_FRM_DETAIL_LIST' // 양식상세리스트
const FETCH_FRM01_LIST = 'FETCH_FRM01_LIST' // 양식종류
const FETCH_FRM01_LIST2 = 'FETCH_FRM01_LIST2' // 양식종류2
const FETCH_ACC_MGMT_LIST1 = 'FETCH_ACC_MGMT_LIST1' // 계정관리리스트
const FETCH_ACC_MGMT_LIST2 = 'FETCH_ACC_MGMT_LIST2' // 계정관리리스트

const bseFinFormMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 양식리스트
    frms: [],
    frm: {
      // frmTyp:1000164,
      // frmNm:(2022)재무제표1
    },
    // 양식 상세
    frmDetails: [],
    frmDetail: {
      // frmNm:'  Ⅰ. 유동자산'
      // lvCl:"2",
      // rowSeq:2,
      // accId:111
    },

    // 유형(조회용)
    frmTyps: [],

    // 유형(팝업용)
    frmTyps2: [],

    // 계정관리조회
    accMgmts1: [],
    accMgmts2: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_FRM_LIST](state, frms) {
      state.frms = frms
    },
    [FETCH_FRM_DETAIL_LIST](state, frmDetails) {
      state.frmDetails = frmDetails
    },
    [FETCH_FRM01_LIST](state, frmTyps) {
      state.frmTyps = frmTyps
    },
    [FETCH_FRM01_LIST2](state, frmTyps2) {
      state.frmTyps2 = frmTyps2
    },
    [FETCH_ACC_MGMT_LIST1](state, accMgmts1) {
      state.accMgmts1 = accMgmts1
    },
    [FETCH_ACC_MGMT_LIST2](state, accMgmts2) {
      state.accMgmts2 = accMgmts2
    }
  },

  actions: {
    // 양식리스트조회
    fetchFrmList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        bseFinFormService
          .getFinFrmInfoList(queryStr)
          .then((res) => {
            commit(FETCH_FRM_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 양식 상세조회
    fetchFrmDetailList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        bseFinFormService
          .getFinFrmInfoDetList(queryStr)
          .then((res) => {
            commit(FETCH_FRM_DETAIL_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 양식유형 공통코드조회
    fetchFrmTypeList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_FRM01_LIST, res.data)
            const frmTyp2 = JSON.parse(JSON.stringify(res.data))
            frmTyp2.shift()
            commit(FETCH_FRM01_LIST2, frmTyp2)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },

    // 계정관리리스트 조회
    fetchAccMgmtList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        bseFinFormService
          .getAccMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_ACC_MGMT_LIST1, res.data.accMgmt1)
            commit(FETCH_ACC_MGMT_LIST2, res.data.accMgmt2)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 엑셀업로드
    // includeFrmYn => 양식포함 Y/N - 양식 포함일 경우 업로드한 엑셀을 다운로드를 하여 레벨 순서 등을 정리하여 다시 올린다
    uploadExcel({ commit }, { files, sheetNum, includeFrmYn }) {
      return new Promise((resolve, reject) => {
        bseFinFormService
          .uploadExcel(files, sheetNum, includeFrmYn)
          .then((res) => {
            commit(FETCH_FRM_DETAIL_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 재무양식 상세 row삭제
    deleteFrmDetails({ commit, state }, selIdx) {
      state.frmDetails.splice(selIdx, 1)
      commit(FETCH_FRM_DETAIL_LIST, state.frmDetails)
    },

    // 양식리스트 초기화
    clearFrms({ commit }) {
      commit(FETCH_FRM_LIST, [])
    },
    // 양식상세리스트 초기화
    clearFrmDetails({ commit }) {
      commit(FETCH_FRM_DETAIL_LIST, [])
    }
  }
}

export default bseFinFormMgmtStore
