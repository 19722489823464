import infFacilityMgmtService from '@/api/services/infra/inf-facility-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_FACILITY_LIST = 'FETCH_FACILITY_LIST' // 시설
const FETCH_FACILITY = 'FETCH_FACILITY'
const FETCH_LOC_CL_LIST = 'FETCH_LOC_CL_LIST' // 위치 구분
const FETCH_LOC_CL = 'FETCH_LOC_CL'
const FETCH_RNT_CL_LIST = 'FETCH_RNT_CL_LIST' // 임대 구분
const FETCH_RNT_CL = 'FETCH_RNT_CL'
const FETCH_RNT_TYP_LIST = 'FETCH_RNT_TYP_LIST' // 임대 유형
const FETCH_RNT_TYP = 'FETCH_RNT_TYP'
const FETCH_CRNCY_LIST = 'FETCH_CRNCY_LIST' // 통화
const FETCH_CRNCY = 'FETCH_CRNCY'
const FETCH_UNIT_LIST = 'FETCH_UNIT_LIST' // 단위
const FETCH_UNIT = 'FETCH_UNIT'

const infFacilityMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    facilities: [],

    facility: {
      // bo_rnt_id: 1000002,
      // bo_id: 1000001,
      // bo_nm: ,
      // office: '',
      // rnt_typ_id: 1000062,
      // loc_typ_id: 1000067,
      // loc_nm: '푸동공항 사무실',
      // rnt_nm: 항공화물 처리,
      // rnt_cl_id: 1000071,
      // qty: 110.88,
      // unit: 'm2',
      // rnt_prd: 999,
      // ltc_yn: 'Y',
      // cont_std: '20190601',
      // cont_etd: '',
      // pnlty_note: '',
      // crncy_cd: 'CNY',
      // hre: 15000.00,
      // hre_prd: '',
      // dpam_end_yn: '',
      // addr: ' Rm301, No.528 HaiTian Yi Road,',
      // note: '',
      // del_yn: 'N',
      // reg_dt: '',
      // upd_dt: '',
      // work_ip: '',
      // reg_no: '',
      // upd_no: ''
    },

    // 위치 구분
    locCls: [],
    locCl: {
      // codeType:undefined
      // itemCd:"1"
      // label:"사무실"
      // labelEng:"사무실"
      // value:1000067
    },

    // 임대 구분
    rntCls: [],
    rntCl: {
      // codeType:undefined
      // itemCd:"1"
      // label:"임차"
      // labelEng:"임차"
      // value:1000071
    },

    // 임대 유형
    rntTypes: [],
    rntType: {
      // codeType:undefined
      // itemCd:"10"
      // label:"시설"
      // labelEng:"시설"
      // value:1000062
    },

    // 통화
    crncys: [],
    crncyCd: {
      // label:"United Arab Emirates dirham"
      // value:"AED"
    },

    // 단위
    units: [],
    unit: {}
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_FACILITY_LIST](state, payload) {
      state.facilities = payload
    },
    [FETCH_FACILITY](state, facility) {
      state.facility = facility
    },
    [FETCH_LOC_CL_LIST](state, locCls) {
      state.locCls = locCls
    },
    [FETCH_LOC_CL](state, locCl) {
      state.locCl = locCl
    },
    [FETCH_RNT_CL_LIST](state, rntCls) {
      state.rntCls = rntCls
    },
    [FETCH_RNT_CL](state, rntCl) {
      state.rntCl = rntCl
    },
    [FETCH_RNT_TYP_LIST](state, rntTypes) {
      state.rntTypes = rntTypes
    },
    [FETCH_RNT_TYP](state, rntType) {
      state.rntType = rntType
    },
    [FETCH_CRNCY_LIST](state, crncys) {
      state.crncys = crncys
    },
    [FETCH_CRNCY](state, crncyCd) {
      state.crncyCd = crncyCd
    },
    [FETCH_UNIT_LIST](state, units) {
      state.units = units
    },
    [FETCH_UNIT](state, unit) {
      state.unit = unit
    }
  },

  actions: {
    // === for Grid sample
    fetchFacilityMgmtList({ commit }, queryStr = '') {
      // console.log('infEmployeeMgmtStore > fetchFacilityMgmtList > qureystr ==>', queryStr)
      return new Promise((resolve, reject) => {
        infFacilityMgmtService
          .getFacilityMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_FACILITY_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchFacility({ commit }, selectFacility) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_FACILITY, selectFacility)
    },
    fetchLocClList({ commit, state }, { codeType, locale, cdVal }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            this.codeOpts = res.data

            const locClList = [
              {
                cdVal: '',
                codeType: 'RLOC',
                itemCd: '',
                label: 'ALL',
                labelEng: 'ALL',
                value: ''
              }
            ]

            if (cdVal) {
              for (let i = 0; i < res.data.length; i++) {
                const resCdVal = res.data[i].cdVal

                if (cdVal === resCdVal) {
                  const temp = JSON.parse(JSON.stringify(res.data[i]))
                  locClList.push(temp)
                }
              }
            }

            commit(FETCH_LOC_CL_LIST, locClList)
            commit(FETCH_LOC_CL, JSON.parse(JSON.stringify(locClList[0])))
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    fetchLocCl({ commit }, selectLocCl) {
      commit(FETCH_LOC_CL, selectLocCl)
    },

    fetchRntClList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            this.codeOpts = res.data
            commit(FETCH_RNT_CL_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchRntCl({ commit }, selectRntCl) {
      // 특정 row를 선택(list에서 특정 건이  때)
      commit(FETCH_RNT_CL, selectRntCl)
    },

    fetchRntTypList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_RNT_TYP_LIST, res.data)

            for (let i = 0; i < res.data.length; i++) {
              const cdVal = res.data[i].cdVal

              if (cdVal === '1') {
                // cd_val = '1'인 '부동산' 선택
                commit(
                  FETCH_RNT_TYP,
                  JSON.parse(JSON.stringify(state.rntTypes[i]))
                )
              }
            }

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    fetchRntTyp({ commit, state }, selectRntTyp) {
      // 특정 임대 유형 선택하면 cd_val이 동일한 구분 리스트 라져오기
      commit(FETCH_RNT_TYP, selectRntTyp)
    },

    clearInfFacility({ commit }) {
      commit(FETCH_FACILITY_LIST, [])
    },
    fetchCrncyList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCurrencyCodeList()
          .then((res) => {
            console.log('infEmployeeMgmtStore > fetchCrncyList > res', res)
            this.codeOpts = res.data
            commit(FETCH_CRNCY_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },

    fetchUnitList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            this.codeOpts = res.data
            commit(FETCH_UNIT_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchUnit({ commit }, selectRntCl) {
      // 특정 row를 선택(list에서 특정 건이  때)
      commit(FETCH_UNIT, selectRntCl)
    }
  }
}

export default infFacilityMgmtStore
