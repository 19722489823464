// import FinBalanceSheetPage from '@/views/finance/FinBalanceSheetPage.vue';

export default [
  // 재무제표
  {
    path: '/fin-balance-sheet',
    name: 'FinBalanceSheetPage',
    component: () =>
      import(
        /* webpackChunkName: "Finance" */ '@/views/finance/FinBalanceSheetPage.vue'
      ),
    meta: { auth: true }
  },

  // 재무관리 손익계산서
  {
    path: '/fin-income-stagement',
    name: 'FinIncomeStatementPage',
    component: () =>
      import(
        /* webpackChunkName: "Finance" */ '@/views/finance/FinIncomeStatementPage.vue'
      ),
    meta: { auth: true }
  },

  {
    // === 미수채권 증감 현황 Page ===
    path: '/fin-ar-incremental-analysis',
    name: 'FinArIncreAnalysisPage',
    // component: FinArIncreAnalysisPage,
    component: () =>
      import(
        /* webpackChunkName: "Finance" */ '@/views/finance/FinArIncreAnalysisPage.vue'
      ),
    meta: { auth: true }
  },

  {
    // === 미수채권 정보 등록 Page ===
    path: '/fin-accounts-receivable',
    name: 'FinAccountsReceivablePage',
    // component: FinAccountsReceivablePage,
    component: () =>
      import(
        /* webpackChunkName: "Finance" */ '@/views/finance/FinAccountsReceivablePage.vue'
      ),
    meta: { auth: true }
  },

  {
    // === 재무제표 리포트 Page ===
    path: '/fin-curr-status-inq',
    name: 'FinCurrStatusInqPage',
    // component: FinCurrStatusInqPage,
    component: () =>
      import(
        /* webpackChunkName: "Finance" */ '@/views/finance/FinCurrStatusInqPage.vue'
      ),
    meta: { auth: true }
  }
]
