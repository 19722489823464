import bseBranchOfficeMgmtService from '@/api/services/base/bse-branch-office-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_BRANCH_OFFICE_LIST = 'FETCH_BRANCH_OFFICE_LIST' // 법인
const FETCH_BRANCH_OFFICE = 'FETCH_BRANCH_OFFICE'
const FETCH_BOCL_LIST = 'FETCH_BOCL_LIST' // 법인코드
const FETCH_BOCL = 'FETCH_BOCL'
const FETCH_CNTRY_LIST = 'FETCH_CNTRY_LIST' // 국가
const FETCH_CNTRY = 'FETCH_CNTRY'
const FETCH_CRNCY_LIST = 'FETCH_CRNCY_LIST' // 통화
const FETCH_CRNCY = 'FETCH_CRNCY'
const FETCH_TRRT_LIST = 'FETCH_TRRT_LIST' // 지역
const FETCH_TRRT = 'FETCH_TRRT'
const FETCH_BO_LIST = 'FETCH_BO_LIST' // 법인리스트
const SELECT_BO = 'SELECT_BO' // 선택된 법인
const SUB_BO_LIST = 'SUB_BO_LIST' // 지점리스트
const SELECT_SUB_BO = 'SELECT_SUB_BO' // 선택된 지점 리스트

const bseBranchOfficeMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 법인
    branchOffices: [],
    branchOffice: {
      // bo:"중국법인"
      // boCl:"H"
      // boId:1000001
      // boNm:"중국법인"
      // boNmEng:"중국법인"
      // boSnm:"중국법인"
      // boSnmEng:"중국법인"
      // cntryCd:"CN"
      // compId:100
      // crncyCd:"CNY"
      // etd:"29991231"
      // regDt:1647017757000
      // regNo:"bat"
      // std:"20200101"
      // subBo:""
      // subBoId:0
      // trrtId:1000102
      // updDt:1648228243000
      // useYn:"Y"
    },

    // 법인 구분
    boCls: [],
    boCl: {
      // codeType:"BOCL"
      // itemCd:"B"
      // label:"지사"
      // labelEng:"지사"
      // value:1000010
    },

    // 국가
    cntries: [],
    cntryCd: {
      // crncyCd:undefined
      // isoCntryCd:"AED"
      // label:"United Arab Emirates"
      // value:"AE"
    },

    // 통화
    crncies: [],
    crncyCd: {
      // label:"United Arab Emirates dirham"
      // value:"AED"
    },

    // 지역
    trrts: [],
    trrtId: {
      // codeType:"TRRT"
      // itemCd:"1"
      // label:"중국지역"
      // labelEng:"중국지역"
      // value:1000102
    },

    // 법인리스트
    bos: [],
    // 선택된 법인
    bo: {
      boId: '' // 1000001
      // boNm: '상해',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng:
      //   'Shanghai Hyundai Asia Shipping International Freight Forwarding Co.Ltd.'
    },

    // 지점리스트
    subBos: [],
    // 선택된 지점
    subBo: {
      boId: '' // 1000021
      // boNm: '낙척(북경)',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng: '낙천(북경)물류유한공사'
    }
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_BRANCH_OFFICE_LIST](state, branchOffices) {
      state.branchOffices = branchOffices
    },
    [FETCH_BRANCH_OFFICE](state, branchOffice) {
      state.branchOffice = branchOffice
    },
    [FETCH_BOCL_LIST](state, boCls) {
      boCls = boCls.slice(2)
      state.boCls = boCls
    },
    [FETCH_BOCL](state, boCl) {
      state.boCl = boCl
    },
    [FETCH_CNTRY_LIST](state, cntries) {
      cntries.shift()
      state.cntries = cntries
    },
    [FETCH_CNTRY](state, cntryCd) {
      state.cntryCd = cntryCd
    },
    [FETCH_CRNCY_LIST](state, crncies) {
      state.crncies = crncies
    },
    [FETCH_CRNCY](state, crncyCd) {
      state.crncyCd = crncyCd
    },
    [FETCH_TRRT_LIST](state, trrts) {
      trrts.shift()
      state.trrts = trrts
    },
    [FETCH_TRRT](state, trrtId) {
      state.trrtId = trrtId
    },
    // 법인리스트
    [FETCH_BO_LIST](state, payload) {
      state.bos = payload
    },
    // 선택된 법인
    [SELECT_BO](state, bo) {
      state.bo = bo
    },
    // 지점리스트
    [SUB_BO_LIST](state, subBos) {
      state.subBos = subBos
    },
    // 선택된지점
    [SELECT_SUB_BO](state, subBo) {
      state.subBo = subBo
    }
  },

  actions: {
    fetchBranchOfficeMgmtList({ commit }, queryStr = '') {
      console.log(
        'bseBranchOfficeMgmtStore > fetchBranchOfficeMgmtList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseBranchOfficeMgmtService
          .getBranchOfficeMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_BRANCH_OFFICE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchBranchOffice({ commit }, selectBranchOffice) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_BRANCH_OFFICE, selectBranchOffice)
    },
    clearBranchOffice({ commit }) {
      commit(FETCH_BRANCH_OFFICE_LIST, [])
    },
    fetchBoClList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_BOCL_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchBoCl({ commit }, selectBoCl) {
      commit(FETCH_BOCL, selectBoCl)
    },
    fetchCntryList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCountryCodeList()
          .then((res) => {
            console.log('bseBranchOfficeMgmtStore > fetchCntryList > res', res)
            commit(FETCH_CNTRY_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchCrncyList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCurrencyCodeList()
          .then((res) => {
            console.log('bseBranchOfficeMgmtStore > fetchCrncyList > res', res)
            commit(FETCH_CRNCY_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchTrrtList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_TRRT_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchTrrtId({ commit }, selectTrrtId) {
      commit(FETCH_TRRT, selectTrrtId)
    },
    // 법인리스트를 가지고 온다.
    fetchBoList({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        bseBranchOfficeMgmtService
          .getBoList()
          .then((res) => {
            const tmpBo = {
              boId: '',
              boNm: 'ALL',
              subBos: [{ boId: '', boNm: '' }]
            }
            res.data.unshift(tmpBo)

            commit(FETCH_BO_LIST, res.data)

            // const selbo = [
            //   {
            //     boId: '',
            //     boNm: 'ALL',
            //     boNmEng: 'ALL'
            //   }
            // ]

            // res.data.unshift(...selbo)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 법인선택시
    // @param selBoId : 선택된 법인 ID
    selectBo({ commit, state }, selBoId) {
      // 특정 법인을 선택했을 경우 해당 법인의 지점 선택
      let selbo = {
        boId: '',
        boNm: 'ALL',
        subBos: []
      }

      const selSubbo = [
        {
          boId: '',
          boNm: 'ALL',
          boNmEng: 'ALL'
        }
      ]

      if (selBoId) {
        selbo = JSON.parse(
          JSON.stringify(state.bos.find((bo) => bo.boId === selBoId))
        )
      }

      selbo.subBos.unshift(...selSubbo)

      commit(SELECT_BO, selbo)
      commit(SUB_BO_LIST, selbo.subBos || [{ boId: '' }])
      commit(
        SELECT_SUB_BO,
        JSON.parse(JSON.stringify(selbo.subBos[0] || { boId: '' }))
      )
    },
    // 지점 선택시
    // @param selSubBoId : 선택된 지점ID
    selectSubBo({ commit, state }, selSubBoId) {
      // 특정 지점을 선택했을 경우
      let selSubBo = { boId: '' }
      if (selSubBoId) {
        selSubBo = JSON.parse(
          JSON.stringify(
            state.subBos.find((subBo) => subBo.boId === selSubBoId)
          )
        )
      }

      commit(SELECT_SUB_BO, selSubBo || { boId: '' })
    }
  }
}

export default bseBranchOfficeMgmtStore
