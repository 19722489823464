import finAccountsReceivableServices from '@/api/services/finance/fin-accounts-receivable-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_INCLUDING_MARINE_LIST = 'FETCH_INCLUDING_MARINE_LIST' // 현대상선 포함 미수채권
const FETCH_INCLUDING_MARINE = 'FETCH_INCLUDING_MARINE'
const FETCH_EXCLUDING_MARINE_LIST = 'FETCH_EXCLUDING_MARINE_LIST' // 현대상선 제외 미수채권
const FETCH_EXCLUDING_MARINE = 'FETCH_EXCLUDING_MARINE'
const FETCH_INCCL_LIST = 'FETCH_INCCL_LIST' // 업로드 대상
const FETCH_INCCL = 'FETCH_INCCL'
// 화폐구분 공통코드 리스트
const FETCH_UNTDP_LIST = 'FETCH_UNTDP_LIST'

const finAccountsReceivableStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    // 현대 상선 포함
    includingMarines: [],
    includingMarine: {},

    // 현대 상선 제외
    excludingMarines: [],
    excludingMarine: {},

    // 업로드 대상
    inccls: [],
    incclCd: {
      // codeType:"INCCL"
      // itemCd:"1"
      // label:"상선제외"
      // labelEng:"상선제외"
      // value:1000084
    },

    // 화폐단위
    untDps: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_INCLUDING_MARINE_LIST](state, includingMarines) {
      state.includingMarines = includingMarines
    },
    [FETCH_INCLUDING_MARINE](state, includingMarine) {
      state.includingMarine = includingMarine
    },
    [FETCH_EXCLUDING_MARINE_LIST](state, excludingMarines) {
      state.excludingMarines = excludingMarines
    },
    [FETCH_EXCLUDING_MARINE](state, excludingMarine) {
      state.excludingMarine = excludingMarine
    },
    [FETCH_INCCL_LIST](state, inccls) {
      inccls.shift()
      state.inccls = inccls
    },
    [FETCH_INCCL](state, incclCd) {
      state.incclCd = incclCd
    },
    [FETCH_UNTDP_LIST](state, untDps) {
      state.untDps = untDps
    }
  },

  actions: {
    fetchIncludingMarineList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        finAccountsReceivableServices
          .getIncludingMarineList(queryStr)
          .then((res) => {
            commit(FETCH_INCLUDING_MARINE_LIST, res.data)
            console.log('fetchIncludingMarineList > res', res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearIncludingMarine({ commit }) {
      commit(FETCH_INCLUDING_MARINE_LIST, [])
    },
    fetchExcludingMarineList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        finAccountsReceivableServices
          .getExcludingMarineList(queryStr)
          .then((res) => {
            commit(FETCH_EXCLUDING_MARINE_LIST, res.data)
            console.log('fetchIncludingMarineList > res', res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearExcludingMarine({ commit }) {
      commit(FETCH_EXCLUDING_MARINE_LIST, [])
    },
    includingUploadExcel({ commit }, { files, arYymm, incclCd, sheetNum }) {
      return new Promise((resolve, reject) => {
        finAccountsReceivableServices
          .includingUploadExcel(files, arYymm, incclCd, sheetNum)
          .then((res) => {
            commit(FETCH_INCLUDING_MARINE_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    excludingUploadExcel({ commit }, { files, arYymm, incclCd, sheetNum }) {
      return new Promise((resolve, reject) => {
        finAccountsReceivableServices
          .excludingUploadExcel(files, arYymm, incclCd, sheetNum)
          .then((res) => {
            commit(FETCH_EXCLUDING_MARINE_LIST, res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteIncudingMarine({ commit, state }, selIdx) {
      state.arSmmryCusts.splice(selIdx, 1)
      commit(FETCH_INCLUDING_MARINE_LIST, state.arSmmryCusts)
    },
    deleteExcludingMarine({ commit, state }, selIdx) {
      state.arSmmryCusts.splice(selIdx, 1)
      commit(FETCH_EXCLUDING_MARINE_LIST, state.arSmmryCusts)
    },
    fetchIncclList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_INCCL_LIST, res.data)
            commit(FETCH_INCCL, JSON.parse(JSON.stringify(state.inccls[0])))
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchInccl({ commit }, selectIncclCd) {
      commit(FETCH_INCCL, selectIncclCd)
    },
    // 화폐단위 조회
    fetchUntDpList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_UNTDP_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}

export default finAccountsReceivableStore
