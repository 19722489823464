const routes = [
  {
    path: '/chart/area',
    name: 'AreaChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/AreaChart.vue'
      )
  },
  {
    path: '/chart/bar',
    name: 'BarChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/BarChart.vue'
      )
  },
  {
    path: '/chart/column',
    name: 'ColumnChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/ColumnChart.vue'
      )
  },
  {
    path: '/chart/stackcolumn',
    name: 'StackColumnChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/StackColumnChart.vue'
      )
  },
  {
    path: '/chart/donut',
    name: 'DonutChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/DonutChart.vue'
      )
  },
  {
    path: '/chart/pie',
    name: 'PieChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/PieChart.vue'
      )
  },
  {
    path: '/chart/line',
    name: 'LineChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/LineChart.vue'
      )
  },
  {
    path: '/chart/linecolumn',
    name: 'LineColumnChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/LineColumnChart.vue'
      )
  },
  {
    path: '/chart/mixed',
    name: 'MixedChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/MixedChart.vue'
      )
  },
  {
    path: '/chart/heatmap',
    name: 'HeatmapChart',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/chart/HeatmapChart.vue'
      )
  }
]

export default routes
