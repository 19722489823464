import { axiosApi } from '@/api'

export default {
  // --- 접근이력 조회 ---
  getAccessHistList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/access-hists${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
