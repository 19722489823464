import { axiosApi } from '@/api'

export default {
  userCheck(userInfo) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/checkUserInfo', userInfo)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // === 로그인 서비스 ===
  // 인증이 완료되면 AccessToken, RefreshToken, 사용자 정보, 사용가능한 메뉴,
  // 메뉴별 권한 정보를 리턴
  userLogin(userInfo) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/login', userInfo)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // OTP 메일 전송
  userOTPMailsend(otpInfo) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/otpSend', otpInfo)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // OTP 확인
  userOTPConfirm(otpInfo) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/otpConfirm', otpInfo)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // === 로그인 사용자의 관련 정보 취득 서비스 ===
  // 로그인 인증을 거쳤다면 토근이 같이 전송되어 로그인 사용자의 정보를 조회 가능
  // 자동로그인에 사용되므로 사용자 정보, 사용가능한 메뉴, 메뉴별 권한 정보를 리턴
  getMyInfo() {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/user-menu')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // === 토근 재발행 ===
  // RefreshToken이 유효하면 AccessToken 및 Refresh Token 재발행
  getRefreshToken() {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/ref-token')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // === 로그아웃 서비스 ===
  // 서버 토큰 및 DB정보 제거 요청
  userLogout(userInfo) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/logout', userInfo)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  resetUserPwd(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/cmm/resetpwd', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
