import sysAuthMgmtService from '@/api/services/system/sys-auth-mgmt-service'
import sysPgmMgmtService from '@/api/services/system/sys-pgm-mgmt-service'

// === for gred sample
const FETCH_AUTH_LIST = 'FETCH_AUTH_LIST'
const SELECT_AUTH = 'SELECT_AUTH'
const FETCH_MENU_LIST = 'FETCH_MENU_LIST'
const SELECT_MENU = 'SELECT_MENU'
const FETCH_PGM_AUTH_LIST = 'FETCH_PGM_AUTH_LIST'
const SELECT_PGM_AUTH = 'SELECT_PGM_AUTH'
const FETCH_MENU_CODE_LIST = 'FETCH_MENU_CODE_LIST'
const CLEAR_STORE = 'CLEAR_STORE'

const sysAuthMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 사용자
    auths: [],
    auth: {
      // authCd: 'LGL-002', // 권한 코드
      // authNm: '해외사업팀', // 권한코드명
      // authNmEng: '해외사업팀', // 권한코드명(영문)
      // delYn: 'N'
    },

    // 메뉴리스트
    menus: [],
    menu: {
      // topMenuCd: 'INC000',
      // topMenuNm: '손익관리',
      // topMenuNmEng: 'Income Mgmt.'
    },

    // 프로그램 권한
    pgmAuths: [],
    pgmAuth: {
      // authCd: 'LGL-001', // 권한 코드
      // topMenuCd: 'BOINC000', // top 메뉴 코드
      // topMenuNm: '손익관리', // top 메뉴 명
      // topMenuNmEng: '손익관리', // top 메뉴 명(영어)
      // pgmId: 'BOINC001', // 프로그램 id
      // pgmNm: '매출등록(계획)', // 프로그램 명
      // pgmNmEng: '매출등록(계획)', // 프로그램 명(영어)
      // viewSeq: 101, // 뷰 순서
      // viewAuth: true, // view 권한
      // addAuth: true, // 추가 권한
      // chgAuth: true, // 수정 권한
      // delAuth: true, // 삭제 권한
      // dwlAuth: true, // 다운로드 권한
      // uplAuth: false, // 업로드 권한
      // sveAuth: false, // 저장 권한
      // clsAuth: false, // 마감 권한
      // codeLv: '2', // code level
      // delYn: 'N' // 삭제 YN
    },

    // 메뉴코드 리스트(코드용)
    menuCodes: [],
    menuCode: {
      // topMenuCd: 'INC000',
      // topMenuNm: '손익관리',
      // topMenuNmEng: 'Income Mgmt.'
    }
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_AUTH_LIST](state, auths) {
      state.auths = auths
      state.pgmAuths = []
    },
    [SELECT_AUTH](state, auth) {
      state.auth = auth
    },
    [FETCH_MENU_LIST](state, menus) {
      state.menus = menus
      state.pgmAuths = []
    },
    [SELECT_MENU](state, menu) {
      state.menu = menu
    },
    [FETCH_PGM_AUTH_LIST](state, pgmAuths) {
      state.pgmAuths = pgmAuths
    },
    [SELECT_PGM_AUTH](state, pgmAuth) {
      state.pgmAuth = pgmAuth
    },
    [FETCH_MENU_CODE_LIST](state, menuCodes) {
      state.menuCodes = menuCodes
    },
    [CLEAR_STORE](state) {
      state.auths = []
      state.menus = []
      state.pgmAuths = []
    }
  },

  actions: {
    // 권한 리스트 조회
    fetchAuthList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysAuthMgmtService
          .getAuthList(queryStr)
          .then((res) => {
            commit(FETCH_AUTH_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 권한코드 선택
    selectAuth({ commit }, selectAuth) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_AUTH, selectAuth)
    },

    // 메뉴 리스트 조회
    fetchMenuList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysAuthMgmtService
          .getMenuList(queryStr)
          .then((res) => {
            commit(FETCH_MENU_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 메뉴 선택
    selectMenu({ commit }, selectMenu) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_MENU, selectMenu)
    },

    // 프로그램 권한 리스트 조회
    fetchPgmAuthList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysAuthMgmtService
          .getPgmAuthList(queryStr)
          .then((res) => {
            commit(FETCH_PGM_AUTH_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 권한코드 선택
    selectPgmAuth({ commit }, selectPgmAuth) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_PGM_AUTH, selectPgmAuth)
    },

    // 메뉴코드 리스트 조회
    fetchMenuCodeList({ commit }, { queryStr, locale }) {
      return new Promise((resolve, reject) => {
        sysPgmMgmtService
          .getMenuCodeList(queryStr, locale)
          .then((res) => {
            commit(FETCH_MENU_CODE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default sysAuthMgmtStore
