import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from './mixins'

// 다국어 설정
import i18n from './i18n'

// --- Element UI for vue3 ---
import ElementPlus from 'element-plus'

// --- Element UI css for vue3 ---
import 'element-plus/dist/index.css'
// --- Project Custom stylee css ---
import './assets/css/reset.css'
import './assets/css/NotoSansKR-Hestia.css'
import './assets/css/style.css'
import './assets/css/custom.css'
import './assets/css/sub.css'

// ===========>>> 2022.02.24 제거 예정
// --- fontaweson library
// import { library } from '@fortawesome/fontawesome-svg-core'
// import {
//   faCaretUp,
//   faCaretDown,
//   faTimes
// } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import VueSweetalert2 from 'vue-sweetalert2'

// import Datepicker from 'vue3-date-time-picker'
// import VueLoading from 'vue-loading-overlay'

// import './assets/css/bootstrap-grid.min.css'
// import './assets/css/bootstrap-utilities.min.css'

// import 'vue-loading-overlay/dist/vue-loading.css'
// import 'sweetalert2/dist/sweetalert2.min.css'
// import 'vue3-date-time-picker/dist/main.css'

// library.add(faCaretUp, faCaretDown, faTimes)
// ===========>>> 2022.02.24 제거 예정

// --- vue app instance 생성 ---
const app = createApp(App)
app
  .use(router)
  .use(store)
  .use(i18n) // 다국어 처리
  .use(ElementPlus) // Element UI

// ===========>>> 2022.02.24 제거 예정
// 선택적 추가 라이브러리
// app.use(VueLoading)
// app.use(VueSweetalert2)
// app.component('font-awesome-icon', FontAwesomeIcon)
// app.component('Datepicker', Datepicker)
// ===========>>> 2022.02.24 제거 예정

// --- 사용자 directive 정의 ---
// input 요소에 마우스 포커스 처리
app.directive('focus', {
  mounted(el, binding) {
    el.focus()
  }
})

// input 요소에서 포커스 아웃시 값이 입력되었는지를 체크
app.directive('blur', {
  mounted(el, binding) {
    if (binding.arg) {
      el.addEventListener('blur', () => {
        if (el.value === '') {
          alert('필수 입력 값입니다.')
        }
      })
    }
  }
})

// ===========>>> 2022.02.24 제거 예정
// 숫자만 입력가능하도록 일반 input 요소를 변경
// app.directive('number', {
//   mounted(el, binding) {
//     el.addEventListener('keydown', (e) => {
//       if (
//         !(
//           (e.keyCode >= 48 && e.keyCode <= 57) ||
//           e.keyCode === 8 ||
//           e.keyCode === 46 ||
//           e.keyCode === 37 ||
//           e.keyCode === 39
//         )
//       ) {
//         e.preventDefault()
//       }
//     })
//   }
// })

// // 소수점 포함해서 숫자만 입력가능하도록 일반 input 요소를 변경
// app.directive('float', {
//   mounted(el, binding) {
//     el.addEventListener('keydown', (e) => {
//       if (
//         !(
//           (e.keyCode >= 48 && e.keyCode <= 57) ||
//           e.keyCode === 190 ||
//           e.keyCode === 8 ||
//           e.keyCode === 46 ||
//           e.keyCode === 37 ||
//           e.keyCode === 39
//         )
//       ) {
//         e.preventDefault()
//       }
//     })
//   }
// })

// axios 관련 함수 mixin으로 등록
// 서비스 레이어를 만들어 분리했으므로 의미 없어짐
// ★★★ 샘플 참조를 위해 일시적으로 남겨두지만 추후 제거 필요
// ===========>>> 2022.02.24 제거 예정(마지막에...)
app.mixin(mixin)
// ===========>>> 2022.02.24 제거 예정

// mount vue instance to app id
app.mount('#app')
