import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 양식리스트조회
  getFrmInfoList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/frm-infos${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 엑셀 Sheet명 리스트 가져오기
  getExcelSheetNames(files) {
    console.log('getExcelSheetNames!!')
    return commonUtils.$upload('/fin/frm-infos/xls/sheets-name', files[0])
  },

  // 법인목록관리 리스트조회
  getFinBoMgmtList(queryStr = '', pos) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/frm-infos/bo-mgmt/${pos}${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 법인목록관리 저장
  saveFrmBoList(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/fin/frm-infos', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
