import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 매출손익계획 헤더정보조회
  getIncPlan(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/inc-plan${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 매출계획헤더저장
  saveIncPlan(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/inc-plan', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 매출손익계획 정보조회
  getIncSalesPlan(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/sales-plans${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 엑셀 업로드
  uploadExcel(files, boId, incYy) {
    return commonUtils.$upload(`inc/sales-plans/xls/${boId}/${incYy}`, files[0])
  },

  // 매출계획저장
  saveIncSalPlan(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/sales-plans', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 매출계획마감
  procIncSalCls(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/sales-plans/cls', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 매출계획 삭제
  deleteIncSalPlan(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(
          `/inc/sales-plans/${payload.boId}/${payload.incYy}/${payload.incMon}/${payload.seq}`,
          payload
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
