<template>
  <div v-if="$route.path !== '/login'">
    <HeaderBar />
    <div class="container">
      <AsideMenu />
      <router-view />
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>
<script>
import HeaderBar from '@/components/layouts/HeaderBar'
import AsideMenu from '@/components/layouts/AsideMenu'
export default {
  name: 'App',

  components: {
    HeaderBar, // Top HeaerBar
    AsideMenu // Left AsideMenu
  },

  created() {}
}
</script>
