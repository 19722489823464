import axios from 'axios'
import { setInterceptors } from './common/interceptors'

// 일반 업무처리용 api
function createAxiosApi() {
  const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_LGLGMS_API_URL
  })

  return setInterceptors(axiosApi)
}
const axiosApi = createAxiosApi()

// 파일 및 엑셀 업로드/다운로드용 api
function createAxiosApiFile() {
  const axiosApiFile = axios.create({
    baseURL: process.env.VUE_APP_LGLGMS_API_URL
  })

  // return setInterceptorsFile(axiosApiFile)
  return axiosApiFile
}
const axiosApiFile = createAxiosApiFile()

export { axiosApi, axiosApiFile }
