// import IncSalesPlanPage from '@/views/income/IncSalesPlanPage.vue';

export default [
  // 매출 계획
  {
    path: '/inc-sales-plan',
    name: 'IncSalesPlanPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncSalesPlanPage.vue'
      ),
    meta: { auth: true }
  },
  // 비용 계획-영업외손익(ETC)
  {
    path: '/inc-exp-plan/2',
    name: 'IncExpPlanPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncExpPlanPage.vue'
      ),
    meta: { auth: true }
  },
  // 비용 계획-판관비상세 등록(영업실적)
  {
    path: '/inc-exp-plan/1',
    name: 'IncExpPlanPage2',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncExpPlanPage.vue'
      ),
    meta: { auth: true }
  },
  // 매출 실적
  {
    path: '/inc-sales-perf',
    name: 'IncSalesPerfPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncSalesPerfPage.vue'
      ),
    meta: { auth: true }
  },
  // 비용 실적-영업외손익(ETC)
  {
    path: '/inc-exp-perf/2',
    name: 'IncExpPerfPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncExpPerfPage.vue'
      ),
    meta: { auth: true }
  },
  // 비용 실적-판관비상세 등록(영업실적)
  {
    path: '/inc-exp-perf/1',
    name: 'IncExpPerfPage2',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncExpPerfPage.vue'
      ),
    meta: { auth: true }
  },
  // 본사 지급분 관리
  {
    path: '/inc-hopay-mgmt',
    name: 'IncHoPayMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncHoPayMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // 손익조회
  {
    path: '/inc-perf-inq',
    name: 'IncPerfInqPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncPerfInqPage.vue'
      ),
    meta: { auth: true }
  },
  // 손익집계조회
  {
    path: '/inc-mon-aggr',
    name: 'IncMonAggrPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncMonAggrPage.vue'
      ),
    meta: { auth: true }
  },
  // 당월실적
  {
    path: '/inc-curr-mon-perf',
    name: 'IncCurrMonPerfPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncCurrMonPerfPage.vue'
      ),
    meta: { auth: true }
  },
  // 손익 데이터 검색
  {
    path: '/inc-data-inq',
    name: 'IncDataInqPage',
    component: () =>
      import(
        /* webpackChunkName: "Income" */ '@/views/income/IncDataInqPage.vue'
      ),
    meta: { auth: true }
  }
]
