const routes = [
  {
    path: '/crud/userlist',
    name: 'UserList',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/crud/UserList.vue'
      )
  },
  {
    path: '/crud/usercreate',
    name: 'UserCreate',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/crud/UserCreate.vue'
      )
  },
  {
    path: '/crud/userdetail',
    name: 'UserDetail',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/crud/UserDetail.vue'
      )
  },
  {
    path: '/crud/useredit',
    name: 'UserEdit',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/crud/UserEdit.vue'
      )
  }
]

export default routes
