import { axiosApi } from '@/api'

export default {
  // 사용자 리스트 조회
  getUserList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/users${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 추가
  createUser(payload) {
    // console.log('SysUserService > createUser() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/users', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  checkUser(userId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/users/${userId}/check`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateUser(userId, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/users/${userId}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 삭제
  deleteUser(userId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/users/${userId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
