import infEquipmentMgmtService from '@/api/services/infra/inf-equipment-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_EQUIPMENT_LIST = 'FETCH_EQUIPMENT_LIST' // 장치
const FETCH_EQUIPMENT = 'FETCH_EQUIPMENT'
const FETCH_LOC_CL_LIST = 'FETCH_LOC_CL_LIST' // 위치 구분
const FETCH_LOC_CL = 'FETCH_LOC_CL'
const FETCH_LOC_CL_LIST_02 = 'FETCH_LOC_CL_LIST_02'
const FETCH_LOC_CL_02 = 'FETCH_LOC_CL_02'
const FETCH_RNT_CL_LIST = 'FETCH_RNT_CL_LIST' // 임대 구분
const FETCH_RNT_CL = 'FETCH_RNT_CL'
const FETCH_RNT_TYP_LIST = 'FETCH_RNT_TYP_LIST' // 임대 유형
const FETCH_RNT_TYP = 'FETCH_RNT_TYP'
const FETCH_RNT_TYP_LIST_02 = 'FETCH_RNT_TYP_LIST_02'
const FETCH_RNT_TYP_02 = 'FETCH_RNT_TYP_02'
const FETCH_CRNCY_LIST = 'FETCH_CRNCY_LIST' // 통화
const FETCH_CRNCY = 'FETCH_CRNCY'

const infEquipmentMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample

    // 장비
    equipments: [],
    equipment: {
      // boCl:"B"
      // boId:1000021
      // boNm:"낙척(북경)"
      // boRntId:1000033
      // contEtd:"20210509"
      // contStd:"20200510"
      // crncyCd:"CNY"
      // delYn:"N"
      // hre:2835
      // locClId:1000069
      // locClNm:"창고"
      // ltcYn:"Y"
      // qty:75
      // rntClId:1000071
      // rntClNm:"임차"
      // rntPrd:12
      // rntTypId:1000062
      // unit:"m2"
    },

    // 위치 구분
    locCls: [],
    locCl: {
      // codeType:undefined
      // itemCd:"1"
      // label:"사무실"
      // labelEng:"사무실"
      // value:1000067
    },

    // 임대 구분
    rntCls: [],
    rntCl: {
      // codeType:undefined
      // itemCd:"1"
      // label:"임차"
      // labelEng:"임차"
      // value:1000071
    },

    // 임대 유형
    rntTypes: [],
    rntType: {
      // codeType:undefined
      // itemCd:"10"
      // label:"시설"
      // labelEng:"시설"
      // value:1000062
    },

    // 통화
    crncys: [],
    crncyCd: {
      // label:"United Arab Emirates dirham"
      // value:"AED"
    },

    /**
     *  등록용
     */

    // 위치 구분
    locCls2: [],
    locCl2: {
      // codeType:undefined
      // itemCd:"1"
      // label:"사무실"
      // labelEng:"사무실"
      // value:1000067
    },

    // 임대 유형
    rntTypes2: [],
    rntType2: {
      // codeType:undefined
      // itemCd:"10"
      // label:"시설"
      // labelEng:"시설"
      // value:1000062
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_EQUIPMENT_LIST](state, payload) {
      state.equipments = payload
    },
    [FETCH_EQUIPMENT](state, equipment) {
      state.equipment = equipment
    },
    [FETCH_LOC_CL_LIST](state, locCls) {
      state.locCls = locCls
    },
    [FETCH_LOC_CL](state, locCl) {
      state.locCl = locCl
    },
    [FETCH_RNT_CL_LIST](state, rntCls) {
      state.rntCls = rntCls
    },
    [FETCH_RNT_CL](state, rntCl) {
      state.rntCl = rntCl
    },
    [FETCH_RNT_TYP_LIST](state, rntTypes) {
      rntTypes.splice(1, 1) // 부동산 제거
      state.rntTypes = rntTypes
    },
    [FETCH_RNT_TYP](state, rntType) {
      state.rntType = rntType
    },
    [FETCH_CRNCY_LIST](state, crncys) {
      state.crncys = crncys
    },
    [FETCH_CRNCY](state, crncyCd) {
      state.crncyCd = crncyCd
    },
    [FETCH_LOC_CL_LIST_02](state, locCls2) {
      state.locCls2 = locCls2
    },
    [FETCH_LOC_CL_02](state, locCl2) {
      state.locCl2 = locCl2
    },
    [FETCH_RNT_TYP_LIST_02](state, rntTypes2) {
      rntTypes2.splice(1, 1) // 부동산 제거
      state.rntTypes2 = rntTypes2
    },
    [FETCH_RNT_TYP_02](state, rntType2) {
      state.rntType2 = rntType2
    }
  },

  actions: {
    // === for Grid sample
    fetchEquipmentMgmtList({ commit }, queryStr = '') {
      // console.log('infEmployeeMgmtStore > fetchEquipmentMgmtList > qureystr ==>', queryStr)
      return new Promise((resolve, reject) => {
        infEquipmentMgmtService
          .getEquipmentMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_EQUIPMENT_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchEquipment({ commit }, selectEquipment) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_EQUIPMENT, selectEquipment)
    },
    clearInfEquipment({ commit }) {
      commit(FETCH_EQUIPMENT_LIST, [])
    },
    fetchLocClList({ commit, state }, { codeType, locale, cdVal }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            const locClList = [
              {
                cdVal: '',
                codeType: 'RLOC',
                itemCd: '',
                label: 'ALL',
                labelEng: 'ALL',
                value: ''
              }
            ]

            if (cdVal) {
              for (let i = 0; i < res.data.length; i++) {
                const resCdVal = res.data[i].cdVal

                if (cdVal === resCdVal) {
                  const temp = JSON.parse(JSON.stringify(res.data[i]))
                  locClList.push(temp)
                }
              }
            }

            commit(FETCH_LOC_CL_LIST, locClList)
            commit(FETCH_LOC_CL, JSON.parse(JSON.stringify(locClList[0])))

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    fetchLocCl({ commit, state }, selectLocCl) {
      let selLocCl = {
        cdVal: '',
        codeType: 'RLOC',
        itemCd: '',
        label: 'ALL',
        labelEng: 'ALL',
        value: ''
      }

      console.log(state.locCls)

      if (selectLocCl) {
        for (let i = 0; i < state.locCls.length; i++) {
          if (state.locCls[i].value === selectLocCl) {
            selLocCl = JSON.parse(JSON.stringify(state.locCls[i]))
          }
        }
      }

      commit(FETCH_LOC_CL, selLocCl)
    },

    clearLocCl({ commit }) {
      commit(FETCH_LOC_CL_LIST, [])
      commit(FETCH_LOC_CL, '')
    },

    fetchRntClList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            this.codeOpts = res.data
            commit(FETCH_RNT_CL_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchRntCl({ commit }, selectRntCl) {
      // 특정 row를 선택(list에서 특정 건이  때)
      commit(FETCH_RNT_CL, selectRntCl)
    },
    fetchRntTypList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            const rntTypList = [
              {
                cdVal: '',
                codeType: 'RNT',
                itemCd: '',
                label: 'ALL',
                labelEng: 'ALL',
                value: ''
              }
            ]

            for (let i = 0; i < res.data.length; i++) {
              const cdVal = res.data[i].cdVal
              if (cdVal) {
                JSON.parse(JSON.stringify(res.data[i]))
                rntTypList.push(res.data[i])
              }
            }
            commit(FETCH_RNT_TYP_LIST, rntTypList)
            commit(FETCH_RNT_TYP, rntTypList[0])

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    fetchRntTyp({ commit, state }, selectRntTyp) {
      // 특정 임대 유형 선택하면 cd_val이 동일한 구분 리스트 가져오기
      let selRntTyp = {
        cdVal: '',
        codeType: 'RNT',
        itemCd: '',
        label: 'ALL',
        labelEng: 'ALL',
        value: ''
      }

      if (selectRntTyp) {
        for (let i = 0; i < state.rntTypes.length; i++) {
          if (state.rntTypes[i].value === selectRntTyp) {
            selRntTyp = JSON.parse(JSON.stringify(state.rntTypes[i]))
          }
        }
      }

      commit(FETCH_RNT_TYP, selRntTyp)
    },

    clearRntTyp({ commit }) {
      commit(FETCH_RNT_TYP_LIST, [])
      commit(FETCH_RNT_TYP, '')
    },

    fetchCrncyList({ commit }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCurrencyCodeList()
          .then((res) => {
            console.log('infEmployeeMgmtStore > fetchCrncyList > res', res)
            this.codeOpts = res.data
            commit(FETCH_CRNCY_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },

    fetchModalLocClList({ commit, state }, { codeType, locale, cdVal }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            const locClList2 = [
              {
                cdVal: '',
                codeType: 'RLOC',
                itemCd: '',
                label: 'ALL',
                labelEng: 'ALL',
                value: ''
              }
            ]

            if (cdVal) {
              for (let i = 0; i < res.data.length; i++) {
                const resCdVal = res.data[i].cdVal

                if (cdVal === resCdVal) {
                  const temp = JSON.parse(JSON.stringify(res.data[i]))
                  locClList2.push(temp)
                }
              }
            }

            commit(FETCH_LOC_CL_LIST_02, locClList2)
            commit(FETCH_LOC_CL_02, JSON.parse(JSON.stringify(locClList2[0])))

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    fetchModalLocCl({ commit, state }, selectLocCl) {
      let selLocCl2 = {
        cdVal: '',
        codeType: 'RLOC',
        itemCd: '',
        label: 'ALL',
        labelEng: 'ALL',
        value: ''
      }

      if (selectLocCl) {
        for (let i = 0; i < state.locCls2.length; i++) {
          if (state.locCls2[i].value === selectLocCl) {
            selLocCl2 = JSON.parse(JSON.stringify(state.locCls2[i]))
          }
        }
      }

      commit(FETCH_LOC_CL_02, selLocCl2)
    },

    fetchModalRntTypList({ commit, state }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            const rntTypList2 = [
              {
                cdVal: '',
                codeType: 'RNT',
                itemCd: '',
                label: 'ALL',
                labelEng: 'ALL',
                value: ''
              }
            ]

            for (let i = 0; i < res.data.length; i++) {
              const cdVal = res.data[i].cdVal
              if (cdVal) {
                const temp = JSON.parse(JSON.stringify(res.data[i]))
                rntTypList2.push(temp)
              }
            }
            commit(FETCH_RNT_TYP_LIST_02, rntTypList2)
            commit(FETCH_RNT_TYP_02, JSON.parse(JSON.stringify(rntTypList2[0])))

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    fetchModalRntTyp({ commit, state }, selectRntTyp) {
      // 특정 임대 유형 선택하면 cd_val이 동일한 구분 리스트 가져오기
      let selRntTyp2 = {
        cdVal: '',
        codeType: 'RNT',
        itemCd: '',
        label: 'ALL',
        labelEng: 'ALL',
        value: ''
      }

      if (selectRntTyp) {
        for (let i = 0; i < state.rntTypes2.length; i++) {
          if (state.rntTypes2[i].value === selectRntTyp) {
            selRntTyp2 = JSON.parse(JSON.stringify(state.rntTypes2[i]))
          }
        }
      }

      commit(FETCH_RNT_TYP_02, selRntTyp2)
    }
  }
}

export default infEquipmentMgmtStore
