<template>
  <div class="login_wrap">
    <div class="login_in flex_layout04">
      <div class="tit_b flex_layout01">
        <h1>{{ $t('LoginPage.content.Company') }}</h1>
        <p>{{ $t('LoginPage.content.System') }}</p>
      </div>
      <div class="right_b flex_layout01">
        <div class="login_box">
          <h2>USER LOGIN</h2>
          <!-- 입력영역 -->
          <div class="input_group">
            <el-form :inline="true" :model="frmLoginInfo" class="demo-form-inline">
              <el-row>
                <el-col :span="24" class="input_box">
                  <el-form-item :label="$t('LoginPage.content.userId')" for="id">
                    <el-input v-model="frmLoginInfo.userId" ref="loginId" type="text" id="id" placeholder="Input Your ID"
                      autofocus>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="input_box">
                  <el-form-item :label="$t('LoginPage.content.userPwd')" for="pw">
                    <el-input type="Password" v-model="frmLoginInfo.userPwd" id="pw" placeholder="Input Your Password"
                      @keyup.enter="onCheckUser()">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div>
                <el-link type="info" @click="onDialog">{{
                  $t('LoginPage.content.btnPwInit')
                }}</el-link>
              </div>
            </el-form>
          </div>
          <!-- //입력영역 -->
          <ul>
            <li class="pt10">
              <el-button type="" @click="onCheckUser">{{
                $t('LoginPage.content.btnLogin')
              }}</el-button>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <!-- Modal Popup 비밀번호 초기화 팝업-->
    <el-dialog :title="$t('LoginPage.content.btnPwInit')" v-model="dialogResetVisible" width="840px" destroy-on-close center>
      <div class="pop_content">
        <!-- 입력영역 -->
        <div class="input_group col_item02">
          <el-form ref="resetPwd" :model="IUDObj" :rules="rules" :inline="true" class="demo-form-inline">
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('SysUserMgmtPage.popup.content.userId')" required class="flex_align03"
                  prop="userId">
                  <el-input v-model="IUDObj.userId" placeholder=""></el-input>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('SysUserMgmtPage.popup.content.userEmail')" prop="userEmail">
                  <el-input v-model="IUDObj.userEmail" placeholder=""></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="btm_btn_area">
            <ul class="btn_list_r">
              <li>
                <el-button @click="doSaveRow('resetPwd')" class="b_save">{{
                  $t('Common.button.btnReset')
                }}</el-button>
              </li>
            </ul>
          </div>
        </span>
      </template>
    </el-dialog>

    <!-- Modal Popup 2차 인증 팝업-->
    <el-dialog :title="$t('LoginPage.content.OTP')" v-model="dialogOTPVisible" width="420px" destroy-on-close center>
      <div class="pop_content">
        <!-- 입력영역 -->
        <div class="input_group col_item02">
          <el-form ref="OTP" :model="IUDObj" :inline="true" class="demo-form-inline">
            <el-row >
              <el-col :span="24">
                <el-form-item :label="$t('SysUserMgmtPage.popup.content.userId')" required class="flex_align03"
                  prop="userId">
                  <el-input readonly v-model="IUDObj.userId" placeholder=""></el-input>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row v-show="false">
              <el-col :span="24">
                <el-form-item :label="$t('SysUserMgmtPage.popup.content.userEmail')" prop="userEmail">
                  <el-input readonly v-model="IUDObj.userEmail" placeholder=""></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('SysUserMgmtPage.popup.content.OTP')" prop="otp">
                  <el-input v-model="IUDObj.otp" placeholder=""></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="btm_btn_area">
            <ul class="btn_list_r">
              <li>
                <el-button @click="onOTPMailSend" class="b_save">{{
                  $t('Common.button.mailsend')
                }}</el-button>
              </li>
              <li>
                <el-button @click="onOTPConfirm" class="b_save">{{
                  $t('Common.button.confirm')
                }}</el-button>
              </li>
            </ul>
        </div>
        </span>
      </template>
    </el-dialog>

    <div class="login_footer">
      {{ $t('LoginPage.content.copyright') }}
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import loginAuthService from '@/api/common/login-auth-service'
const loginAuthStore = 'loginAuthStore'

export default {
  name: 'LoginPage',
  components: {},

  data() {
    return {
      checked1: '',
      // 모달 창 관련
      dialogResetVisible: false,
      dialogOTPVisible: false,

      frmLoginInfo: {
        userId: '',
        userPwd: '',
        compId: 100
      },

      IUDObj: {
        userId: '',
        userEmail: '',
        otp: ''
      },
      rules: {
        userId: [
          {
            required: true,
            message: 'Please input User ID'
          }
        ],
        userEmail: [
          {
            required: true,
            message: 'Please input User email'
          }
        ]
      }
    }
  },
  created() {
    this.initStore()
  },
  mounted() { },

  computed: {},

  methods: {
    ...mapActions(loginAuthStore, ['login', 'initStore', 'me']),

    onCheckUser() {
      const loginInfo = this.frmLoginInfo
      console.log('LoginPage > onLogin > checkInfo => ', loginInfo)

      loginAuthService
        .userCheck(loginInfo)  
        .then((res) => {
          this.IUDObj.userId = this.frmLoginInfo.userId
          this.IUDObj.userEmail = res.data.userEmail
          if (res.data.userAuthCd === 'LGL-CHK') {
            this.onLogin()  
          } else if (!this.IUDObj.userEmail || this.IUDObj.userEmail === '') {
            alert(this.$t('LoginPage.popup.emptyEmail'))
          } else {
            this.dialogOTPVisible = true
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          alert(err.svrMessage)
        }) 
    },

    // OTP 메일 전송
    onOTPMailSend() {
      console.log('LoginPage > onOTPMailSend')
      const OTPInfo = this.IUDObj

      loginAuthService
        .userOTPMailsend(OTPInfo)  
        .then((res) => {
          alert(this.$t('LoginPage.popup.OTPMailSend'))
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          alert(err.svrMessage)
        })
    },

    onOTPConfirm() {
      console.log('LoginPage > onOTPConfirm')
      const OTPInfo = this.IUDObj

      if (OTPInfo.otp === '' || OTPInfo.otp == null) {
        alert(this.$t('LoginPage.popup.emptyOTP'))
        return
      }

      loginAuthService
        .userOTPConfirm(OTPInfo)  
        .then((res) => {
          this.onLogin()  
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          alert(err.svrMessage)
        }) 
    },

    onLogin() {
      const loginInfo = this.frmLoginInfo
      console.log('LoginPage > onLogin > loginInfo => ', loginInfo)

      this.login(loginInfo)
        .then((res) => {
          console.log('===>>> LOGIN SUCCESS!!! <<<===')
          this.$router.push('/home')
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          // err객체는 하부 속성까지 정확하게 지정해야 표시가 됨
          console.log(
            '$$$ LoginPage > onLogin > catch err, err.svrCode, err.svrMessage :',
            err,
            err.httpStatus,
            err.svrCode,
            err.svrMessage
          )
          // 로그인 에러
          if (err.httpStatus === 401) {
            if (err.svrCode === 701) {
              // 사용자 id가 존재하지 않음
              this.initformLogin()
            }
          }
        })
    },
    initformLogin() {
      this.frmLoginInfo.userId = ''
      this.frmLoginInfo.userPwd = ''
      this.$refs.loginId.focus()
    },
    onDialog() {
      this.IUDObj.userId = this.frmLoginInfo.userId
      this.IUDObj.userEmail = ''
      this.dialogResetVisible = true
    },
    // 저장 버튼 클릭
    doSaveRow(formEl) {
      this.$refs[formEl].validate((valid, fields) => {
        // 기본 validation 검증 실패 시
        if (!valid) {
          console.log(
            'SysUserMgmtPage > doSaverow() > validation error!!! fields : ',
            fields
          )
          return false
        }

        // Insert 경우 비밀번호 필수 입력 검증, Update시 존재하면 검증

        if (!confirm(this.$t('Common.msg.reset'))) {
          this.dialogResetVisible = false
          return
        }

        // IUD컬럼을 실제 객체의 컬럼명으로 변환
        const {
          userId,
          userEmail
        } = this.IUDObj

        // IU의 공통 항목 추출
        const payload = {
          userId,
          userEmail
        }

        // payload에 PK항목 추가
        payload.userId = userId

        loginAuthService
          .resetUserPwd(payload)
          .then((res) => {
            console.log('===>>> CHANGE PASSWORD SUCCESS!!! <<<===')
            this.dialogResetVisible = false
            alert(this.$t('LoginPage.popup.ReissuedPwd'))
          })
          // eslint-disable-next-line handle-callback-err
          .catch((err) => {
            // err객체는 하부 속성까지 정확하게 지정해야 표시가 됨
            console.log(
              '$$$ LoginPage > onChangePassword > catch err, err.svrCode, err.svrMessage :',
              err,
              err.httpStatus,
              err.svrCode,
              err.svrMessage
            )
            // 로그인 에러
            // alert(err.svrMessage)
          })
      })
    }
  }
}
</script>
<style coped>
#app>div {
  display: block;
}

.login_wrap {
  position: relative;
  height: 100vh;
  background: #fff url('~@/assets/img/login_bg.jpg') no-repeat center center;
  width: 100%;
}

.login_in {
  position: absolute;
  width: 876px;
  height: 350px;
  top: 50%;
  left: 50%;
  margin-left: -438px;
  margin-top: -190px;
  box-shadow: 5px 5px 5px #00000024;
}

.login_in>div {
  width: 50%;
}

.login_in .tit_b {
  background: rgba(50, 50, 50, 0.9);
}

.login_box .input_group .el-row {
  border: 0;
}

.login_in .right_b {
  background: #fff;
}

.login_in .tit_b h1 {
  width: 276px;
  height: 78px;
  text-indent: -9999px;
  background: url('~@/assets/img/login_h1_02.png') no-repeat center center;
  margin-top: -10px;
}

.login_in .tit_b p {
  color: #999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: -4px;
}

.login_box {
  width: 300px;
}

.login_box h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: -0.5px;
}

.login_box .el-col {
  display: flex;
  justify-content: space-between;
  border: 1px solid #bbb;
}

.login_box .input_group,
.login_box .login_box {
  border: 0;
}

.login_box .input_group .el-form-item__label {
  background: none;
  border: 0;
}

.login_box input[type='text'],
.login_box input[type='password'] {
  height: 30px;
  border: 0 none !important;
  color: #222;
  font-size: 14px;
  font-weight: 500;
}

.login_box .input_b {
  display: flex;
  justify-content: space-between;
  border: 1px solid #afafaf;
}

.login_box .input_group .el-form-item__label,
.login_box .input_b label {
  width: 110px;
  display: inline-block;
  line-height: 26px;
  padding-left: 16px;
  font-weight: 600;
  font-size: 14px;
  color: #555;
  text-align: left;
}

.login_box .input_group {
  margin: 8px 0 5px 0;
}

.input_box+.input_box {
  margin-top: 6px;
}

.login_box .input_group .el-form-item__content {
  padding: 2px;
}

.txt_btn_b .check_b label {
  font-weight: 500;
  color: #222;
}

.txt_btn_b a {
  display: flex;
  line-height: 26px;
  color: #333 !important;
  position: relative;
  justify-content: flex-end;
  font-size: 14px;
}

.txt_btn_b a:hover {
  color: #111;
}

.txt_btn_b a::before {
  content: '';
  width: 24px;
  height: 12px;
  margin-right: 3px;
  opacity: 0.7;

  background: url('~@/assets/img/icon_arrow.svg') no-repeat right center;
}

.txt_btn_b a:hover::before {
  /*background: url('~@/assets/img/icon_login_search_o.svg') no-repeat center
    center;
  background: url('~@/assets/img/icon_pw_o.svg') no-repeat center center;*/
  opacity: 0.82;
}

.login_box button {
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #ed1b23;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.login_box button:hover {
  background: #ed1b23;
  color: #fff;
  font-weight: 500;
  opacity: 0.84;
  box-shadow: 4px 4px 4px #00000020;
}

.login_footer {
  color: #fff;
  font-size: 12px;
  width: 100%;
  text-align: center;
  position: absolute;
  margin-top: 190px;
  top: 50%;
  font-weight: 500;
  text-shadow: 4px 2px 2px #444;
}
</style>
