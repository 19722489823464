// import Vue from 'vue'
import { createI18n } from 'vue-i18n/index'
import { koKR, enUS } from './locale'

// Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE, // set locale(default)
  // locale: 'ko_KR', // default
  fallbackLocale: 'en_US', // set fallback locale
  messages: {
    ko_KR: koKR,
    en_US: enUS
  }
})

export default i18n
