import bseFormService from '@/api/services/base/bse-form-serivce'
import commonService from '@/api/services/cmm/common-service'

const FETCH_INC_INFO_LIST = 'FETCH_INC_INFO_LIST' // 손익 항목
const FETCH_INC_INFO = 'FETCH_INC_INFO'
const FETCH_INC_DETAIL_LIST = 'FETCH_INC_DETAIL_LIST' // 손익 항목 상세
const FETCH_INC_DETAIL = 'FETCH_INC_DETAIL'
const FETCH_INC_ITM_GRP1_LIST = 'FETCH_INC_ITM_GRP1_LIST' // 손익 항목 그룹1
const FETCH_INC_ITM_GRP1 = 'FETCH_INC_ITM_GRP1'
const FETCH_TYPES_LIST = 'FETCH_TYPES_LIST' // 유형
const FETCH_TYPES = 'FETCH_TYPES'
const FETCH_CLASS01_LIST = 'FETCH_CLASS01_LIST' // 구분1 (모달팝업)
const FETCH_CLASS01 = 'FETCH_CLASS01'
const FETCH_CLASS02_LIST = 'FETCH_CLASS02_LIST' // 구분2
const FETCH_CLASS02 = 'FETCH_CLASS02'
const FETCH_CLASS03_LIST = 'FETCH_CLASS03_LIST' // 구분3
const FETCH_CLASS03 = 'FETCH_CLASS03'
const FETCH_GRP1_LIST = 'FETCH_GRP1_LIST' // 그룹1
const FETCH_GRP1 = 'FETCH_GRP1'
const FETCH_CLASS01_LIST_SEARCH = 'FETCH_CLASS01_LIST_SEARCH' // 구분1 (검색조건)
const FETCH_CLASS01_SEARCH = 'FETCH_CLASS01_SEARCH'

const bseFormMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 손익항목
    incInfos: [],
    incInfo: {
      // aggYn:"Y"
      // compId:100
      // delYn:"Y"
      // incItm1:"매출액"
      // incItm2:"기존"
      // incItm3:null
      // incItmId:101
      // itmNm:"매출액"
      // lvCl:1
      // maxViewSeq:56
      // viewSeq:2
      // wrkFrml1:"1022"
      // wrkFrml2:"101"
    },
    // 손익항목 상세
    incDetails: [],
    incDetail: {
      // compId:100
      // delYn:"Y"
      // expYn:"Y"
      // grp1Id:1000002
      // grp1Nm:"신사업"
      // incCl1Id:1000024
      // incCl1Nm:"매출원가"
      // incCl2Id:1000036
      // incCl2Nm:"감가상각비"
      // incCl3Id:1000045
      // incCl3Nm:"임차료(창고)"
      // incItmDetId:100
      // incItmId:112
      // incTypId:1000021
      // incTypNm:"영업실적"
      // rvnYn:"N"
    },
    // 손익 항목 그룹1
    incItmGrp1List: [],
    incItmGrp1: {},
    // 유형
    types: [],
    type: {
      // codeType:"M101"
      // itemCd:"1"
      // label:"영업실적"
      // labelEng:"영업실적"
      // value:1000021
    },

    // 구분 1
    class01List: [],
    class01: {
      // codeType:"M102"
      // itemCd:"1"
      // label:"매출액"
      // labelEng:"매출액"
      // value:1000023
    },
    // 구분 2
    class02List: [],
    class02: {
      // codeType:"M103"
      // itemCd:"3"
      // label:"인건비"
      // labelEng:"인건비"
      // value:1000031
    },
    // 구분 3
    class03List: [],
    class03: {
      // codeType:"M104"
      // itemCd:"2"
      // label:"상여"
      // labelEng:"상여"
      // value:1000039
    },

    // 그룹
    grp1List: [],
    grp1Id: {
      // codeType:"GRP01"
      // itemCd:"2"
      // label:"협력"
      // labelEng:"협력"
      // value:1000001
    },

    // 구분 1 (검색조건)
    searchIncCl1List: [],
    selIncCl1Id: {}
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_INC_INFO_LIST](state, incInfos) {
      state.incInfos = incInfos
    },
    [FETCH_INC_INFO](state, incInfo) {
      state.incInfo = incInfo
    },
    [FETCH_INC_DETAIL_LIST](state, incDetails) {
      state.incDetails = incDetails
    },
    [FETCH_INC_DETAIL](state, incDetail) {
      state.incDetail = incDetail
    },
    [FETCH_INC_ITM_GRP1_LIST](state, incItmGrp1List) {
      state.incItmGrp1List = incItmGrp1List
    },
    [FETCH_INC_ITM_GRP1](state, incItmGrp1) {
      state.incItmGrp1 = incItmGrp1
    },
    [FETCH_TYPES_LIST](state, types) {
      types.shift()
      state.types = types
    },
    [FETCH_TYPES](state, type) {
      state.type = type
    },
    [FETCH_CLASS01_LIST](state, class01List) {
      class01List.shift()
      state.class01List = class01List
    },
    [FETCH_CLASS01](state, class01) {
      state.class01 = class01
    },
    [FETCH_CLASS02_LIST](state, class02List) {
      class02List.shift()
      state.class02List = class02List
    },
    [FETCH_CLASS02](state, class02) {
      state.class02 = class02
    },
    [FETCH_CLASS03_LIST](state, class03List) {
      class03List.shift()
      state.class03List = class03List
    },
    [FETCH_CLASS03](state, class03) {
      state.class03 = class03
    },
    [FETCH_GRP1_LIST](state, grp1List) {
      grp1List.shift()
      state.grp1List = grp1List
    },
    [FETCH_GRP1](state, grp1Id) {
      state.grp1Id = grp1Id
    },
    [FETCH_CLASS01_LIST_SEARCH](state, searchIncCl1List) {
      state.searchIncCl1List = searchIncCl1List
    },
    [FETCH_CLASS01_SEARCH](state, selIncCl1Id) {
      state.selIncCl1Id = selIncCl1Id
    }
  },

  actions: {
    fetchIncInfoList({ commit }, queryStr = '') {
      console.log(
        'bseFormMgmtStore > fetchIncInfoList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseFormService
          .getIncInfoList(queryStr)
          .then((res) => {
            commit(FETCH_INC_INFO_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncInfo({ commit }, selectIncInfo) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_INC_INFO, selectIncInfo)
    },
    fetchCopyIncInfos({ commit }, selectIncInfos) {
      // copy incInfoList
      commit(FETCH_INC_INFO_LIST, selectIncInfos)
    },
    clearIncInfo({ commit }) {
      commit(FETCH_INC_INFO_LIST, [])
    },

    fetchIncDetailList({ commit }, queryStr = '') {
      console.log(
        'bseFormMgmtStore > fetchIncDetailList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseFormService
          .getIncDetailList(queryStr)
          .then((res) => {
            commit(FETCH_INC_DETAIL_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncDetail({ commit }, selectIncInfo) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_INC_DETAIL, selectIncInfo)
    },
    clearIncDetail({ commit }) {
      commit(FETCH_INC_DETAIL_LIST, [])
    },

    fetchTypeList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log('bseFormMgmtStore > fetchTypeList > res', res)
            this.codeOpts = res.data
            commit(FETCH_TYPES_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchType({ commit }, type) {
      commit(FETCH_TYPES, type)
    },

    fetchClass01List({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log('bseFormMgmtStore > fetchClass01List > res', res)
            this.codeOpts = res.data
            commit(FETCH_CLASS01_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchClass01({ commit }, selectClass01) {
      commit(FETCH_CLASS01, selectClass01)
    },

    fetchClass02List({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log('bseFormMgmtStore > fetchClass02List > res', res)
            this.codeOpts = res.data
            commit(FETCH_CLASS02_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchClass02({ commit }, selectClass02) {
      commit(FETCH_CLASS02, selectClass02)
    },

    fetchClass03List({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log('bseFormMgmtStore > fetchClass03List > res', res)
            this.codeOpts = res.data
            commit(FETCH_CLASS03_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchClass03({ commit }, selectClass03) {
      commit(FETCH_CLASS03, selectClass03)
    },

    fetchGrp1List({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log('bseFormMgmtStore > fetchGrp1List > res', res)
            this.codeOpts = res.data
            commit(FETCH_GRP1_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchGrp1({ commit }, selectGrp1) {
      commit(FETCH_GRP1, selectGrp1)
    },

    fetchIncItmGrp1List({ commit }) {
      return new Promise((resolve, reject) => {
        bseFormService
          .getIncItmGrp1List()
          .then((res) => {
            commit(FETCH_INC_ITM_GRP1_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncItmGrp1({ commit }, selectIncItmGrp1) {
      commit(FETCH_INC_ITM_GRP1, selectIncItmGrp1)
    },

    fetchClass01ListSearch({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            console.log('bseFormMgmtStore > fetchClass01ListSearch > res', res)
            this.codeOpts = res.data
            commit(FETCH_CLASS01_LIST_SEARCH, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchClass01Search({ commit }, selectClass01) {
      commit(FETCH_CLASS01_SEARCH, selectClass01)
    }
  }
}

export default bseFormMgmtStore
