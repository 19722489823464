import bseBoCustMgmtService from '@/api/services/base/bse-bo-cust-mgmt-service'

const FETCH_BO_CUST_LIST = 'FETCH_BO_CUST_LIST' // 거래처
const FETCH_BO_CUST = 'FETCH_BO_CUST'

const bseBoCustMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 거래처
    boCusts: [],
    boCust: {
      // boCustCd:"DEFAULT"
      // boId:1000001
      // custNm:"중국법인"
      // custNmEng:"중국법인"
      // custSnm:"중국법인"
      // custSnmEng:"중국법인"
      // delYn:"N"
    }
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_BO_CUST_LIST](state, boCusts) {
      state.boCusts = boCusts
    },
    [FETCH_BO_CUST](state, boCust) {
      state.boCust = boCust
    }
  },

  actions: {
    fetchBoCustList({ commit }, queryStr = '') {
      console.log(
        'bseBoCustMgmtStore > fetchBoCustList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseBoCustMgmtService
          .getBoCustMgmtList(queryStr)
          .then((res) => {
            commit(FETCH_BO_CUST_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchBoCust({ commit }, selectBoCust) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_BO_CUST, selectBoCust)
    },
    fetchBoCusts({ commit }, selectBoCusts) {
      // copy BoCustList
      commit(FETCH_BO_CUST_LIST, selectBoCusts)
    },
    clearBoCust({ commit }) {
      commit(FETCH_BO_CUST_LIST, [])
    }
  }
}

export default bseBoCustMgmtStore
