// import InfFacilityMgmtPage from '@/views/infra/InfFacilityMgmtPage.vue';

export default [
  {
    // === 부동산현황 관리 Page ===
    path: '/inf-facility-mgmt',
    name: 'InfFacilityMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Infra" */ '@/views/infra/InfFacilityMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // === 장비현황 관리 Page ===
  {
    path: '/inf-equipment-mgmt',
    name: 'InfEquipmentMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Infra" */ '@/views/infra/InfEquipmentMgmtPage.vue'
      ),
    meta: { auth: true }
  },
  // === 인원현황 관리 Page ===
  {
    path: '/inf-employee-mgmt',
    name: 'InfEmployeeMgmtPage',
    component: () =>
      import(
        /* webpackChunkName: "Infra" */ '@/views/infra/InfEmployeeMgmtPage.vue'
      ),
    meta: { auth: true }
  }
]
