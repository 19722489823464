import incCurrMonPerfService from '@/api/services/income/inc-curr-mon-perf-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_INC_CURR_MON_PERF_LIST = 'FETCH_INC_CURR_MON_PERF_LIST'

// 확정구분 공통코드 리스트
const FETCH_DEF_LIST = 'FETCH_DEF_LIST'
// 화폐구분 공통코드 리스트
const FETCH_UNTDP_LIST = 'FETCH_UNTDP_LIST'
// 항목리스트
const FETCH_ITEM_INFO_LIST = 'FETCH_ITEM_INFO_LIST'

const incCurrMonPerfStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    // 당월실적 리스트
    incCurrMonPerfs: [],

    incCurrMonPerf: {
      // boId: 999,
      // IncYy: '2022',
      // crncyCd: '',
      // cntryCd: '',
    },
    // 확정구분
    defCls: [],
    // 화폐단위
    untDps: [],
    // 항목리스트
    itemInfos: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_INC_CURR_MON_PERF_LIST](state, incCurrMonPerfs) {
      state.incCurrMonPerfs = incCurrMonPerfs
    },

    [FETCH_DEF_LIST](state, defCls) {
      state.defCls = defCls
    },
    [FETCH_UNTDP_LIST](state, untDps) {
      state.untDps = untDps
    },

    [FETCH_ITEM_INFO_LIST](state, itemInfos) {
      state.itemInfos = itemInfos
    }
  },

  actions: {
    // 확정구분 공통코드 조회
    fetchDefClList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            commit(FETCH_DEF_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 화폐단위 조회
    fetchUntDpList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            res.data.shift()
            const delIdx = res.data.findIndex((item) => item.itemCd === '10')
            res.data.splice(delIdx, 1)
            commit(FETCH_UNTDP_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 조회용 항목리스트
    fetchItemInfoList({ commit }) {
      return new Promise((resolve, reject) => {
        incCurrMonPerfService
          .getIncItemInfoList()
          .then((res) => {
            res.data.unshift({ incItmId: '', itmNm: '---' })
            commit(FETCH_ITEM_INFO_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 당월 실적 리스트
    fetchIncCurrMonPerfList({ commit }, { queryStr = '', plnRetCl }) {
      return new Promise((resolve, reject) => {
        incCurrMonPerfService
          .getIncCurrMonPerfList(queryStr)
          .then((res) => {
            commit(FETCH_INC_CURR_MON_PERF_LIST, res.data || [])

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearIncCurrMonPerfs({ commit }) {
      commit(FETCH_INC_CURR_MON_PERF_LIST, [])
    }
  }
}

export default incCurrMonPerfStore
