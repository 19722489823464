const routes = [
  {
    path: '/template/listtodetail',
    name: 'ListToDetail',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p3/ListToDetail.vue'
      )
  },
  {
    path: '/template/detail',
    name: 'Detail',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p3/Detail.vue'
      )
  },
  {
    path: '/template/detailedit',
    name: 'DetailEdit',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p3/DetailEdit.vue'
      )
  },
  {
    path: '/template/multipleedit',
    name: 'MultipleEdit',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p2/MultipleEdit.vue'
      )
  },
  {
    path: '/template/masterdetail',
    name: 'MasterDetail',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p4/MasterDetail.vue'
      )
  },
  {
    path: '/template/masterdetail2',
    name: 'MasterDetailModal',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p4/MasterDetailModal.vue'
      )
  },
  {
    path: '/template/shuttle',
    name: 'Shuttle',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p5/Shuttle.vue'
      )
  },
  {
    path: '/template/serverpaging',
    name: 'ServerPaging',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/ServerPaging.vue'
      )
  },
  {
    path: '/template/singleviewedit',
    name: 'SingleViewEdit',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p1/SingleViewEdit.vue'
      )
  },
  {
    path: '/template/create',
    name: 'Create',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/uitemplate/p3/Create.vue'
      )
  }
]

export default routes
