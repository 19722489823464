const routes = [
  {
    path: '/file/excel',
    name: 'ExcelUpload1',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/file/ExcelUpload1.vue'
      )
  },
  {
    path: '/file/image',
    name: 'ImageUpload',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/file/ImageUpload.vue'
      )
  },
  {
    path: '/file/file',
    name: 'FileUpload',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/file/FileUpload.vue'
      )
  }
]

export default routes
