import commonService from '@/api/services/cmm/common-service'

const FETCH_BO_LIST = 'FETCH_BO_LIST' // 법인리스트
// 리스트용
const SELECT_BO = 'SELECT_BO' // 선택된 법인
const SUB_BO_LIST = 'SUB_BO_LIST' // 지점리스트
const SELECT_SUB_BO = 'SELECT_SUB_BO' // 선택된 지점 리스트

// 등록용
const SUB_BO_LIST2 = 'SUB_BO_LIST2' // 지점리스트
const SELECT_BO2 = 'SELECT_BO2' // 선택된 법인

const commonBoStore = {
  namespaced: true,

  // === state ===
  state: {
    /**
     * 리스트용
     */
    // 법인리스트
    bos: [],
    // 선택된 법인
    bo: {
      boId: '' // 1000001
      // boNm: '상해',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng:
      //   'Shanghai Hyundai Asia Shipping International Freight Forwarding Co.Ltd.'
    },

    // 지점리스트
    subBos: [],
    // 선택된 지점
    subBo: {
      boId: '' // 1000021
      // boNm: '낙척(북경)',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng: '낙천(북경)물류유한공사'
    },

    /**
     * 등록용
     */
    // 지점리스트2
    subBos2: [],
    bo2: {
      boId: '' // 1000001
      // boNm: '상해',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng:
      //   'Shanghai Hyundai Asia Shipping International Freight Forwarding Co.Ltd.'
    }
  },

  getters: {
    // -
  },

  mutations: {
    // 법인리스트
    [FETCH_BO_LIST](state, payload) {
      state.bos = payload
    },
    // 선택된 법인
    [SELECT_BO](state, bo) {
      state.bo = bo
    },
    // 지점리스트
    [SUB_BO_LIST](state, subBos) {
      state.subBos = subBos
    },
    // 선택된지점
    [SELECT_SUB_BO](state, subBo) {
      state.subBo = subBo
    },
    // 지점리스트2
    [SUB_BO_LIST2](state, subBos2) {
      state.subBos2 = subBos2
    },
    [SELECT_BO2](state, bo2) {
      state.bo2 = bo2
    }
  },

  actions: {
    // 법인리스트를 가지고 온다.
    fetchBoList({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        commonService
          .getBoList()
          .then((res) => {
            const tmpBo = {
              boId: '',
              boNm: 'ALL',
              subBos: [{ boId: '', boNm: '' }]
            }
            res.data.unshift(tmpBo)

            commit(FETCH_BO_LIST, res.data)
            // 디폴트로 첫번째 법인 선택
            // commit(SELECT_BO, JSON.parse(JSON.stringify(state.bos[1])))
            // commit(
            //   SUB_BO_LIST,
            //   JSON.parse(JSON.stringify(state.bos[1].subBos)) || [{ boId: '' }]
            // )
            // commit(
            //   SELECT_SUB_BO,
            //   JSON.parse(JSON.stringify(state.subBos[0])) || { boId: '' }
            // )
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 법인선택시
    // @param selBoId : 선택된 법인 ID
    selectBo({ commit, state }, selBoId) {
      // 특정 법인을 선택했을 경우 해당 법인의 지점 선택
      let selbo = { boId: '', boNm: 'ALL', subBos: [] }
      if (selBoId) {
        const tbos = state.bos.find((bo) => bo.boId === selBoId)
        selbo = JSON.parse(JSON.stringify(tbos))
      }
      commit(SELECT_BO, selbo)

      selbo.subBos.unshift({ boId: '', boNm: 'ALL' })
      commit(SUB_BO_LIST, selbo.subBos || [{ boId: '', boNm: 'ALL' }])
      commit(
        SELECT_SUB_BO,
        JSON.parse(JSON.stringify(selbo.subBos[0] || { boId: '' }))
      )
    },

    // 지점 선택시
    // @param selSubBoId : 선택된 지점ID
    selectSubBo({ commit, state }, selSubBoId) {
      // 특정 지점을 선택했을 경우
      let selSubBo = { boId: '', boNm: '' }
      if (selSubBoId) {
        selSubBo = JSON.parse(
          JSON.stringify(
            state.subBos.find((subBo) => subBo.boId === selSubBoId)
          )
        )
      }

      commit(SELECT_SUB_BO, selSubBo || { boId: '', boNm: 'ALL' })
    },

    /** *** 등록용 ***********/
    /************************
    /**
     * 법인 선택시
     * @param {*} param0
     * @param {*} selBoId : 선택된 법인 ID
     */
    selectBo2({ commit, state }, selBoId) {
      // 특정 법인을 선택했을 경우 해당 법인의 지점 선택
      let selbo = {}
      if (selBoId) {
        const srhBo = state.bos.find((bo2) => bo2.boId === selBoId)
        if (srhBo != null && srhBo !== '') {
          selbo = JSON.parse(JSON.stringify(srhBo))
        }
      }
      commit(SUB_BO_LIST2, selbo.subBos || [{ boId: '' }])
      commit(SELECT_BO2, selbo)
    }
  }
}

export default commonBoStore
