import { axiosApi } from '@/api'
// import commonUtils from '@/utils/common-utils'

export default {
  // 손익집계 리스트 조회
  getIncMonAggrList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/mon-aggrs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
