import bseCloseMgmtService from '@/api/services/base/bse-close-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_CLOSE_LIST = 'FETCH_CLOSE_LIST' // 마감 목록
const FETCH_CLOSE = 'FETCH_CLOSE'
const FETCH_BO_LIST = 'FETCH_BO_LIST' // 법인리스트
const SELECT_BO = 'SELECT_BO'
const FETCH_PGM_LIST = 'FETCH_PGM_LIST' // 마감 프로그램(유형)
const FETCH_PGM = 'FETCH_PGM'

const bseCloseMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 마감 리스트
    closes: [],
    cls: {
      // boId:1000001
      // boNm:"중국법인"
      // clsNoQ1:"01"
      // clsNoQ2:"02"
      // clsPgm:"BOINC002"
      // clsPgmNm:"법인 수익/비용 등록(계획)"
      // mon01Q1:"N"   mon01Q2:"N"
      // mon02Q1:"Y"   mon02Q2:"N"
      // mon03Q1:"Y"   mon03Q2:"Y"
      // mon04Q1:"Y"   mon04Q2:"N"
      // mon05Q1:"Y"   mon05Q2:"N"
      // mon06Q1:"Y"   mon06Q2:"Y"
      // mon07Q1:"Y"   mon07Q2:"N"
      // mon08Q1:"Y"   mon08Q2:"N"
      // mon09Q1:"Y"   mon09Q2:"Y"
      // mon10Q1:"Y"   mon10Q2:"N"
      // mon11Q1:"Y"   mon11Q2:"N"
      // mon12Q1:"Y"   mon12Q2:"Y"
      // subBoNm:""
      // trrt:"1000102"
      // trrtNm:"중국지역"
      // yy:"2021"
    },
    // 법인리스트
    bos: [],
    // 선택된 법인
    bo: {
      boId: '' // 1000001
      // boNm: '상해',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng:
      //   'Shanghai Hyundai Asia Shipping International Freight Forwarding Co.Ltd.'
    },
    pgms: [],
    pgm: {
      //   codeType:"CLS01"
      //   itemCd:"1"
      //   label:"법인 매출 등록(계획)"
      //   labelEng:"법인 매출 등록(계획)"
      //   value:1000048
    }
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_CLOSE_LIST](state, closes) {
      state.closes = closes
    },
    [FETCH_CLOSE](state, cls) {
      state.cls = cls
    },
    // 법인리스트
    [FETCH_BO_LIST](state, payload) {
      state.bos = payload
    },
    // 선택된 법인
    [SELECT_BO](state, bo) {
      state.bo = bo
    },
    [FETCH_PGM_LIST](state, pgms) {
      pgms.shift()
      state.pgms = pgms
    },
    [FETCH_PGM](state, pgm) {
      state.pgm = pgm
    }
  },

  actions: {
    fetchBoCloseList({ commit }, queryStr = '') {
      console.log(
        'bseBoCustMgmtStore > fetchBoClsList > qureystr ==>',
        queryStr
      )
      return new Promise((resolve, reject) => {
        bseCloseMgmtService
          .getCloseList(queryStr)
          .then((res) => {
            console.log(res.data)
            commit(FETCH_CLOSE_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchBoClose({ commit }, selectBoClose) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_CLOSE, selectBoClose)
    },
    clearBoClose({ commit }) {
      commit(FETCH_CLOSE_LIST, [])
    },
    // 법인리스트를 가지고 온다.
    fetchBoList({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        bseCloseMgmtService
          .getBoList()
          .then((res) => {
            const tmpBo = {
              boId: '',
              boNm: 'ALL',
              subBos: [{ boId: '', boNm: '' }]
            }
            res.data.unshift(tmpBo)
            commit(FETCH_BO_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 법인선택시
    // @param selBoId : 선택된 법인 ID
    selectBo({ commit, state }, selBoId) {
      // 특정 법인을 선택했을 경우
      // commit(SELECT_BO, selBoId)

      let selbo = { boId: '', boNm: 'ALL', subBos: [] }
      if (selBoId) {
        const tbos = state.bos.find((bo) => bo.boId === selBoId)
        selbo = JSON.parse(JSON.stringify(tbos))
        console.log('store > tbos', tbos)
      }

      console.log('stroe > selBo', selBoId)
      console.log('store > selbo', selbo)

      commit(SELECT_BO, selbo)
    },

    // 마감 프로그램(유형) 리스트
    fetchPgmList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            this.codeOpts = res.data
            commit(FETCH_PGM_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchPgm({ commit }, selectPgm) {
      // 특정 유형을 선택했을 때
      commit(FETCH_PGM, selectPgm)
    }
  }
}

export default bseCloseMgmtStore
