import { axiosApi } from '@/api'

export default {
  // 거래처 마스터 리스트 조회
  getBoCustMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/bo-custs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 거래처 마스터 추가
  addBoCustMgmt(payload) {
    // console.log('bseBoCustMgmtService > addBoCustMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/bo-custs', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 거래처 마스터 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateBoCustMgmt(id, cd, payload) {
    // console.log(
    //   'bseBoCustMgmtService > updateBranchOfficeMgmt() > payload',
    //   payload
    // )

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/bse/bo-custs/${id}/${cd}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 거래처 마스터 삭제
  deleteBoCustMgmt(id, cd) {
    // console.log('bseBoCustMgmtService > deleteBoCustMgmt() > boId :', id)
    // console.log('bseBoCustMgmtService > deleteBoCustMgmt() > boCustCd :', cd)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/bse/bo-custs/${id}/${cd}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 거래처코드 중복체크
  checkBoCustCd(findParams = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/bo-custs/check${findParams}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
