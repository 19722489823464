import { axiosApi } from '@/api'

export default {
  // 부동산 현황 관리 리스트 조회
  getFacilityMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inf/facilities${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 부동산 현황 관리 추가
  createFacilityMgmt(payload) {
    // console.log('infFacilityService.createFacilityMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inf/facilities', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 부동산 현황 관리 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateFacilityMgmt(id, payload) {
    // console.log('infFacilityService.updateFacilityMgmt() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/inf/facilities/${id}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 부동산 현황 관리 삭제
  deleteFacilityMgmt(id) {
    // console.log('infFacilityService.deleteFacilityMgmt() > boRntId :', id)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/inf/facilities/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
