import { axiosApi } from '@/api'

export default {
  // 양식관리 조회 (손익 항목)
  getIncInfoList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/form-inc-itm-info${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 추가
  addIncInfo(payload) {
    // console.log('bseFormService.addIncInfo() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/form-inc-itm-info', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateIncInfo(id, payload) {
    // console.log('bseFormService.updateIncInfo() > id', id)
    // console.log('bseFormService.updateIncInfo() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/bse/form-inc-itm-info/${id}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 삭제
  deleteIncInfo(id) {
    // console.log('bseFormService.deleteIncInfo() > incItmId :', id)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/bse/form-inc-itm-info/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 양식 관리 조회 (손익 항목 상세)
  getIncDetailList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/form-inc-itm-detail${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 상세 추가
  addIncDetail(payload) {
    console.log('bseFormService.addIncIncDetail() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/form-inc-itm-detail', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 상세 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateIncDetail(id, payload) {
    console.log('bseFormService.updateIncDetail() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/bse/form-inc-itm-detail/${id}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 상세 삭제
  deleteIncDetail(id) {
    console.log('bseFormService.deleteIncDetail() > incItmDetId :', id)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/bse/form-inc-itm-detail/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 항목 그룹 1
  getIncItmGrp1List() {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/bse/form-inc-itm-info/grp1')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
