import { axiosApi } from '@/api'

export default {
  // 사용자 리스트 조회
  getUserList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/v1/users${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 추가
  createUser(payload) {
    console.log('templateService.createUser() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/v1/users/', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateUser(userId, payload) {
    // console.log('templateService.updateUser() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/v1/users/${userId}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user 삭제
  deleteUser(userId) {
    console.log('sampleService.deleteUser() > userId :', userId)

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/v1/users/${userId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // === 게시판 관리(참조용)===
  // 삭제 필요
  // 게시글 리스트 조회
  getPostList() {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/posts/')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 게시글 리스트 조회 by user id
  getPostListByUser(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/posts${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 게시물 조회
  getPost(postId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/posts/${postId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 댓글 조회 by user id
  getCommentListByUser(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/comments${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 게시물 삭제
  deletePost(id = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/posts/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 게시물 수정
  updatePost(postId, payload) {
    const { title, contents } = payload
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/posts/${postId}`, { title, contents })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 게시물 생성
  createPost(payload) {
    const { title, contents } = payload
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/posts', { title, contents })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
