import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 미수채권 정보 조회 (현대 상선 포함)
  getIncludingMarineList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/accounts-receivable/including${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 미수채권 정보 조회 (현대 상선 제외)
  getExcludingMarineList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/accounts-receivable/excluding${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 미수채권 정보 (현대 상선 포함) 엑셀 업로드
  includingUploadExcel(files, arYymm, incclCd, sheetNum) {
    return commonUtils.$upload(
      `fin/accounts-receivable/xls/including/${arYymm}/${incclCd}/${sheetNum}`,
      files[0]
    )
  },

  // 미수채권 정보 (현대 상선 제외) 엑셀 업로드
  excludingUploadExcel(files, arYymm, incclCd, sheetNum) {
    return commonUtils.$upload(
      `fin/accounts-receivable/xls/excluding/${arYymm}/${incclCd}/${sheetNum}`,
      files[0]
    )
  },

  // 미수채권 정보 삭제
  deleteSelectRow(yymm, id, cd) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/fin/accounts-receivable/${yymm}/${id}/${cd}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 미수채권 증감 저장 (상선 제외)
  doSaveExcludingExcel(exIUDObj) {
    // console.log('finAccountsReceivableService > doSaveExcludingExcel() > payload', exIUDObj)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/fin/accounts-receivable/excluding', exIUDObj)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 미수채권 증감 저장 (상선 포함)
  doSaveIncludingExcel(inIUDObj) {
    // console.log(
    //   'finAccountsReceivableService > doSaveIncludingExcel() > payload',
    //   inIUDObj
    // )

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/fin/accounts-receivable/including', inIUDObj)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
