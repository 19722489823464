// import incPerfInqService from '@/api/services/income/inc-perf-inq-service'
// import commonService from '@/api/services/cmm/common-service'

// const FETCH_INC_RPERF_INQ_LIST = 'FETCH_INC_RPERF_INQ_LIST'
// const FETCH_INC_PPERF_INQ_LIST = 'FETCH_INC_PPERF_INQ_LIST'
// const FETCH_INC_LY_PERF_INQ_LIST = 'FETCH_INC_LY_PERF_INQ_LIST'
// // 확정구분 공통코드 리스트
// const FETCH_DEF_LIST = 'FETCH_DEF_LIST'
// // 화폐구분 공통코드 리스트
// const FETCH_UNTDP_LIST = 'FETCH_UNTDP_LIST'

const finTransHistStore = {
  namespaced: true,

  // // === state ===
  // // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  // state: {
  //   // === for gred sample
  //   // 실적
  //   incRPerfInqs: [],
  //   // 계획
  //   incPPerfInqs: [],
  //   // 전년실적
  //   incLyPerfInqs: [],

  //   incPerfInq: {
  //     // boId: 999,
  //     // IncYy: '2022',
  //     // crncyCd: '',
  //     // cntryCd: '',
  //   },
  //   // 확정구분
  //   defCls: [],
  //   // 화폐단위
  //   untDps: []
  // },

  getters: {
    // -
  },

  mutations: {
    // [FETCH_INC_RPERF_INQ_LIST](state, incRPerfInqs) {
    //   state.incRPerfInqs = incRPerfInqs
    // },
    // [FETCH_INC_PPERF_INQ_LIST](state, incPPerfInqs) {
    //   state.incPPerfInqs = incPPerfInqs
    // },
    // [FETCH_INC_LY_PERF_INQ_LIST](state, incLyPerfInqs) {
    //   state.incLyPerfInqs = incLyPerfInqs
    // },
    // [FETCH_DEF_LIST](state, defCls) {
    //   state.defCls = defCls
    // },
    // [FETCH_UNTDP_LIST](state, untDps) {
    //   state.untDps = untDps
    // }
  },

  actions: {
    // // 확정구분 공통코드 조회
    // fetchDefClList({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     commonService
    //       .getCommonCodeList(payload.codeType, payload.lang)
    //       .then((res) => {
    //         commit(FETCH_DEF_LIST, res.data || [])
    //         resolve(res)
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    // // 화폐단위 조회
    // fetchUntDpList({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     commonService
    //       .getCommonCodeList(payload.codeType, payload.lang)
    //       .then((res) => {
    //         commit(FETCH_UNTDP_LIST, res.data || [])
    //         resolve(res)
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    // // 손익조회 리스트
    // fetchIncPerfInqList({ commit }, { queryStr = '', plnRetCl }) {
    //   console.log('plnRetCl ==>  ', plnRetCl)
    //   return new Promise((resolve, reject) => {
    //     incPerfInqService
    //       .getIncPerfInqList(queryStr)
    //       .then((res) => {
    //         switch (plnRetCl) {
    //           case 'P':
    //             commit(FETCH_INC_PPERF_INQ_LIST, res.data || [])
    //             break
    //           case 'R':
    //             commit(FETCH_INC_RPERF_INQ_LIST, res.data || [])
    //             break
    //           case 'LY':
    //             commit(FETCH_INC_LY_PERF_INQ_LIST, res.data || [])
    //             break
    //           default:
    //             commit(FETCH_INC_PPERF_INQ_LIST, res.data || [])
    //             break
    //         }
    //         resolve(res)
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    // clearIncPerfInqs({ commit }) {
    //   commit(FETCH_INC_PPERF_INQ_LIST, [])
    //   commit(FETCH_INC_RPERF_INQ_LIST, [])
    //   commit(FETCH_INC_LY_PERF_INQ_LIST, [])
    // }
  }
}

export default finTransHistStore
