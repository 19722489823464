import { axiosApi } from '@/api'

export default {
  // --- 코드유형 조회 ---
  getCodeTypeList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/code-types${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 하위 코드 존재 체크(1건)
  checkCodeVal(tccId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/code-types/${tccId}/check`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 코드유형 추가
  createCodeType(payload) {
    // console.log('SysCodeService > createUser() > payload', payload)

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/code-types', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 코드유형 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateCodeType(tccId, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/code-types/${tccId}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 코드유형 삭제
  deleteCodeType(tccId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/code-types/${tccId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // ------------------
  // --- 코드값 조회 ---
  getCodeValList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/code-vals${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 하위 서비스 유형 존재 체크(1건)
  checkSvcType(tccvId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/code-vals/${tccvId}/check`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 코드값 추가
  createCodeVal(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/code-vals', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 코드값 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateCodeVal(tccvId, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/code-vals/${tccvId}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 코드값 삭제
  deleteCodeVal(tccvId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/code-vals/${tccvId}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // ------------------
  // --- 서비스유형 조회 ---
  getSvcTypeList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/sys/svc-types${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 서비스유형 추가
  createSvcType(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/sys/svc-types', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 서비스유형 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateSvcType({ tccvId, svcTyp }, payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/sys/svc-types/${tccvId}/${svcTyp}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 서비스유형 삭제
  deleteSvcType({ tccvId, svcTyp }) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/sys/svc-types/${tccvId}/${svcTyp}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
