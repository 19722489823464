import anlInfoService from '@/api/services/analysis/anl-info-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_CUST_PERF_LIST = 'FETCH_CUST_PERF_LIST'
const SELECT_CUST_PERF = 'SELECT_CUST_PERF'
const FETCH_DEF_CL_CODE_LIST = 'FETCH_DEF_CL_CODE_LIST'
const CLEAR_STORE = 'CLEAR_STORE'
const SET_CUST_TOP10_CATES = 'SET_CUST_TOP10_CATES'
const SET_CUST_TOP10_SERIES = 'SET_CUST_TOP10_SERIES'

const anlCustPerfStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // // 사용자
    custPerfs: [],
    custPerf: {
      // boCustCd: 'C20220103', // 법인 거래처 코드
      // custNm: '청도중한승원무역', // 법인 거래처명
      // rank: 1, // 순위
      // salAmt: 23000866.12, // 매출액
      // salPer: '0.91%', // 매출액 비율(단위문자 및 포맷이 필요해서 문자로 처리)
      // cumsumSalAmt: 23000866.12, // 수익합계 누계
      // cumsumSalAmtPer: '18.63%' // 수익합계 누계율
    },

    // 거래처별 매출 Top10 Chart Series & Data
    custTop10Categories: [
      // '청도중한승원무역',
      // '석달(상해)국제화운',
      // '봉호물류(북경)천진분공사',
      // '한신해운(HMM)',
      // '강소가리달국제물류'
      // 'France',
      // 'Japan',
      // 'United States',
      // 'China',
      // 'India'
    ],
    custTop10Series: [
      // {
      //   data: [
      //     23000866.12, 2012433.21, 125712.0, 12388.0, 12065.0
      //     // , 11000, 10000, 9000, 8000, 7000
      //   ]
      // }
    ],

    defClCodes: [],
    defClCode: {
      // value: 'Q1',
      // label: 'B+8',
      // labelEng: '8일'
    }
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_CUST_PERF_LIST](state, custPerfs) {
      state.custPerfs = custPerfs
    },
    [SELECT_CUST_PERF](state, custPerf) {
      state.custPerf = custPerf
    },
    [FETCH_DEF_CL_CODE_LIST](state, codes) {
      state.defClCodes = codes
    },
    [CLEAR_STORE](state) {
      state.custPerfs = []
      state.custPerf = {}
      state.custTop10Categories = []
      state.custTop10Series = []
    },
    [SET_CUST_TOP10_CATES](state, categories) {
      state.custTop10Categories = categories
    },
    [SET_CUST_TOP10_SERIES](state, series) {
      state.custTop10Series = series
    }
  },

  actions: {
    // // 코드유형 리스트 조회
    fetchCustPerfList({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        anlInfoService
          .getCustPerfList(queryStr)
          .then((res) => {
            const custPerfList = res.data
            commit(FETCH_CUST_PERF_LIST, custPerfList)

            // 상위 10개 거래처 추출 및 그래프 표시용 데이터 생성
            if (custPerfList && custPerfList.length > 0) {
              const custPerfTop10 = custPerfList.slice(0, 10)
              const custTop10Categories = custPerfTop10.map((item) => {
                return item.custNm
              })
              commit(SET_CUST_TOP10_CATES, custTop10Categories)

              const custTop10Series = custPerfTop10.map((item) => {
                return item.salAmt ? Math.round(item.salAmt / 10000) : 0
              })
              commit(SET_CUST_TOP10_SERIES, custTop10Series)
            } else {
              commit(SET_CUST_TOP10_CATES, [])
              commit(SET_CUST_TOP10_SERIES, [])
            }

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 코드 유형 선택
    selectCustPerf({ commit }, custPerf) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_CUST_PERF, custPerf)
    },
    // 공통코드(확정구분) 리스트 조회
    fetchDefClCodeList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            const commCodes = res.data
            if (commCodes && commCodes.length > 0) {
              commCodes.shift()
              commit(FETCH_DEF_CL_CODE_LIST, commCodes)
            }

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default anlCustPerfStore
