import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 재무제표 리스트 조회
  getFinBsSheetList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/bs-sheets${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 재무제표 저장
  saveFinBsSheets(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/fin/bs-sheets', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 엑셀업로드
  uploadExcel(files, frmId, crncyType, sheetNum) {
    return commonUtils.$upload(
      `fin/bs-sheets/xls/${sheetNum}/${frmId}/${crncyType}`,
      files[0]
    )
  }
}
