import { axiosApi } from '@/api'
// import commonUtils from '@/utils/common-utils'

export default {
  // 실적 헤더정보조회
  getIncRet(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/inc-ret${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 실적헤더저장
  saveIncRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/inc-ret', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 본사 지급분 관리(실적) 리스트조회
  getIncHopayRets(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/ret-hopays${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 추가
  saveIncHopayRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/ret-hopays', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 수정
  updateIncHopayMgmts(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .put('/inc/ret-hopays', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 삭제
  deleteIncHopayMgmts(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(
          `/inc/ret-hopays/${payload.boId}/${payload.incYymm}/${payload.defCl}`
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 본사지급분 마감
  procIncCls(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/ret-hopays/cls', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
