import { axiosApi } from '@/api'

export default {
  // 실적 조회
  getIncRetList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/inc-up-ret${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 계획 조회
  getIncPlnList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/inc-up-pln${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
