import { axiosApi } from '@/api'
// import commonUtils from '@/utils/common-utils'

export default {
  // 재무제표 조회
  getBalanSheetList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/curr-status-inq/balance-sheets${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 리스트
  getBoList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/fin/curr-status-inq/bos')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 손익 계산서 조회
  getIncStatement(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/curr-status-inq/inc-statements${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
