import incHqpayMgmtService from '@/api/services/income/inc-hopay-mgmt-service'
import commonService from '@/api/services/cmm/common-service'

// === for gred sample
const FETCH_HOPAY_RET_LIST = 'FETCH_HOPAY_RET_LIST'
// 확정구분 공통코드 리스트
const FETCH_DEF_LIST = 'FETCH_DEF_LIST'

const incHopayMgmtStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for gred sample
    incHopayRets: [],

    incHopayRet: {
      // boId: 999,
      // IncYymm: '2022',
      // defCl: 'Q1',
      // incItmId: '',
      // typCd: '',
      // cl1Cd: '', // 구분1코드
      // cl2Cd: '', // 구분2코드
      // cl3Cd: '', // 구분3코드
      // ptAmt: '', // 지급분 금액
      // clsYn: '', // 마감 YN
      // incItmDetId: ''
    },
    // 확정구분
    defCls: []
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_HOPAY_RET_LIST](state, incHopayRets) {
      state.incHopayRets = incHopayRets
    },
    [FETCH_DEF_LIST](state, defCls) {
      state.defCls = defCls
    }
  },
  actions: {
    // 확정구분 공통코드 조회
    fetchDefClList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(payload.codeType, payload.lang)
          .then((res) => {
            commit(FETCH_DEF_LIST, res.data || [])
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearInHopayRets({ commit }) {
      commit(FETCH_HOPAY_RET_LIST, [])
    },
    fetchIncHopayRetList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        incHqpayMgmtService
          .getIncHopayRets(queryStr)
          .then((res) => {
            commit(FETCH_HOPAY_RET_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // row삭제
    deleteIncHopayRet({ commit, state }, selIdx) {
      state.incHopayRets.splice(selIdx, 1)
      commit(FETCH_HOPAY_RET_LIST, state.incHopayRets)
    }
  }
}

export default incHopayMgmtStore
