import anlInfoService from '@/api/services/analysis/anl-info-service'
// import commonService from '@/api/services/cmm/common-service'

const FETCH_BUSI_PROF_ANL_INFO = 'FETCH_BUSI_PROF_ANL_INFO'
const SET_SALES_AMT_CHARTS = 'SET_SALES_AMT_CHARTS'
const SET_BUSI_PROF_AMT_CHARTS = 'SET_BUSI_PROF_AMT_CHARTS'
const SET_BUSI_PROF_EXP_AMT_CHARTS = 'SET_BUSI_PROF_EXP_AMT_CHARTS'
const SET_CUM_SALES_AMT_CHARTS = 'SET_CUM_SALES_AMT_CHARTS'
const SET_CUM_BUSI_PROF_AMT_CHARTS = 'SET_CUM_BUSI_PROF_AMT_CHARTS'
const SET_CUM_BUSI_PROF_EXP_AMT_CHARTS = 'SET_CUM_BUSI_PROF_EXP_AMT_CHARTS'
const CLEAR_STORE = 'CLEAR_STORE'

const anlBusiProfStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 원천 데이터 리스트
    // 매출액(3년치)
    salesAmts: [
      // {
      // incYy: '2021',
      // incItm1: '매출액',
      // mon01Amt: 4138.17, mon02Amt: 4644.17, mon03Amt: 4268.22, mon04Amt: 4776.75,
      // mon05Amt: 4470.0, mon06Amt: 4535.0, mon07Amt: 0.0, mon08Amt: 0.0,
      // mon09Amt: 0.0, mon10Amt: 0.0, mon11Amt: 0.0, mon12Amt: 0.0
      // },
    ],
    // 영업이익(3년치)
    busiProfAmts: [
      // {
      //   incYy: '2021',
      //   incItm1: '영업이익',
      //   mon01Amt: -8.3, mon02Amt: 2.65, mon03Amt: 33.95, mon04Amt: 56.37,
      //   mon05Amt: 6.7,  mon06Amt: -9.39, mon07Amt: 0.0,  mon08Amt: 0.0,
      //   mon09Amt: 0.0, mon10Amt: 0.0, mon11Amt: 0.0, mon12Amt: 0.0
      // },
    ],
    // 영업이익&영업비용(당해년도)
    busiProfExpAmts: [
      // {
      //   incYy: '2021',
      //   incItm1: '영업이익',
      //   mon01Amt: -8.3, mon02Amt: 2.65, mon03Amt: 33.95, mon04Amt: 56.37,
      //   mon05Amt: 6.7, mon06Amt: -9.39, mon07Amt: 0.0, mon08Amt: 0.0,
      //   mon09Amt: 0.0, mon10Amt: 0.0, mon11Amt: 0.0, mon12Amt: 0.0
      // },
      // {
      //   incYy: '2021',
      //   incItm1: '일반관리비',
      //   mon01Amt: 132.44, mon02Amt: 119.55, mon03Amt: 130.51, mon04Amt: 127.29,
      //   mon05Amt: 122.9,  mon06Amt: 140.99, mon07Amt: 0.0, mon08Amt: 0.0,
      //   mon09Amt: 0.0, mon10Amt: 0.0, mon11Amt: 0.0, mon12Amt: 0.0
      // }
    ],

    // 매출액 누계(3년치)
    cumSalesAmts: [
      // {
      //   incYy: '2021',
      //   incItm1: '매출액',
      //   mon01Amt: 4138.17, mon02Amt: 8782.34, mon03Amt: 13050.56, mon04Amt: 17827.31,
      //   mon05Amt: 22297.31, mon06Amt: 26832.31, mon07Amt: 26832.31, mon08Amt: 26832.31,
      //   mon09Amt: 26832.31, mon10Amt: 26832.31, mon11Amt: 26832.31, mon12Amt: 26832.31
      // }
    ],
    // 영업이익 누계(3년치)
    cumBusiProfAmts: [
      // {
      //   incYy: '2021',
      //   incItm1: '영업이익',
      //   mon01Amt: -8.3, mon02Amt: -5.65, mon03Amt: 28.3, mon04Amt: 84.67,
      //   mon05Amt: 91.36, mon06Amt: 81.97, mon07Amt: 81.97, mon08Amt: 81.97,
      //   mon09Amt: 81.97, mon10Amt: 81.97, mon11Amt: 81.97, mon12Amt: 81.97
      // }
    ],
    // 영업이익&영업비용 누계(당해년도)
    cumBusiProfExpAmts: [
      // {
      //   incYy: '2021',
      //   incItm1: '영업이익',
      //   mon01Amt: -8.3, mon02Amt: -5.65, mon03Amt: 28.3, mon04Amt: 84.67,
      //   mon05Amt: 91.36, mon06Amt: 81.97, mon07Amt: 81.97, mon08Amt: 81.97,
      //   mon09Amt: 81.97, mon10Amt: 81.97, mon11Amt: 81.97, mon12Amt: 81.97
      // },
      // {
      //   incYy: '2021',
      //   incItm1: '일반관리비',
      //   mon01Amt: 132.44, mon02Amt: 251.98, mon03Amt: 382.49, mon04Amt: 509.78,
      //   mon05Amt: 632.69, mon06Amt: 773.68, mon07Amt: 773.68, mon08Amt: 773.68,
      //   mon09Amt: 773.68, mon10Amt: 773.68, mon11Amt: 773.68, mon12Amt: 773.68
      // }
    ],

    // --- Chart Data ---1
    // 년도가 label이되고 12개월치가 각 데이터를 구성
    // 매출액
    salesAmtcharts: [
      //   {
      //     name: '2021',
      //     data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, ]
      //   }
    ],
    busiProfAmtcharts: [], // 영업이익
    busiProfExpAmtcharts: [], // 영업이익&영업비용

    cumSalesAmtcharts: [], // 매출액 누계
    cumBusiProfAmtcharts: [], // 영업이익 누계
    cumBusiProfExpAmtcharts: [] // 영업이익&영업비용 누계
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_BUSI_PROF_ANL_INFO](state, payload) {
      state.salesAmts = payload.salesAmtList // 매출액
      state.busiProfAmts = payload.busiProfAmtList // 영업이익
      state.busiProfExpAmts = payload.busiProfExpAmtList // 영업이익&영업비용
      state.cumSalesAmts = payload.cumSalesAmtList // 매출액 누계
      state.cumBusiProfAmts = payload.cumBusiProfAmtList // 영업이익 누계
      state.cumBusiProfExpAmts = payload.cumBusiProfExpAmtList // 영업이익&영업비용 누계
    },

    // chart 데이터 설정
    [SET_SALES_AMT_CHARTS](state, chartData) {
      state.salesAmtcharts = chartData
    },
    [SET_BUSI_PROF_AMT_CHARTS](state, chartData) {
      state.busiProfAmtcharts = chartData
    },
    [SET_BUSI_PROF_EXP_AMT_CHARTS](state, chartData) {
      state.busiProfExpAmtcharts = chartData
    },
    [SET_CUM_SALES_AMT_CHARTS](state, chartData) {
      state.cumSalesAmtcharts = chartData
    },
    [SET_CUM_BUSI_PROF_AMT_CHARTS](state, chartData) {
      state.cumBusiProfAmtcharts = chartData
    },
    [SET_CUM_BUSI_PROF_EXP_AMT_CHARTS](state, chartData) {
      state.cumBusiProfExpAmtcharts = chartData
    },
    [CLEAR_STORE](state) {
      state.salesAmtcharts = []
      state.busiProfAmtcharts = []
      state.busiProfExpAmtcharts = []
      state.cumSalesAmtcharts = []
      state.cumBusiProfAmtcharts = []
      state.cumBusiProfExpAmtcharts = []
    }
  },

  actions: {
    // 코드유형 리스트 조회
    fetchBusiProfAlnInfo({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        anlInfoService
          .getBusiProfAnlInfo(queryStr)
          .then((res) => {
            const busiProfAnlInfo = res.data
            commit(FETCH_BUSI_PROF_ANL_INFO, busiProfAnlInfo)

            // 매출액 3년치 chart data 구성
            const salesAmtSeries = state.salesAmts.map((item) => {
              return {
                name: item.incYy, // + '(' + item.incItm1 + ')',
                data: [
                  item.mon01Amt,
                  item.mon02Amt,
                  item.mon03Amt,
                  item.mon04Amt,
                  item.mon05Amt,
                  item.mon06Amt,
                  item.mon07Amt,
                  item.mon08Amt,
                  item.mon09Amt,
                  item.mon10Amt,
                  item.mon11Amt,
                  item.mon12Amt
                ]
              }
            })
            commit(SET_SALES_AMT_CHARTS, salesAmtSeries)

            // 영업이익 3년치 chart data 구성
            const busiProfAmtSeries = state.busiProfAmts.map((item) => {
              return {
                name: item.incYy, //  + '(' + item.incItm1 + ')',
                data: [
                  item.mon01Amt,
                  item.mon02Amt,
                  item.mon03Amt,
                  item.mon04Amt,
                  item.mon05Amt,
                  item.mon06Amt,
                  item.mon07Amt,
                  item.mon08Amt,
                  item.mon09Amt,
                  item.mon10Amt,
                  item.mon11Amt,
                  item.mon12Amt
                ]
              }
            })
            commit(SET_BUSI_PROF_AMT_CHARTS, busiProfAmtSeries)

            // 영업이익&영업비용 당해년도 chart data 구성
            const busiProfExpAmtSeries = state.busiProfExpAmts.map((item) => {
              return {
                name: item.incItm1 + '(' + item.incYy + ')',
                data: [
                  item.mon01Amt,
                  item.mon02Amt,
                  item.mon03Amt,
                  item.mon04Amt,
                  item.mon05Amt,
                  item.mon06Amt,
                  item.mon07Amt,
                  item.mon08Amt,
                  item.mon09Amt,
                  item.mon10Amt,
                  item.mon11Amt,
                  item.mon12Amt
                ]
              }
            })
            commit(SET_BUSI_PROF_EXP_AMT_CHARTS, busiProfExpAmtSeries)

            // 매출액(누계) 3년치 chart data 구성
            const cumSalesAmtSeries = state.cumSalesAmts.map((item) => {
              return {
                name: item.incYy, // + '(' + item.incItm1 + ')',
                data: [
                  item.mon01Amt,
                  item.mon02Amt,
                  item.mon03Amt,
                  item.mon04Amt,
                  item.mon05Amt,
                  item.mon06Amt,
                  item.mon07Amt,
                  item.mon08Amt,
                  item.mon09Amt,
                  item.mon10Amt,
                  item.mon11Amt,
                  item.mon12Amt
                ]
              }
            })
            commit(SET_CUM_SALES_AMT_CHARTS, cumSalesAmtSeries)

            // 영업이익(누계) 3년치 chart data 구성
            const cumBusiProfAmtSeries = state.cumBusiProfAmts.map((item) => {
              return {
                name: item.incYy, // + '(' + item.incItm1 + ')',
                data: [
                  item.mon01Amt,
                  item.mon02Amt,
                  item.mon03Amt,
                  item.mon04Amt,
                  item.mon05Amt,
                  item.mon06Amt,
                  item.mon07Amt,
                  item.mon08Amt,
                  item.mon09Amt,
                  item.mon10Amt,
                  item.mon11Amt,
                  item.mon12Amt
                ]
              }
            })
            commit(SET_CUM_BUSI_PROF_AMT_CHARTS, cumBusiProfAmtSeries)

            // 영업이익&영업비용(누계) 당해년도 chart data 구성
            const cumBusiProfExpAmtSeries = state.cumBusiProfExpAmts.map(
              (item) => {
                return {
                  name: item.incItm1 + '(' + item.incYy + ')',
                  data: [
                    item.mon01Amt,
                    item.mon02Amt,
                    item.mon03Amt,
                    item.mon04Amt,
                    item.mon05Amt,
                    item.mon06Amt,
                    item.mon07Amt,
                    item.mon08Amt,
                    item.mon09Amt,
                    item.mon10Amt,
                    item.mon11Amt,
                    item.mon12Amt
                  ]
                }
              }
            )
            commit(SET_CUM_BUSI_PROF_EXP_AMT_CHARTS, cumBusiProfExpAmtSeries)

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default anlBusiProfStore
