const routes = [
  {
    /* 컴포넌트 모음 */
    path: '/component',
    name: 'Component',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Component.vue'
      )
  },
  {
    /* 전체메뉴 팝업 */
    path: '/allmenu_pop',
    name: 'Allmenu_pop',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Allmenu_pop.vue'
      )
  },
  {
    /* 법인 매출 계획 등록 */
    path: '/inc-sal-plan-reg',
    name: 'Inc_SalPlanReg',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_SalPlanReg.vue'
      )
  },
  {
    /* 법인 수익/비용 계획등록 */
    path: '/inc-rvn-plan-reg',
    name: 'Inc_RvnPlanReg',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_RvnPlanReg.vue'
      )
  },
  {
    /* 법인 수익/비용 등록 */
    path: '/inc-rvn-reg',
    name: 'Inc_RvnReg',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_RvnReg.vue'
      )
  },
  {
    /* 법인 매출 등록 */
    path: '/inc-sal-reg',
    name: 'Inc_SalReg',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_SalReg.vue'
      )
  },
  {
    /* 메모상세 팝업 */
    path: '/inc-memodetail-pop',
    name: 'Inc_MemoDetail_pop',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_MemoDetail_pop.vue'
      )
  },
  {
    /* 메모등록 팝업 */
    path: '/inc-memoreg-pop',
    name: 'Inc_MemoReg_pop',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_MemoReg_pop.vue'
      )
  },
  {
    /* 법인 본사지급분관리 */
    path: '/inc-pay-mana',
    name: 'Inc_PayMana',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_PayMana.vue'
      )
  },
  {
    /* 법인 손익조회 */
    path: '/inc-inc-inquery',
    name: 'Inc_IncInquery',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_IncInquery.vue'
      )
  },
  {
    /* 법인 손익집계 */
    path: '/inc-inc-agg',
    name: 'Inc_IncAgg',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_IncAgg.vue'
      )
  },
  {
    /* 법인 당월 실적 */
    path: '/inc-month-ret',
    name: 'Inc_MonthRet',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_MonthRet.vue'
      )
  },
  {
    /* 법인 손익데이터 검색 */
    path: '/inc-incdata',
    name: 'Inc_IncData',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_IncData.vue'
      )
  },
  {
    /* 법인 손익데이터 검색 팝업 */
    path: '/inc-incdata-pop',
    name: 'Inc_IncData_pop',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_IncData_pop.vue'
      )
  },
  {
    /* 법인 마감 현황 */
    path: '/inc-clscurrent',
    name: 'Inc_ClsCurrent',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inc_ClsCurrent.vue'
      )
  },
  {
    /* 법인 시설현황 관리 */
    path: '/inf-facilities-mana',
    name: 'Inf_FacilitiesMana',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inf_FacilitiesMana.vue'
      )
  },
  {
    /* 법인 시설현황 관리(등록) 팝업  */
    path: '/inf-facilities-reg-pop',
    name: 'Inf_FacilitiesReg_pop',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inf_FacilitiesReg_pop.vue'
      )
  },
  {
    /* 법인 인원현황 관리 */
    path: '/inf-people-mana',
    name: 'Inf_PeopleMana',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Inf_PeopleMana.vue'
      )
  },
  {
    /* 재무 - 시산표 업로드 */
    path: '/fin-trial-balance',
    name: 'Fin_TrialBalance',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Fin_TrialBalance.vue'
      )
  },
  {
    /* 재무 - 월 경과 미회수채권 업로드  */
    path: '/fin-monpas',
    name: 'Fin_MonPas',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Fin_MonPas.vue'
      )
  },
  {
    /* 재무 - 미수채권 증감 업로드 */
    path: '/fin-recinc',
    name: 'Fin_RecInc',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Fin_RecInc.vue'
      )
  },
  {
    /* 재무 - 법인목록관리 */
    path: '/fin-listmana',
    name: 'Fin_ListMana',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Fin_ListMana.vue'
      )
  },
  {
    // === 엑셀 업로드 시트 선택 팝업 Page ===
    path: '/excel-popup',
    name: 'Excel_popup',
    component: () =>
      import(
        /* webpackPrefetch:true, webpackChunkName: "template" */ '@/views/publish/Excel_popup.vue'
      )
    // meta: { auth: true }
  }
]

export default routes
