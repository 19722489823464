// import ExcelJS from 'exceljs'
// import { saveAs } from 'file-saver'
// import VueCookies from 'vue-cookies'
// import axios from 'axios'
// import store from '../store'

// ========================================================
// 2022.02.07 yhlee
// 기존 프레임워크에서 사용하던 전역믹스인으로
// 현재는 샘플 참조를 위해 남겨둠.
// 추후, 개발이 일정부분 진행되면 삭제 예정
// 동일한 함수를 @/utils/common-utls.js에서 제공하므로
// 제거된 다음에도 this.$get -> sampleUtils.$get으로 사용 가능
// ========================================================
export default {
  methods: {
    // /**
    //  * 모든 HTTP 메소드를 사용해서 서버로 요청할 수 있는 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @param {*} method HTTP 메소드
    //  * @param {*} data 전송할 데이터
    //  * @returns
    //  */
    // async $api(url, method, data) {
    //   return (
    //     await axios({
    //       method: method,
    //       url: url,
    //       data: data
    //     }).catch((e) => {
    //       console.log(e)
    //     })
    //   ).data
    // },
    // /**
    //  * GET 요청을 처리하는 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @param {*} opt 요청 옵션
    //  * @returns
    //  */
    // async $get(url, opt = {}) {
    //   return (
    //     await axios({
    //       url,
    //       ...opt
    //     })
    //   ).data
    // },
    // /**
    //  * POST 요청을 처리하는 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @param {*} data 전송할 데이터
    //  * @returns
    //  */
    // async $post(url, data) {
    //   return (
    //     await axios.post(url, data).catch((e) => {
    //       console.log(e)
    //     })
    //   ).data
    // },
    // /**
    //  * PUT 요청을 처리하는 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @param {*} data 전송할 데이터
    //  * @returns
    //  */
    // async $put(url, data) {
    //   return (
    //     await axios.put(url, data).catch((e) => {
    //       console.log(e)
    //     })
    //   ).data
    // },
    // /**
    //  * DELETE 요청을 처리하는 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @returns
    //  */
    // async $delete(url) {
    //   return (
    //     await axios.delete(url).catch((e) => {
    //       console.log(e)
    //     })
    //   ).data
    // },
    /**
    //  * 파일 업로드를 처리하는 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @param {*} file 파일
    //  * @returns
    //  */
    // async $upload(url, file) {
    //   const formData = new FormData()
    //   formData.append('file', file)
    //   return (
    //     await axios
    //       .post(url, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         }
    //       })
    //       .catch((e) => {
    //         console.log(e)
    //       })
    //   ).data
    // },
    // /**
    //  * 이미지를 로드하기 위한 함수
    //  * @param {*} blob 파일
    //  * @param {*} readAs read 방법
    //  * @returns
    //  */
    // $loadFile(blob, readAs) {
    //   return new Promise((resolve, reject) => {
    //     const fr = new FileReader()
    //     fr.onload = (e) => resolve(e.target.result)
    //     fr.onerror = (e) => reject(e)
    //     if (readAs === 'arraybuffer') fr.readAsArrayBuffer(blob)
    //     else fr.readAsDataURL(blob)
    //   })
    // },
    // /**
    //  * 서버의 파일 다운로드 처리 함수
    //  * @param {*} url 서버 라우트 경로
    //  * @param {*} filename 저장 파일 이름
    //  */
    // async $download(url, filename) {
    //   const f = await this.$get(url, {
    //     responseType: 'blob'
    //   })
    //   saveAs(new Blob([f]), `${filename}`)
    // },
    // /**
    //  * 클라이언트의 그리드 데이터를 엑셀파일로 다운로드 처리하는 함수
    //  * @param {*} header 헤더 정보
    //  * @param {*} rows 데이터 정보
    //  * @param {*} fileName 파일 이름
    //  * @param {*} option 옵션
    //  */
    // async $ExcelFromTable(header = [], rows = [], fileName, option = {}) {
    //   header = header.filter((h) => h.title && h.key)
    //   // https://github.com/exceljs/exceljs#tables
    //   const wb = new ExcelJS.Workbook()
    //   const ws = wb.addWorksheet() // name,{pageSetup:https://github.com/exceljs/exceljs#page-setup}
    //   ws.addTable({
    //     name: 'tableName',
    //     ref: 'A1',
    //     headerRow: true,
    //     // style: { theme: 'TableStyleDark3', showRowStripes: true },
    //     columns: header.map((h) => ({
    //       name: h.title
    //     })), // width 설정가능, total함수 가능
    //     rows: rows.map((r) => header.map((h) => r[h.key])),
    //     ...option
    //   })
    //   saveAs(new Blob([await wb.xlsx.writeBuffer()]), `${fileName}.xlsx`)
    // },
    // /**
    //  * 날짜 포맷 처리 함수
    //  * @param {*} d 날짜
    //  * @param {*} f 적용 포맷
    //  * @returns
    //  */
    // $convertDateFormat(d, f) {
    //   let year = ''
    //   let month = ''
    //   let day = ''
    //   if (typeof d === 'string') {
    //     year = d.substrring(0, 4)
    //     month = d.substring(4, 6)
    //     day = d.substring(6, 8)
    //   } else if (typeof d === 'object') {
    //     year = d.getFullYear()
    //     month = d.getMonth() + 1
    //     month = month.toString().padStart(2, 0)
    //     day = d.getDate()
    //   }
    //   return f.replace('YYYY', year).replace('MM', month).replace('DD', day)
    // },
    // /**
    //  * 금액 포맷 처리 함수
    //  * @param {*} amount 금액
    //  * @param {*} format 적용 포맷
    //  * @returns
    //  */
    // $convertNumberFormat(amount, format) {
    //   let currencyUnit = ''
    //   let lastUnit = ''
    //   if (format.substring(0, 1) !== '#') {
    //     currencyUnit = format.substring(0, 1)
    //   }
    //   if (format.slice(-1) !== '#' && format.slice(-1) !== '0') {
    //     lastUnit = format.slice(-1)
    //   }
    //   let groupingSeparator = ''
    //   let maxFractionDigits = 0
    //   let decimalSeparator = ''
    //   if (format.indexOf('.') === -1) {
    //     groupingSeparator = ','
    //   } else if (format.indexOf(',') === -1) {
    //     groupingSeparator = '.'
    //   } else if (format.indexOf(',') < format.indexOf('.')) {
    //     groupingSeparator = ','
    //     decimalSeparator = '.'
    //     maxFractionDigits = format.length - format.indexOf('.') - 1
    //   } else {
    //     groupingSeparator = '.'
    //     decimalSeparator = ','
    //     maxFractionDigits = format.length - format.indexOf(',') - 1
    //   }
    //   let sign = ''
    //   let dec = 1
    //   for (let i = 0; i < maxFractionDigits; i++) {
    //     dec = dec * 10
    //   }
    //   let v = String(Math.round(parseFloat(amount) * dec) / dec)
    //   if (v.startsWith('-')) {
    //     sign = '-'
    //     v = v.substring(1)
    //   }
    //   if (
    //     maxFractionDigits > 0 &&
    //     format.substring(format.length - 1) === '0'
    //   ) {
    //     v = String(parseFloat(v).toFixed(maxFractionDigits))
    //   }
    //   let d = ''
    //   if (maxFractionDigits > 0 && v.indexOf('.') > -1) {
    //     d = v.substring(v.indexOf('.'))
    //     d = d.replace('.', decimalSeparator)
    //     v = v.substring(0, v.indexOf('.'))
    //   }
    //   const r = /(\d+)(\d{3})/
    //   while (r.test(v)) {
    //     v = v.replace(r, '$1' + groupingSeparator + '$2')
    //   }
    //   return sign + currencyUnit + String(v) + String(d) + lastUnit
    // }
  }
}
