import devSampleService from '@/api/services/sample/dev-sample-service'

// === for gred sample
const FETCH_USER_LIST = 'FETCH_USER_LIST'
const SELECT_USER = 'SELECT_USER'
// const FETCH_COMMENT_LIST = 'FETCH_COMMENT_LIST'

const devSampleStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // === for grid sample
    users: [],

    user: {
      // id: 999,
      // username: '홍길동',
      // email: 'skylee@empal.com',
      // gender: 'M',
      // phone: '010-1111-2222',
      // user_type: 'U',
      // company: '',
      // address: ''
    },

    rntTypes: [],
    rntType: {
      // value: '1000071',
      // label: '임차'
    }
  },

  getters: {
    // -
  },

  mutations: {
    // === for gred sample
    [FETCH_USER_LIST](state, payload) {
      state.users = payload
    },
    [SELECT_USER](state, user) {
      state.user = user
    }
    // [FETCH_COMMENT_LIST](state, comments) {
    //   state.comments = comments
    // }
  },

  actions: {
    // === for Grid sample
    fetchUserList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        devSampleService
          .getUserList(queryStr)
          .then((res) => {
            commit(FETCH_USER_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    selectUser({ commit }, selectUser) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_USER, selectUser)
    }

    // fetchCommentListByUser({ commit }, userId = '') {
    //   const queryStr = userId ? '?user_id=' + userId : ''
    //   return devSampleService.getCommentListByUser(queryStr).then((res) => {
    //     commit(FETCH_COMMENT_LIST, res.data)
    //   })
    // }
  }
}

export default devSampleStore
