import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 손익계산서 리스트 조회
  getFinPlStatementList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/fin/pl-statements${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 손익계산서 저장
  saveFinPlStatements(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/fin/pl-statements', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 엑셀업로드
  uploadExcel(files, frmId, crncyType, sheetNum) {
    return commonUtils.$upload(
      `fin/pl-statements/xls/${sheetNum}/${frmId}/${crncyType}`,
      files[0]
    )
  }
}
