import sysAccessHistService from '@/api/services/system/sys-access-hist-service'

const FETCH_ACCESS_HIST_LIST = 'FETCH_ACCESS_HIST_LIST'
const SELECT_ACCESS_HIST = 'SELECT_ACCESS_HIST'
const CLEAR_STORE = 'CLEAR_STORE'

const sysAccessHistStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 사용자
    accessHists: [],
    accessHist: {
      // logId: 1, // 로그 id
      // devDt: '2022-04-07 23:22:23', // 발생 일자
      // logTyp: 'I', // 로그 유형(I:Log-In, O:Log-Out)
      // logTypNm: 'Login', // 로그 유형명
      // loginId: 'user1', // 로그인 id
      // loginNm: '홍길동', // 로그인 명
      // loginIp: '127.0.0.1', // 로그인 ip
      // errYn: '', // 오류Yn
      // errRsn: '' // 오류 사유
    }
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_ACCESS_HIST_LIST](state, accessHists) {
      state.accessHists = accessHists
    },
    [SELECT_ACCESS_HIST](state, accessHist) {
      state.accessHist = accessHist
    },
    [CLEAR_STORE](state) {
      state.accessHists = []
    }
  },

  actions: {
    // 코드유형 리스트 조회
    fetchAccessHistList({ commit }, queryStr = '') {
      return new Promise((resolve, reject) => {
        sysAccessHistService
          .getAccessHistList(queryStr)
          .then((res) => {
            commit(FETCH_ACCESS_HIST_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 코드 유형 선택
    selectPgm({ commit }, selectAccessHist) {
      // 특정 유저를 선택(list에서 특정 건이 선택될 때)
      commit(SELECT_ACCESS_HIST, selectAccessHist)
    },
    clearStore({ commit }) {
      commit(CLEAR_STORE)
    }
  }
}

export default sysAccessHistStore
