import template from './template'
import crud from './crud'
import chart from './chart'
import datagrid from './datagrid'
import file from './file'
import devSample from './dev-sample'

const sampleRoutes = [
  // 로그인 샘플
  {
    path: '/sample-login',
    name: 'SampleLogin',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/Login.vue'
      )
  },
  {
    path: '/i18n/lang',
    name: 'MultiLang',
    component: () =>
      import(
        /* webpackPrefetch: 0, webpackChunkName: "rvSample" */ '@/views/samples/i18n/MultiLang.vue'
      )
  },
  ...template,
  ...chart,
  ...crud,
  ...datagrid,
  ...file,
  ...devSample
]

export default sampleRoutes
