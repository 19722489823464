import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 실적 헤더정보조회
  getIncRet(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/inc-ret${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 매출실적헤더저장
  saveIncRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/inc-ret', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 비용실적 정보조회
  getIncExpRet(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/exp-perfs${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 엑셀업로드
  uploadExcel(files, boId, incYymm, defCl, typCd, sheetNum) {
    return commonUtils.$upload(
      `inc/exp-perfs/xls/${sheetNum}/${boId}/${incYymm}/${defCl}/${typCd}`,
      files[0]
    )
  },

  // 비용실적저장
  saveIncExpRet(incExpRets, typCdNm) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post(`/inc/exp-perfs/${typCdNm}`, incExpRets)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비용실적 마감
  procIncExpCls(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .post('/inc/exp-perfs/cls', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비용실적 삭제
  deleteIncExpRet(payload) {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(
          `/inc/exp-perfs/${payload.boId}/${payload.incYymm}/${payload.defCl}/${payload.seq}/${payload.typCdNm}`,
          payload
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 비용실적에 해당하는 매출실적조회
  getIncSalPerfByExps(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/inc/exp-perfs/sales${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
