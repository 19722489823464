import { axiosApi } from '@/api'
import commonUtils from '@/utils/common-utils'

export default {
  // 공통 코드 List 조회
  getCommonCodeList(codeType, locale = 'en_US') {
    // make query string
    const queryStr = '?codeType=' + codeType

    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/cmm/common-codes${queryStr}`)
        .then((res) => {
          // console.log('common-service > getCommonCodeList > locale ->', locale)
          // code 컬럼 변환 처리

          const commonCodeTemp = res.data.map((item) => {
            const {
              tccvId: value,
              stdCdNm: label,
              stdCdNmEng: labelEng,
              bseCd: codeType,
              stdCd: itemCd,
              cdVal
            } = item

            // 언어가 영어가 아니면 다른 언어로 설정
            return {
              value,
              label: locale === 'ko_KR' ? label : labelEng,
              codeType,
              labelEng,
              itemCd,
              cdVal
            }
          })

          // 'ALL'에 해당하는 dummy 행 추가
          const commonCodeDummy = [
            {
              value: '',
              label: 'ALL',
              codeType: codeType,
              labelEng: 'ALL',
              itemCd: '',
              cdVal: ''
            }
          ]
          commonCodeTemp.unshift(...commonCodeDummy)

          res.data = commonCodeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 공통 코드 List 조회
  getAllCommonCodeList(locale = 'en_US') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/cmm/common-codes-all')
        .then((res) => {
          // console.log('common-service > getCommonCodeList > locale ->', locale)
          // code 컬럼 변환 처리

          const commonCodeTemp = res.data.map((item) => {
            const {
              tccvId: value,
              stdCdNm: label,
              stdCdNmEng: labelEng,
              bseCd: codeType,
              stdCd: itemCd,
              cdVal
            } = item

            // 언어가 영어가 아니면 다른 언어로 설정
            return {
              value,
              label: locale === 'ko_KR' ? label : labelEng,
              codeType,
              labelEng,
              itemCd,
              cdVal
            }
          })

          // 'ALL'에 해당하는 dummy 행 추가
          const commonCodeDummy = [
            {
              value: '',
              label: 'ALL',
              codeType: '',
              labelEng: 'ALL',
              itemCd: '',
              cdVal: ''
            }
          ]
          commonCodeTemp.unshift(...commonCodeDummy)

          res.data = commonCodeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인리스트조회
  getBoList(locale) {
    // make query string

    return new Promise((resolve, reject) => {
      axiosApi
        .get('/cmm/bos')
        .then((res) => {
          res.data.map((item) => {
            return item
          })

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 통화 코드 List 조회
  getCurrencyCodeList() {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/cmm/currency-codes')
        .then((res) => {
          // combo box를 위한 속성명으로 변환
          const codeTemp = res.data.map((item) => {
            const { crncyCd: value, crncyNm: label } = item

            return {
              value,
              label
            }
          })

          // 'ALL'에 해당하는 dummy 행 추가
          const codeDummy = [
            {
              value: '',
              label: 'ALL'
            }
          ]
          codeTemp.unshift(...codeDummy)

          res.data = codeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 국가 코드 List 조회
  getCountryCodeList() {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/cmm/country-codes')
        .then((res) => {
          // combo box를 위한 속성명으로 변환
          const codeTemp = res.data.map((item) => {
            const { cntryCd: value, cntryNm: label, isoCntryCd, crncyCd } = item

            return {
              value,
              label,
              isoCntryCd,
              crncyCd
            }
          })

          // 'ALL'에 해당하는 dummy 행 추가
          const codeDummy = [
            {
              value: '',
              label: 'ALL',
              isoCntryCd: '',
              crncyCd: ''
            }
          ]
          codeTemp.unshift(...codeDummy)

          res.data = codeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 지역언어 코드 List 조회
  getLocaleCodeList() {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/cmm/locale-codes')
        .then((res) => {
          // combo box를 위한 속성명으로 변환
          const codeTemp = res.data.map((item) => {
            const { lngCd: value, lngDesc: label, trrtCd, lngTyp } = item

            return {
              value,
              label,
              trrtCd,
              lngTyp
            }
          })

          // 'ALL'에 해당하는 dummy 행 추가
          const codeDummy = [
            {
              value: '',
              label: 'ALL',
              trrtCd: '',
              lngTyp: ''
            }
          ]
          codeTemp.unshift(...codeDummy)

          res.data = codeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 권한코드 List 조회
  getAuthCodeList(locale) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/cmm/auth-codes')
        .then((res) => {
          // combo box를 위한 속성명으로 변환
          const authCodeTemp = res.data.map((item) => {
            const { authCd: value, authNm: label, authNmEng: labelEng } = item

            // 언어가 영어가 아니면 다른 언어로 설정
            return {
              value,
              label: locale === 'en_US' ? labelEng : label
            }
          })

          // 'ALL'에 해당하는 dummy 행 추가
          const authCodeDummy = [
            {
              value: '',
              label: 'ALL'
            }
          ]
          authCodeTemp.unshift(...authCodeDummy)

          res.data = authCodeTemp

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 엑셀 Sheet명 리스트 가져오기
  getExcelSheetNames(files) {
    return commonUtils.$upload('/excel/sheets-name', files[0])
  },

  // 최종환율 년월
  getExchgRateYm(yyyy) {
    const queryStr = '?yyyy=' + yyyy
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/cmm/exchgrateym${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
