import { axiosApi } from '@/api'

export default {
  // 법인 마스터 리스트 조회
  getBranchOfficeMgmtList(queryStr = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/branch-offices${queryStr}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 마스터 추가
  createBranchOfficeMgmt(payload) {
    console.log(
      'BseBranchOfficeMgmtService.createBranchOfficeMgmt() > payload',
      payload
    )

    return new Promise((resolve, reject) => {
      axiosApi
        .post('/bse/branch-offices', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 마스터 수정
  // put을 사용하는 경우 반드시 전체 레코드를 backend로 넘겨야 함.
  updateBranchOfficeMgmt(id, payload) {
    console.log(
      'BseBranchOfficeMgmtService.updateBranchOfficeMgmt() > payload',
      payload
    )

    return new Promise((resolve, reject) => {
      axiosApi
        .put(`/bse/branch-offices/${id}`, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 마스터 삭제
  deleteBranchOfficeMgmt(id) {
    console.log(
      'BseBranchOfficeMgmtService.deleteBranchOfficeMgmt() > boId :',
      id
    )

    return new Promise((resolve, reject) => {
      axiosApi
        .delete(`/bse/branch-offices/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인코드 중복체크
  checkBoCd(findParams = '') {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/branch-offices/check${findParams}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 법인 목록 조회
  getBoList(locale) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get('/bse/branch-offices/bos')
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 지사 목록 조회
  getPboCount(boId) {
    return new Promise((resolve, reject) => {
      axiosApi
        .get(`/bse/branch-offices/${boId}/count`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
