import bseIncUpService from '@/api/services/base/bse-inc-up-service'
import commonService from '@/api/services/cmm/common-service'

const FETCH_TRRT_LIST = 'FETCH_TRRT_LIST' // 지역
const FETCH_TRRT = 'FETCH_TRRT'
const FETCH_INC_RET_LIST = 'FETCH_INC_RET_LIST' // 실적
const FETCH_INC_RET = 'FETCH_INC_RET'
const FETCH_INC_PLN_LIST = 'FETCH_INC_PLN_LIST' // 계획
const FETCH_INC_PLN = 'FETCH_INC_PLN'
const FETCH_BO_LIST = 'FETCH_BO_LIST' // 법인리스트 => 지역 선택 시 변경
const SELECT_BO = 'SELECT_BO' // 선택된 법인
const FETCH_ORIGIN_BO_LIST = 'FETCH_ORIGIN_BO_LIST' // original 법인 리스트

const bseIncUpStore = {
  namespaced: true,

  // === state ===
  // 객체의 경우 구조 및 데이터 정의 필요(실제 실행시에는 대체됨)
  // 특히, vue 컴포넌트에서 v-model로 객체의 속성을 참조하는 경우 반드시 필요
  state: {
    // 지역
    trrts: [],
    trrtId: {
      // codeType:"TRRT"
      // itemCd:"1"
      // label:"중국지역"
      // labelEng:"중국지역"
      // value:1000102
    },
    // 실적
    incRets: [],
    incRet: {
      // boId:1000030
      // boNmH:"독일법인"
      // cnt1:12
      // cnt2:0
      // lstDefCl:"Q1"
      // lstIncYymm:202204
      // lstRegDt1:"2022-07-01 16:41:15"
      // lstRegDt2:null
      // subBoNm:"함부르크본사"
      // trrtId:"1000104"
      // trrtNm:"구주지역"
    },
    // 계획
    incPlns: [],
    incPln: {
      // boId:1000030
      // boNmH:"독일법인"
      // cnt1:2016
      // cnt2:2016
      // lstDefCl:"Q1"
      // lstIncYymm:2022
      // lstRegDt1:"2022-05-24 02:31:14"
      // lstRegDt2:"2022-05-24 02:41:33"
      // subBoNm:"함부르크본사"
      // trrtId:"1000104"
      // trrtNm:"구주지역"
    },
    // 법인리스트
    bos: [],
    // 선택된 법인
    bo: {
      boId: '' // 1000001
      // boNm: '상해',
      // crncyCd: 'CNY',
      // cntryCd: 'CN',
      // boNmEng:
      //   'Shanghai Hyundai Asia Shipping International Freight Forwarding Co.Ltd.'
    },
    // 선택된 지역에 해당되는 법인 리스트
    originBos: []
  },

  getters: {
    // -
  },

  mutations: {
    [FETCH_TRRT_LIST](state, trrts) {
      state.trrts = trrts
    },
    [FETCH_TRRT](state, trrtId) {
      state.trrtId = trrtId
    },
    [FETCH_INC_RET_LIST](state, incRets) {
      state.incRets = incRets
    },
    [FETCH_INC_RET](state, incRet) {
      state.incRet = incRet
    },
    [FETCH_INC_PLN_LIST](state, incPlns) {
      state.incPlns = incPlns
    },
    [FETCH_INC_PLN](state, incPln) {
      state.incPln = incPln
    },
    // 법인리스트
    [FETCH_BO_LIST](state, payload) {
      state.bos = payload
    },
    // 선택된 법인
    [SELECT_BO](state, bo) {
      state.bo = bo
    },
    [FETCH_ORIGIN_BO_LIST](state, payload) {
      state.originBos = payload
    }
  },

  actions: {
    // 지역 리스트
    fetchTrrtList({ commit }, { codeType, locale }) {
      return new Promise((resolve, reject) => {
        commonService
          .getCommonCodeList(codeType, locale)
          .then((res) => {
            commit(FETCH_TRRT_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    fetchTrrtId({ commit, state }, selectTrrtId) {
      // 지역 선택 시 해당 지역의 법인 리스트 가져오기
      if (!selectTrrtId) {
        this.fetchBoList()
      }

      let selBoList = []

      const tmpBo = {
        boId: '',
        boNm: 'ALL',
        subBos: [{ boId: '', boNm: '' }]
      }

      if (selectTrrtId) {
        const tbos = state.originBos.filter((bo) => bo.trrtId === selectTrrtId)
        selBoList = JSON.parse(JSON.stringify(tbos))
      }

      selBoList.unshift(tmpBo)

      commit(FETCH_TRRT, selectTrrtId)
      commit(FETCH_BO_LIST, selBoList)
    },

    // 실적 리스트
    fetchIncRetList({ commit }, queryStr = '') {
      // console.log('bseIncUpStore > fetchIncRetList > qureystr ==>', queryStr)
      return new Promise((resolve, reject) => {
        bseIncUpService
          .getIncRetList(queryStr)
          .then((res) => {
            commit(FETCH_INC_RET_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncRet({ commit }, selectIncRet) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_INC_RET, selectIncRet)
    },
    clearIncRet({ commit }) {
      commit(FETCH_INC_RET_LIST, [])
    },

    // 계획 리스트
    fetchIncPlnList({ commit }, queryStr = '') {
      // console.log('bseIncUpStore > fetchIncPlnList > qureystr ==>', queryStr)
      return new Promise((resolve, reject) => {
        bseIncUpService
          .getIncPlnList(queryStr)
          .then((res) => {
            commit(FETCH_INC_PLN_LIST, res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchIncPln({ commit }, selectIncPln) {
      // 특정 row를 선택(list에서 특정 건이 선택될 때)
      commit(FETCH_INC_PLN, selectIncPln)
    },
    clearIncPln({ commit }) {
      commit(FETCH_INC_PLN_LIST, [])
    },
    // 법인리스트를 가지고 온다.
    fetchBoList({ commit, state }, queryStr = '') {
      return new Promise((resolve, reject) => {
        commonService
          .getBoList()
          .then((res) => {
            const tmpBo = {
              boId: '',
              boNm: 'ALL'
            }
            res.data.unshift(tmpBo)

            commit(FETCH_BO_LIST, res.data)
            commit(FETCH_ORIGIN_BO_LIST, res.data)

            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 법인선택시
    // @param selBoId : 선택된 법인 ID
    selectBo({ commit, state }, selBoId) {
      // 특정 법인을 선택했을 경우
      // commit(SELECT_BO, selBoId)

      let selbo = { boId: '', boNm: 'ALL', subBos: [] }
      if (selBoId) {
        const tbos = state.bos.find((bo) => bo.boId === selBoId)
        selbo = JSON.parse(JSON.stringify(tbos))
      }

      console.log('stroe > selBo', selBoId)
      console.log('store > selbo', selbo)
      commit(SELECT_BO, selbo)
    }
  }
}

export default bseIncUpStore
